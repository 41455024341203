/*------------------------------------*\
	#Search navigation
\*------------------------------------*/
.search-navigation {
  font: 16px/22px "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  text-align: center;
  list-style: none;
  overflow: hidden;
  width: 100%;
  padding: 10px 0 0;
  margin: -18px 0 70px;
}
.search-navigation dt {
  margin: 0 0 12px;
}
.search-navigation dd ul {
  margin: 0;
  padding: 0;
}
.search-navigation dd ul li {
  display: inline-block;
  margin: 10px 10px 0;
}
.search-navigation dd ul li.active {
  color: #fff;
  text-transform: uppercase;
  background: #ec1d25;
  padding: 10px 20px;
  border-radius: 3px;
}
.search-navigation dd ul li.previous a,
.search-navigation dd ul li.next a {
  background: #ec1d25;
  color: #fff;
  padding: 10px 20px;
  border-radius: 3px;
  line-height: 22px;
  text-decoration: none;
  display: block;
}
.search-navigation dd ul li.previous a:hover,
.search-navigation dd ul li.next a:hover {
  background-color: #292929;
}
.search-navigation dd ul li .button {
  display: block;
}
.search-navigation dd ul li:before {
  display: none;
}
