/*------------------------------------*\
	#Product intro
\*------------------------------------*/
.product-intro {
  /*
	 * For modern browsers
	 * 1. The space content is one way to avoid an Opera bug when the
	 *    contenteditable attribute is included anywhere else in the document.
	 *    Otherwise it causes space to appear at the top and bottom of elements
	 *    that are clearfixed.
	 * 2. The use of `table` rather than `block` is only necessary if using
	 *    `:before` to contain the top-margins of child elements.
	 */
  *zoom: 1;
}
.product-intro:before,
.product-intro:after {
  content: " ";
  display: table;
}
.product-intro:after {
  clear: both;
}
.product-intro__right {
  position: relative;
  margin-bottom: 36px;
}
@media only screen and (min-width: 1024px) {
  .product-intro__right {
    float: right;
    width: 570px;
  }
  .product-intro__right + .product-intro__left {
    float: left;
    width: calc(100% - 570px);
  }
  .product-intro__right:before {
    position: absolute;
    content: '';
    width: 100%;
    height: 40px;
    top: -40px;
    left: 0;
    background: #fff;
  }
}
