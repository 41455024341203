/*------------------------------------*\
	#Product tabs
\*------------------------------------*/
.product-tabs {
  position: relative;
  padding: 0 0 31px;
  border-bottom: 1px solid rgba(51, 51, 51, 0.12);
}
.product-tabs:after {
  position: absolute;
  content: '';
  width: 1280px;
  height: 1px;
  right: 100%;
  bottom: -1px;
  background: rgba(51, 51, 51, 0.12);
}
.product-tabs:last-child {
  border-bottom: none;
}
.product-tabs:last-child:after {
  display: none;
}
