﻿/*------------------------------------*\
	#Anchor
\*------------------------------------*/

@import (reference) "../default.less";

.anchor {
	display: block;
	position: relative;
	visibility: hidden;

	@media @laptop {
		top: -140px;
	}
}