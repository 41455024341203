/*------------------------------------*\
	#Call to action
\*------------------------------------*/
.call-to-action {
  /*
	 * For modern browsers
	 * 1. The space content is one way to avoid an Opera bug when the
	 *    contenteditable attribute is included anywhere else in the document.
	 *    Otherwise it causes space to appear at the top and bottom of elements
	 *    that are clearfixed.
	 * 2. The use of `table` rather than `block` is only necessary if using
	 *    `:before` to contain the top-margins of child elements.
	 */
  *zoom: 1;
  min-height: 302px;
  margin: 8px 0 22px;
  background: #ec1d25 url(../images/red-watermark.png) no-repeat center;
  background-size: cover;
}
.call-to-action:before,
.call-to-action:after {
  content: " ";
  display: table;
}
.call-to-action:after {
  clear: both;
}
@media only screen and (min-width: 768px) {
  .call-to-action {
    background-size: auto;
  }
}
@media only screen and (min-width: 1024px) {
  .call-to-action {
    display: flex;
    align-items: center;
  }
}
.call-to-action__title {
  float: left;
  width: 100%;
  padding: 20px 20px;
  margin: 0;
  font: 100 24px/36px "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  letter-spacing: 0.02em;
  color: #fff;
}
.call-to-action__title strong {
  font-weight: 500;
}
.call-to-action__title p {
  margin: 0;
}
@media only screen and (min-width: 425px) {
  .call-to-action__title {
    font-size: 36px;
    line-height: 42px;
  }
}
@media only screen and (min-width: 640px) {
  .call-to-action__title {
    font-size: 45px;
    line-height: 48px;
  }
}
@media only screen and (min-width: 768px) {
  .call-to-action__title {
    font-size: 50px;
    line-height: 53px;
    padding: 20px 40px;
  }
}
@media only screen and (min-width: 1024px) {
  .call-to-action__title {
    width: 55%;
  }
}
.call-to-action__button-container {
  float: left;
  width: 100%;
  padding: 4px 20px 20px;
}
@media only screen and (min-width: 640px) {
  .call-to-action__button-container {
    padding: 4px 40px 20px;
  }
}
@media only screen and (min-width: 1024px) {
  .call-to-action__button-container {
    padding: 4px 40px 0;
    width: 45%;
    text-align: center;
  }
}
