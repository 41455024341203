﻿/*------------------------------------*\
	#Social media
\*------------------------------------*/

@import (reference) "../default.less";

.social-media {

	&,
	ul {
		position: relative;	
		z-index: 1;
		overflow: hidden;
		list-style: none;
		width: auto;
		padding: 0;
		margin: 42px auto 8px;
		text-align: center;

		@media @laptop {
		}

		li {
			position: relative;
			display: inline-block;
			margin: 0 5px;
			color: #fff;
		
			a {
				box-sizing: border-box;
				display: block;
				overflow: hidden;
				text-decoration: none;
				padding: 0;
				width:  50px;
				height: 50px;
				text-indent: -9999px;

				&:after {
					box-sizing: border-box;
					font-size: 26px;
					position: absolute;
					top:  50%;
					left: 50%;
					z-index: 1;
					transform: translate(-50%, -50%);
					text-indent: 0;
				}
			}

			&:hover a {
				color: @color-primary;
			}

			&.facebook { a:after  { .icon(@icon-facebook); } }
			&.linkedin { a:after  { .icon(@icon-linkedin); } }
			&.twitter {  a:after { .icon(@icon-twitter); } }
			&.google-plus { a:after  { .icon(@icon-google-plus); } }
			&.youtube { a:after { .icon(@icon-youtube); } }
			&.soundcloud { a:after { .icon(@icon-soundcloud); } }
			&.vimeo { a:after { .icon(@icon-vimeo); } }
			&.instagram { a:after { .icon(@icon-instagram); } }
			&.flickr { a:after { .icon(@icon-flickr); } }
			&.pinterest { a:after { .icon(@icon-pinterest); } }
		}
	}
}