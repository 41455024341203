/*------------------------------------*\
	#Navigation top
\*------------------------------------*/
.navigation-top ul {
  padding: 10px 3px 12px;
  margin: 0;
  text-align: left;
}
.navigation-top ul ul {
  padding: 0 0 0 20px;
}
@media only screen and (min-width: 1024px) {
  .navigation-top ul {
    text-align: right;
    padding: 10px 40px 12px 246px;
  }
  .navigation-top ul ul {
    display: none;
  }
}
.navigation-top li {
  display: block;
}
.navigation-top li.active a {
  color: #eeebe3;
}
@media only screen and (min-width: 1024px) {
  .navigation-top li {
    display: inline-block;
  }
  .navigation-top li.active a {
    color: #ec1d25;
  }
}
.navigation-top a {
  text-decoration: none;
  font: 400 14px/1.2em "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  padding: 0 17px;
  color: #fff;
}
.navigation-top a:hover {
  color: #eeebe3;
}
@media only screen and (min-width: 1024px) {
  .navigation-top a {
    color: #999999;
  }
  .navigation-top a:hover {
    color: #ec1d25;
  }
}
@media only screen and (min-width: 1024px) {
  .navigation-top {
    height: 50px;
  }
}
