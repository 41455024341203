﻿/*------------------------------------*\
	#specification
\*------------------------------------*/

@import (reference) "../default.less";

.specification {
	.clearfix;
	display: inline-block;
	padding: 7px 0 0;
	margin: 0 0 15px;
}

.specification__row {
	border-top: 1px solid fade(@color-text, 12%);

	&:last-child {
		border-bottom: 1px solid fade(@color-text, 12%);
	}
}

.specification__name,
.specification__value  {
	padding: 12px 20px 9px;
	display: block;

	@media @tablet-small {
		display: table-cell;
	}
}

.specification__name {
	max-width: 196px;
}

.specification__link {
	color: @color-primary;
	text-decoration: none;

	&:hover {
		color: darken(@color-primary, 10%);
	}
}