/*------------------------------------*\
	#Toastr
\*------------------------------------*/
.toast {
  border-bottom: 5px solid #766a62;
  background: url(../images/grey-watermark.png) #faf7f2 no-repeat right -147px center;
  background-size: 300px auto;
}
.toast .date {
  color: #000;
  font: bold 16px/26px "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
}
.toast .title {
  position: relative;
  color: #000;
  font: 300 16px/26px "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  width: auto;
}
.toast .title:after {
  position: relative;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  font-family: 'Icons';
  line-height: 1;
  text-transform: none;
  speak: none;
  content: '\e90b';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: greyscale;
  top: -2px;
  right: -4px;
  font-size: 9px;
  line-height: 1em;
  margin-top: -6px;
  transition: right 0.3s ease-in-out;
}
.toast .toast-progress {
  background-color: #000;
  opacity: 0.2;
}
.toast .toast-close-button {
  color: #000;
  opacity: 1;
  top: -10px;
}
.toast .toast-close-button:after {
  display: none;
}
.toast .toast-close-button:hover {
  background: none;
  color: #ec1d25;
}
.toast:hover {
  background-color: #eeebe3;
}
.toast:hover .title:after {
  right: -6px;
}
#toast-container > div {
  border-bottom: 5px solid #eeebe3;
  padding: 21px 15px 22px 15px;
  opacity: 1;
  box-shadow: none;
  background-position: right -147px center;
}
