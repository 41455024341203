﻿/*------------------------------------*\
	#Breadcrumbs
\*------------------------------------*/

@import (reference) "../default.less";

.breadcrumbs {
	position: relative;
	font: 14px/1.2em @font-text;
	letter-spacing: 0.02em;
	//white-space: nowrap;
	color: @color-text;
	padding: 15px 0 9px;
	margin: 0;
	background: @color-background;

	&,
	ul {
		list-style: none;
	}

	li,
	ul,
	a {
		display: inline-block;
		vertical-align: top;
		//white-space: nowrap;
		//text-overflow: ellipsis;
	}

	ul {
		position: relative;
		padding-left: 7px;
		margin: 0;

		li {
			padding-left: 7px;
			
			&:before {
				position: absolute;
				.icon(@icon-caret-right);
				margin-top: 1px;
				font-size: 5px;
				top:  5px;
				left: 4px;
			}
		} 
	}

	a {
		position: relative;
		text-decoration: none;

		&:hover {
			text-decoration: underline;
		}
	}

	&:before {
		position: absolute;
		content: '';
		width:  1280px;
		height: 100%;
		top:   0;
		right: 100%;
		background: @color-background;
	}
}

.breadcrumbs__wrapper {
	background: @color-background;

	@media @laptop {
		background: none;
	}
}