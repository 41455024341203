/*------------------------------------*\
	#aside
\*------------------------------------*/
.gallery {
  overflow: hidden;
  width: 100%;
  padding: 40px 20px 0;
  margin: 0;
}
.gallery .hide {
  display: none;
}
.gallery ul {
  width: auto;
  padding: 1px 0 0;
  margin-bottom: -40px;
  border: none;
  list-style: none;
}
.gallery ul li {
  position: relative;
  float: left;
  margin: 0 0 40px;
  border: none;
}
.gallery ul li.video a:after {
  position: absolute;
  z-index: 2;
  content: '';
  width: 56px;
  height: 40px;
  margin: -20px 0 0 -28px;
  top: 50%;
  left: 50%;
  background: url(../images/youtube-play.png);
  background-image: url(../images/youtube-play.svg);
}
.gallery ul li a {
  position: relative;
  display: block;
}
.gallery ul li.active a:before {
  display: none !important;
}
@media only screen and (min-width: 768px) {
  .gallery {
    padding: 40px 20px 0;
  }
}
@media only screen and (min-width: 1024px) {
  .gallery {
    padding: 40px 0 0;
  }
}
.gallery__big-image {
  margin: 0 0 40px;
}
.gallery__video a {
  display: block;
  position: relative;
}
.gallery__video a:after {
  position: absolute;
  content: '';
  width: 70px;
  height: 50px;
  margin: -25px 0 0 -35px;
  top: 50%;
  left: 50%;
  background: url(../images/video-overlay.png) no-repeat center;
  background-image: url(../images/video-overlay.svg);
}
