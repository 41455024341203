﻿/*------------------------------------*\
	#Vacancies count
\*------------------------------------*/

@import (reference) "../default.less";

.vacancies-count__wrapper {
	position: relative;
}

.vacancies-count {
	position: absolute;
	padding: 2px 5px;
	border-radius: 50%;
	top:   -8px;
	right: 2px;
	font: 700 11px/12px @font-text;
	background: @color-primary-dark;
	color: @color-white;

	@media @laptop {
		background: @color-primary;
	}

	.side-nav & {
		position: relative;
		right: auto;
		left: 2px;
	}
}