/*------------------------------------*\
	#Contact box.
\*------------------------------------*/
.contact-box {
  position: fixed;
  right: -260px;
  z-index: 10;
  width: 320px;
  height: 130px;
  bottom: 0;
  transition: right 250ms ease;
  /*&.open {
		right: 0;
	}*/
}
@media only screen and (min-width: 640px) {
  .contact-box {
    margin-top: -65px;
    top: 50%;
    bottom: auto;
  }
}
.contact-box__telephone,
.contact-box__pointer {
  position: relative;
  box-sizing: border-box;
  font: 500 18px/30px "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  height: 61px;
  padding: 15px 20px 15px 30px;
  margin-left: 61px;
  transition: right 250ms ease;
  right: 0;
}
.contact-box__telephone:before,
.contact-box__pointer:before {
  font-size: 26px;
  position: absolute;
  left: -40px;
  z-index: 1;
  cursor: default;
}
.contact-box__telephone:after,
.contact-box__pointer:after {
  position: absolute;
  top: 0;
  left: -61px;
  content: '';
  width: 61px;
  height: 61px;
  font-size: 36px;
}
.contact-box__telephone a,
.contact-box__pointer a {
  text-decoration: none;
}
.contact-box__telephone a:hover,
.contact-box__pointer a:hover {
  text-decoration: underline;
}
.contact-box__telephone a:hover,
.contact-box__pointer a:hover {
  color: inherit;
}
.contact-box__telephone:hover,
.contact-box__pointer:hover,
.contact-box__telephone.open,
.contact-box__pointer.open {
  right: 260px;
}
.contact-box__telephone {
  background: #ec1d25;
  color: #fff;
}
.contact-box__telephone:before {
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  font-family: 'Icons';
  line-height: 1;
  text-transform: none;
  speak: none;
  content: '\e917';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: greyscale;
  background: #ec1d25;
  margin-top: 2px;
  left: -44px;
}
.contact-box__telephone:after {
  background: #ec1d25;
}
.contact-box__pointer {
  background: #faf7f2;
  color: rgba(0, 0, 0, 0.4);
}
.contact-box__pointer:before {
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  font-family: 'Icons';
  line-height: 1;
  text-transform: none;
  speak: none;
  content: '\e91d';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: greyscale;
  left: -44px;
  background: #faf7f2;
}
.contact-box__pointer:after {
  background: #faf7f2;
}
