﻿/*------------------------------------*\
	#Navigation
\*------------------------------------*/

@import (reference) "../default.less";

.navigation {
	padding: 0;
	overflow-x: hidden;
	overflow-y: auto;
		
	ul {
		display: inline-block;
		width: 100%;
		margin: 0;
		padding: 0;
		list-style: none;

		li {
				
			a {
				position: relative;
				display: block;
				font: 500 15px/1.2em @font-text;
				color: @color-white;
				text-decoration: none;
				padding: 13px 23px 12px;
				text-transform: uppercase;

				span {
					display: inline-block;
					position: relative;

					&:after {
						position: absolute;
						content: '';
						width:  100%;
						height: 2px;
						bottom:  -2px;
						left:    0;
						background: @color-white;
						transform: scaleX(0);
						transition: transform 0.2s ease-in-out;
					}
				}
			}

			&:hover > a span:after,
			&.active > a span:after {
				transform: scaleX(1)
			}
			
			&.home ul {
				display: none;
			}
		}

		ul li a {
			padding: 13px 23px 12px 43px;
		}
	}

	@media @laptop {
		.clearfix;
		position: relative;
		display: flex;
		height: 70px;
		padding: 0  0 0 285px;
		margin: 0;
		overflow: visible;
		align-items: center;
		justify-content: flex-end;
		
		> ul {
			background: none;
			width:  auto;
			height: auto;
			padding: 0 42px 0 0;
			margin: 0;

			> li {
				float: left;
				position: relative;

				a {
					white-space: nowrap;
					transition: none;
					color: @color-white;
				}

				&.home {
					width:  62px;
					height: 20px; 

					a{
						position: relative;
						text-indent: 9999px;
						overflow: hidden;
						transition: color 0.2s ease-in-out;

						&:after {
							position: absolute;
							.icon(@icon-house);
							font-size: 20px;
							margin: -10px 0 0 -10px;
							top:  50%;
							left: 50%;
							text-indent: 0;
						}
					}

					&:hover a {
						color: @color-background-dark !important;
					}
				}

				&:hover {
					
					a {
						
					}
				}

				&.active:hover > a {
					color: @color-white;
				}

				ul {
					display: none;
				}
			}
		}

		.headroom--not-top & {
			height: 77px;
		}
	}
}