﻿/*------------------------------------*\
	#Product tabs
\*------------------------------------*/

@import (reference) "../default.less";

.product-tabs {
	position: relative;
	padding: 0 0 31px;
	border-bottom: 1px solid fade(@color-text, 12%);

	&:after {
		position: absolute;
		content: '';
		width: 1280px;
		height: 1px;
		right:  100%;
		bottom: -1px;
		background: fade(@color-text, 12%);
	}

	&:last-child {
		border-bottom: none;
		
		&:after {
			display: none;
		}
	}
}