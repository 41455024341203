﻿/*------------------------------------*\
	#Form
\*------------------------------------*/

@import (reference) "../default.less";

.form {
	padding: 4px 0 14px;

	label {
		float: left;
		display: block;
		width: 100%;

		@media @tablet-small {
			width: 227px;
		}
	}
	
	.radio,
	input,
	textarea,
	.select-wrapper {
		float: left;
		width: 100%; 

		@media @tablet-small {
			width: calc(~"100% - 227px"); 
		}
	}
}

.form__control.is-checkbox {
	display: flex;
	align-items: center;

	input {
		width: auto;
		margin: 0 20px 0 0;

		@media @tablet-small {
			width: auto;
		}
	}

	label {
		width: auto;
	}
}

.form__control {
	margin: 0 0 10px;
}

.form__control--button {
	text-align: right;
}

.form--product {
	.clearfix;
}

.form__content {
	overflow: hidden;
}