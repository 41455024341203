﻿/*------------------------------------*\
	#Product
\*------------------------------------*/

@import (reference) "../default.less";

.product {
	.clearfix;
	margin: 0 0 40px;
}

.product__container {
	.clearfix;
	display: flex;
	flex-wrap: wrap;
	background: @color-background;
	transition: background 0.2s ease-in-out;

	section:first-child & {
		background: darken(@color-background, 5%);

		&:hover {
			background: @color-background-dark;
		}
	}

	&:hover {
		background: @color-background-dark;

		a {
			color: inherit;
		}
	}

	a {
		display: block;
		width: 100%;
		height: 100%;
	}
}

.product__image-container {
	float: left;
	width: 100%;
	border: 1px solid fade(@color-text, 12%);
	background: @color-white;

	@media @laptop-med {
		width:  50%;
	}
}

.product__placeholder {
	.responsive-container(600, 600);
	background: url(../images/placeholder.jpg) no-repeat center;
	background-image: url(../images/placeholder.svg), none;
	background-size: cover;
}

.product__text-container {
	float: left;
	width:  100%;
	padding: 36px 20px 112px;

	p {
		letter-spacing: 0.02em;
		margin: 0;
	}

	@media @mobile-large {
		padding: 36px 40px 112px;
	}

	@media @laptop-med {
		width:  50%;
	}
}

.product__text-content {
	position: relative;
	height: 100%;
}

.product__title {
	font: 300 27px/28px @font-text;
	letter-spacing: 0.02em;
	margin: 0 0 32px;
}

.product__button {
	position: absolute;
	bottom: -22px;
	transform: translateY(100%);
}