/*------------------------------------*\
	#Button
\*------------------------------------*/
.button {
  position: relative;
  display: inline-block;
  font: 500 15px/1.2em "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  color: #000;
  padding: 17px 30px 15px 0;
  transition: background 0.2s ease-in-out, border-color 0.2s ease-in-out, color 0.2s ease-in-out;
  -webkit-appearance: none;
  cursor: pointer;
  text-transform: uppercase;
  text-decoration: none;
  background-color: transparent;
  border: none;
  margin: -2px 0 0;
}
.button:after {
  position: absolute;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  font-family: 'Icons';
  line-height: 1;
  text-transform: none;
  speak: none;
  content: '\e90b';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: greyscale;
  font-size: 9px;
  margin: -3px 0 0;
  top: 50%;
  right: 6px;
  transition: right 0.2s ease-in-out, margin 0.2s ease-in-out;
}
.text .button {
  margin-right: 18px;
}
.text .button:last-child {
  margin-bottom: 22px;
}
.button:hover {
  color: #ec1d25;
}
.button:hover:after {
  right: 4px;
}
.button-hover {
  color: #ec1d25;
}
.button-hover:after {
  right: 4px;
}
.button--primary,
.button--white,
.button--horticulture,
.button--agricultural {
  padding: 17px 44px 15px 20px;
}
.button--no-arrow {
  padding: 17px 20px 15px 20px;
}
.button--no-arrow:after {
  display: none;
}
.button--horticulture-borderd,
.button--agricultural-borderd {
  padding: 13px 16px 11px;
  background: #fff;
}
.button--primary {
  background: #ec1d25;
  color: #fff;
}
.button--primary:after {
  right: 18px;
}
.button--primary:hover {
  background: #c51118;
  color: #fff;
}
.button--primary:hover:after {
  right: 16px;
}
.button-hover--primary {
  background: #c51118;
  color: #fff;
}
.button-hover--primary:after {
  right: 16px;
}
.button--white {
  background: #fff;
  color: #ec1d25;
}
.button--white:after {
  right: 18px;
}
.button--white:hover {
  background: #e6e6e6;
  color: #ec1d25;
}
.button--white:hover:after {
  right: 16px;
}
.button-hover--white {
  background: #e6e6e6;
  color: #ec1d25;
}
.button-hover--white:after {
  right: 16px;
}
.button--horticulture {
  background: #77b039;
  color: #fff;
}
.button--horticulture:after {
  right: 18px;
}
.button--horticulture:hover {
  background: #5d892d;
  color: #fff;
}
.button--horticulture:hover:after {
  right: 16px;
}
.button-hover--horticulture {
  background: #5d892d;
  color: #fff;
}
.button-hover--horticulture:after {
  right: 16px;
}
.button--horticulture-borderd {
  border: 4px solid #77b039;
  color: #77b039;
}
.button--horticulture-borderd:hover {
  color: #fff;
  background-color: #add088;
}
.button--hover-horticulture-borderd {
  color: #fff;
  background-color: #add088;
}
.button--hover-horticulture-borderd-full {
  color: #fff;
  background-color: #77b039;
}
.button--agricultural {
  background: #ffb400;
  color: #fff;
}
.button--agricultural:after {
  right: 18px;
}
.button--agricultural:hover {
  background: #cc9000;
  color: #fff;
}
.button--agricultural:hover:after {
  right: 16px;
}
.button-hover--agricultural {
  background: #cc9000;
  color: #fff;
}
.button-hover--agricultural:after {
  right: 16px;
}
.button--agricultural-borderd {
  border: 4px solid #ffb400;
  color: #ffb400;
}
.button--agricultural-borderd:hover {
  color: #fff;
  background-color: #ffd266;
}
.button--hover-agricultural-borderd {
  color: #fff;
  background-color: #ffd266;
}
.button--hover-agricultural-borderd-full {
  color: #fff;
  background-color: #ffb400;
}
.button--center {
  width: 100%;
  padding: 17px 36px 15px 20px;
  text-align: center;
}
.button--center:after {
  position: relative;
  top: -1px;
  right: -16px;
}
.button--center:hover:after {
  right: -18px;
}
.button-hover--center:after {
  right: -18px;
}
