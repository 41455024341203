﻿/*------------------------------------*\
	#Image
\*------------------------------------*/

@import (reference) "../default.less";

.image--big {
	margin: 7px 0 33px;
}

.image--right {
	margin: 7px 0 22px 0;
	
	@media @tablet-small {
		float: right;
		max-width: 275px;
		margin: 7px 0 22px 30px;
	}

	/*@media @tablet {
		float: none;
		max-width: 100%;
		margin: 7px 0 22px 0;
	}*/

	@media @laptop {
		float: right;
		max-width: 275px;
		margin: 7px 0 22px 30px;
	}

	@media @laptop-med {
		max-width: 416px;
	}
}