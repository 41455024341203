/*------------------------------------*\
	#Happening aside
\*------------------------------------*/
.aside-happening {
  position: relative;
  padding: 129px 20px 40px;
  margin: 0 0 40px;
  background: #faf7f2 url(../images/grey-watermark.png) no-repeat center right -192px;
  background-size: 379px 379px;
  border-bottom: 10px solid #eeebe3;
}
.aside-happening:before {
  position: absolute;
  content: '';
  width: 60px;
  height: 63px;
  margin: 0 0 0 -30px;
  left: 50%;
  top: 40px;
  background: url(../images/calander.png);
  background-image: url(../images/calander.svg);
}
@media only screen and (min-width: 425px) {
  .aside-happening {
    padding: 129px 40px 40px;
  }
}
.aside-happening--agricultural {
  border-bottom-color: #ffb400;
}
.aside-happening--horticulture {
  border-bottom-color: #77b039;
}
.aside-happening__date {
  display: block;
  width: 100%;
  text-align: center;
  font: 300 17px/28px "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  letter-spacing: 0.02em;
}
.aside-happening__title {
  font: 100 20px/28px "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  text-align: center;
  text-transform: uppercase;
  margin: 0 0 34px;
}
