﻿/*------------------------------------*\
	#Header
\*------------------------------------*/

@import (reference) "../default.less";

header {
	position: relative;
	width: 100%;
	top: 0;
	z-index: 2;

	@media @laptop {
		position: fixed;
		background: @color-primary;
		height: auto;

		&:before {
			position: absolute;
			content: '';
			width:  100%;
			height: 50px;
			background: @color-white;
			top:  0;
			left: 0;
		}
	}
}