﻿/*------------------------------------*\
	#Project frontpage
\*------------------------------------*/

@import (reference) "../default.less";

.project-frontpage {
	position: relative;
	background: @color-background url(../images/grey-watermark.png) no-repeat center left -520px;
	border-bottom: 10px solid @color-background-dark;
	margin-bottom: 40px;

	@media @laptop {
		background-position: center left -420px;
	}
}

.project-frontpage--horticulture {
	border-bottom-color: @color-horticulture;
}

.project-frontpage--agricultural {
	border-bottom-color: @color-agricultural;
}

.project-frontpage__text-container {
	position: relative;
	width: 100%;
	padding: 33px 20px 46px;

	&:after {
		position: absolute;
		z-index: 1;
		content: '';
		width:  100%;
		height: 180px;
		bottom: -180px;
		left:   0;
		background: linear-gradient(to bottom, rgba(250,247,242,1) 0%,rgba(250,247,242,1) 18%,rgba(250,247,242,0) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#faf7f2', endColorstr='#00faf7f2',GradientType=1 );

		@media @tablet-small {
			width:  373px;
			height: 100%;
			top:    0;
			right:  -373px;
			bottom: auto;
			left:   auto;
			background: linear-gradient(to right, rgba(250,247,242,1) 0%,rgba(250,247,242,1) 18%,rgba(250,247,242,0) 100%);
		}
	}

	@media @tablet-small {
		width: 65%;
		padding: 133px 40px 146px;
	}

	@media @tablet {
		width: 50%;
		padding: 133px 40px 146px;
	}
}

.project-frontpage__title-prefix {
	font: 300 28px/44px @font-text;
	letter-spacing: 0.02em;
}

.project-frontpage__title {
	font: 300 38px/44px @font-text;
	letter-spacing: 0.02em;
	text-transform: uppercase; 
	margin: 0 0 34px;
}

.project-frontpage__image {
	width:  100%;
	height: 250px;
	background-size: cover;
	background-position: center;

	@media @tablet-small {
		position: absolute;
		width:  35%;
		height: 100%;
		top:   0;
		right: 0;
	}

	@media @tablet {
		width:  50%;
	}
}

.project-frontpage__more-projects {
	position: absolute;
	padding: 0 30px 0 0;
	right:  0;
	bottom: -45px;
	font: 500 15px/1.2em @font-text;
	text-transform: uppercase;
	text-decoration: none;

	&:after {
		position: absolute;
		.icon(@icon-caret-right);
		font-size: 9px;
		top:   5px;
		right: 0;
		transition: right 0.2s ease-in-out;
	}

	&:hover:after {
		right: -2px;
	}
}