/*------------------------------------*\
	#Breadcrumbs
\*------------------------------------*/
.breadcrumbs {
  position: relative;
  font: 14px/1.2em "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  letter-spacing: 0.02em;
  color: #333333;
  padding: 15px 0 9px;
  margin: 0;
  background: #faf7f2;
}
.breadcrumbs,
.breadcrumbs ul {
  list-style: none;
}
.breadcrumbs li,
.breadcrumbs ul,
.breadcrumbs a {
  display: inline-block;
  vertical-align: top;
}
.breadcrumbs ul {
  position: relative;
  padding-left: 7px;
  margin: 0;
}
.breadcrumbs ul li {
  padding-left: 7px;
}
.breadcrumbs ul li:before {
  position: absolute;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  font-family: 'Icons';
  line-height: 1;
  text-transform: none;
  speak: none;
  content: '\e90b';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: greyscale;
  margin-top: 1px;
  font-size: 5px;
  top: 5px;
  left: 4px;
}
.breadcrumbs a {
  position: relative;
  text-decoration: none;
}
.breadcrumbs a:hover {
  text-decoration: underline;
}
.breadcrumbs:before {
  position: absolute;
  content: '';
  width: 1280px;
  height: 100%;
  top: 0;
  right: 100%;
  background: #faf7f2;
}
.breadcrumbs__wrapper {
  background: #faf7f2;
}
@media only screen and (min-width: 1024px) {
  .breadcrumbs__wrapper {
    background: none;
  }
}
