/*------------------------------------*\
	#Intro block frontpage
\*------------------------------------*/
.intro-block-frontpage__block {
  position: relative;
  padding-top: 416px;
  background: #faf7f2 no-repeat top center;
  border-bottom: 10px solid #eeebe3;
  margin: 0 0 40px;
}
.intro-block-frontpage__block p {
  position: relative;
  z-index: 1;
  font: 300 28px/36px "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  color: #333333;
  letter-spacing: 0.02em;
  text-align: center;
  padding: 0 40px;
  margin: -70px 0 22px;
  background: #faf7f2;
}
.intro-block-frontpage__block--triangle-bottom {
  padding-top: 0;
  display: flex;
  flex-direction: column;
}
.intro-block-frontpage__block--triangle-bottom:before {
  position: absolute;
  z-index: 1;
  content: '';
  width: 0;
  height: 0;
  margin: 0 0 0 -20.5px;
  border-style: solid;
  border-width: 21px 20.5px 0 20.5px;
  border-color: #faf7f2 transparent transparent transparent;
  bottom: -21px;
  left: 50%;
}
.intro-block-frontpage__block--triangle-bottom:after {
  position: absolute;
  content: '';
  width: 0;
  height: 0;
  margin: 0 0 0 -25.5px;
  border-style: solid;
  border-width: 25px 25.5px 0 25.5px;
  border-color: #eeebe3 transparent transparent transparent;
  bottom: -35px;
  left: 50%;
}
.intro-block-frontpage__block--agriculture {
  border-bottom-color: #ffb400;
}
.intro-block-frontpage__block--agriculture .intro-block-frontpage__image {
  background-image: url(../images/agriculture-intro-block.png);
}
.intro-block-frontpage__block--horticulture {
  border-bottom-color: #77b039;
}
.intro-block-frontpage__block--horticulture .intro-block-frontpage__image {
  background-image: url(../images/horticulture-intro-block.png);
}
.intro-block-frontpage__image {
  position: absolute;
  width: 100%;
  height: 416px;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: center;
}
.intro-block-frontpage__image--fading:before {
  position: absolute;
  z-index: 1;
  content: '';
  width: 100%;
  height: 180px;
  bottom: 70px;
  left: 0;
  background: linear-gradient(to top, #faf7f2 0%, rgba(250, 247, 242, 0) 100%);
}
.intro-block-frontpage__title {
  position: absolute;
  width: 100%;
  top: 12px;
  left: 0;
  text-align: center;
}
.intro-block-frontpage__list ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.intro-block-frontpage__list > ul {
  border-bottom: 1px solid rgba(51, 51, 51, 0.12);
}
.intro-block-frontpage__list a,
.intro-block-frontpage__list strong {
  position: relative;
  display: block;
  text-decoration: none;
  padding: 17px 38px 15px;
  border-top: 1px solid rgba(51, 51, 51, 0.12);
  font: 500 15px/1.2em "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  letter-spacing: 0.02em;
  text-transform: uppercase;
}
.intro-block-frontpage__list a strong,
.intro-block-frontpage__list strong strong {
  margin: -17px -38px -15px;
  border-top: 0;
}
.intro-block-frontpage__list a + ul.open,
.intro-block-frontpage__list strong + ul.open {
  height: auto;
}
.intro-block-frontpage__list strong {
  padding-right: 61px;
}
.intro-block-frontpage__list strong:before {
  position: absolute;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  font-family: 'Icons';
  line-height: 1;
  text-transform: none;
  speak: none;
  content: '\e915';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: greyscale;
  width: 51px;
  height: 50px;
  padding-top: 16.5px;
  border-left: 1px solid rgba(51, 51, 51, 0.12);
  top: 0;
  right: 0;
  font-size: 17px;
  text-align: center;
}
.intro-block-frontpage__list li ul {
  overflow: hidden;
  height: 0;
}
.intro-block-frontpage__list li:hover ul {
  height: auto;
}
.intro-block-frontpage__list li li a {
  font-size: 13px;
  padding-left: 55px;
  background: #eeebe3;
}
.intro-block-frontpage__list li li a:before {
  position: absolute;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  font-family: 'Icons';
  line-height: 1;
  text-transform: none;
  speak: none;
  content: '\e91e';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: greyscale;
  font-size: 7px;
  top: 20.5px;
  left: 42px;
}
.intro-block-frontpage__slideshow {
  position: relative;
  height: 100%;
}
.intro-block-frontpage__slideshow-container {
  flex: 1 1 auto;
}
.intro-block-frontpage__slideshow.lSSlideOuter .lSPager.lSpg {
  width: 100%;
}
.intro-block-frontpage__slideshow.lSSlideOuter .lSPager.lSpg > li a {
  width: 12px;
  height: 12px;
  background: #eeebe3;
}
.intro-block-frontpage__slideshow.lSSlideOuter .lSPager.lSpg > li.active a {
  background: #ec1d25;
}
.intro-block-frontpage__outro {
  position: relative;
  z-index: 1;
  display: block;
  font: 500 15px/1.2em "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.02em;
  text-align: center;
  padding: 17px 20px 16px;
  border-top: 1px solid rgba(51, 51, 51, 0.12);
  text-decoration: none;
  flex: 0 0 auto;
}
.intro-block-frontpage__outro:after {
  position: absolute;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  font-family: 'Icons';
  line-height: 1;
  text-transform: none;
  speak: none;
  content: '\e911';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: greyscale;
  font-size: 17px;
  margin: 0 0 0 -8.5px;
  bottom: -8px;
  left: 50%;
}
