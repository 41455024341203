/*------------------------------------*\
	#Dealers aside 
\*------------------------------------*/
.aside-dealers {
  padding: 35px 20px 28px;
  margin: 0 0 40px;
  background: #faf7f2;
  border-bottom: 10px solid #eeebe3;
}
@media only screen and (min-width: 425px) {
  .aside-dealers {
    padding: 35px 40px 28px;
  }
}
.aside-dealers__title {
  font: 300 20px/28px "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  text-align: center;
  margin: 0 0 17px;
}
.aside-dealers__image {
  width: 252px;
  height: 138px;
  margin: 0 auto 21px;
  background: url(../images/aside-dealers-image.png);
}
@media only screen and (min-width: 1024px) {
  .aside-dealers__image {
    margin: 0 -15px 21px;
  }
}
.aside-dealers .aside-dealers__button {
  margin: 0 0 10px;
}
