﻿/*------------------------------------*\
	#Columns 
\*------------------------------------*/

@import (reference) "../default.less";

// Columns
.columns {
	.clearfix;

	// Columns gutter
	.columns-gutter(20);
	.columns-gutter(30);
	.columns-gutter(32);
	.columns-gutter(40);
}

// Column
.column {
	float: left;
	clear: left;
	width: 100%;
}

// Dual
.column--dual {
	.responsive-columns(2);
}

// Triple
.column--triple {
	.responsive-columns(3);
}

.column--triple-2 {
	.responsive-columns(1.5);
}

// Quad
.column--quad {
	.responsive-columns(4);
}

// Quin
.column--quin {
	.responsive-columns(5);
}

// Gutter creation mixin
.columns-gutter(@size) {
	@size-half:  (@size / 2 * 1px);

	&--gutter-@{size} {
		margin-left:  (@size-half * -1);
		margin-right: (@size-half * -1);

		.column {
			padding-left:  (@size-half);
			padding-right: (@size-half);
		}
	}
}

// Responsive columns creation mixin
.responsive-columns(@amount) {
	
	@styles: {
		width: (100% / @amount);
	
		&:nth-child(n + 1) {
			clear: none;
		}

		&:nth-child(@{amount}n + 1) {
			clear: left;
		}
	};
	
	@styles();

	&-mobile-large {
		
		@media @mobile-large {
			@styles();
		}
	}

	&-tablet-small {

		@media @tablet-small {
			@styles();
		}
	}

	&-tablet {

		@media @tablet {
			@styles();
		}
	}

	&-laptop {

		@media @laptop {
			@styles();
		}
	}

	&-laptop-med {

		@media @laptop-med {
			@styles();
		}
	}

	&-laptop-large {

		@media @laptop-large {
			@styles();
		}
	}

	&-desktop-small {
		
		@media @desktop-small {
			@styles();
		}
	}
}