﻿/*------------------------------------*\
	#aside
\*------------------------------------*/

@import (reference) "../default.less";

.gallery {
	overflow: hidden;
	width: 100%;
	padding: 40px 20px 0; 
	margin: 0;

	.hide {
		display: none;
	}

	ul {
		width: auto;
		padding: 1px 0 0;
		margin-bottom: -40px;
		border: none;
		list-style: none;

		li {
			position: relative;
			float: left;
			margin: 0 0 40px;
			border: none;

			&.video a:after {
				position: absolute;
				z-index: 2;
				content: '';
				width: 56px;
				height: 40px;
				margin: -20px 0 0 -28px;
				top: 50%;
				left: 50%;
				background: url(../images/youtube-play.png);
				background-image: url(../images/youtube-play.svg);
			}

			a {
				position: relative;
				display: block;
			}

			&.active a:before {
				display: none !important;
			}
		}
	}

	@media @tablet {
		padding: 40px 20px 0; 
	}

	@media @laptop {
		padding: 40px 0 0; 
	}
}

.gallery__big-image {
	margin: 0 0 40px;
}

.gallery__video a {
	display: block; 
	position: relative;

	&:after {
		position: absolute;
		content: '';
		width:  70px;
		height: 50px;
		margin: -25px 0 0 -35px;
		top:  50%;
		left: 50%;
		background: url(../images/video-overlay.png) no-repeat center;
		background-image: url(../images/video-overlay.svg);
	}
}