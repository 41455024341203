/*------------------------------------*\
	#Band
\*------------------------------------*/
.logo {
  /*
	 * For modern browsers
	 * 1. The space content is one way to avoid an Opera bug when the
	 *    contenteditable attribute is included anywhere else in the document.
	 *    Otherwise it causes space to appear at the top and bottom of elements
	 *    that are clearfixed.
	 * 2. The use of `table` rather than `block` is only necessary if using
	 *    `:before` to contain the top-margins of child elements.
	 */
  *zoom: 1;
  position: absolute;
  z-index: 1;
  text-indent: -9999px;
  background: url(../images/logo-holland-heater-full-white.png) no-repeat;
  background-image: url(../images/logo-holland-heater-full-white.svg), none;
  background-size: cover;
  width: 200px;
  height: 122px;
  margin: 0;
  top: 8px;
  left: 20px;
}
.logo:before,
.logo:after {
  content: " ";
  display: table;
}
.logo:after {
  clear: both;
}
.logo a {
  display: block;
  width: 100%;
  height: 100%;
}
.logo img {
  display: none;
}
@media only screen and (min-width: 1024px) {
  .logo {
    width: 226px;
    height: 138px;
  }
}
@media only screen and (min-width: 1440px) {
  .logo {
    left: -23px;
  }
}
.logo--footer {
  position: relative;
  width: 256px;
  height: 163px;
  margin: 0 auto 26px;
  top: auto;
  left: auto;
  background: url(../images/logo-holland-heater-red.png) no-repeat top center;
  background-image: url(../images/logo-holland-heater-red.svg), none;
}
.logo--footer a {
  position: relative;
  z-index: 1;
}
.logo--footer a:before {
  position: absolute;
  content: '';
  width: 232px;
  height: 1px;
  margin: 0 0 0 -116px;
  bottom: -27px;
  left: 50%;
  background: rgba(255, 255, 255, 0.2);
}
.logo--footer:before {
  position: absolute;
  content: '';
  width: 227px;
  height: 6px;
  margin: 0 0 0 -113.5px;
  bottom: 14px;
  left: 50%;
  background: url(../images/worldwide.png) no-repeat center;
  background-image: url(../images/worldwide.svg), none;
}
@media only screen and (min-width: 1024px) {
  .logo--footer {
    width: 256px;
    height: 163px;
    margin: -34px auto 26px;
  }
}
@media only screen and (min-width: 1440px) {
  .logo--footer {
    left: auto;
  }
}
