﻿/*------------------------------------*\
	#Aside
\*------------------------------------*/

@import (reference) "../default.less";

.usp-block {
	position: relative;
	padding: 40px 0 0;
	margin: 8px 0 0;
	background: url(../images/grey-watermark.png) @color-background center center no-repeat;
	background-image: url(../images/grey-watermark.png), none;
	
	@media @mobile-large {
		padding: 40px 20px 30px;
	}

	@media @laptop {
		padding: 60px 40px 50px;
	}
}

.usp-block__list {
	color: @color-black;
	font: 300 17px/1.2em @font-text;

	ul {
		list-style: none;
		padding: 0;
		margin: 0;
	}

	li {
		position: relative;
		padding: 16px 40px 14px 53px;

		&:nth-child(odd) {
			background: @color-background-dark;
		}

		&:before {
			position: absolute;
			.icon(@icon-plus);
			font-size: 13px;
			top:  20px;
			left: 20px;
		}


	}
}

.usp-block__logo {
	position: relative;
	width: 100%;
	height: 204px;
	margin: 0 0 40px;
	background: url(../images/logo-holland-heater-full-white.png) no-repeat center top;
	background-image: url(../images/logo-holland-heater-full-white.svg), none;
	background-size: 260px auto;

	&:before {
		position: absolute;
		content: '';
		width: 260px;
		height: 7px;
		margin: 0 0 0 -130px;
		left:   50%;
		bottom: 0;
		background: url(../images/worldwide-black.png) no-repeat;
		background: url(../images/worldwide-black.svg), none;
		background-size: cover;

		@media @mobile-large {
			width: 300px;
			margin: 0 0 0 -150px;
		}

		@media @laptop {
			width: 364px;
			height: 9px;
			margin: 0 0 0 -182px;
		}
	}

	@media @mobile-large {
		height: 244px;
		background-size: 318px auto;

	}

	@media @laptop {
		margin: 0;
	}
}

.usp-block__link {
	position: absolute;
	padding: 0 30px 0 0;
	right:  0;
	bottom: -35px;
	font: 500 15px/1.2em @font-text;
	text-transform: uppercase;
	text-decoration: none;

	&:after {
		position: absolute;
		.icon(@icon-caret-right);
		font-size: 9px;
		top:   5px;
		right: 0;
		transition: right 0.2s ease-in-out;
	}

	&:hover:after {
		right: -2px;
	}
}