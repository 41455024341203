/*------------------------------------*\
	#Aside
\*------------------------------------*/
.usp-block {
  position: relative;
  padding: 40px 0 0;
  margin: 8px 0 0;
  background: url(../images/grey-watermark.png) #faf7f2 center center no-repeat;
  background-image: url(../images/grey-watermark.png), none;
}
@media only screen and (min-width: 425px) {
  .usp-block {
    padding: 40px 20px 30px;
  }
}
@media only screen and (min-width: 1024px) {
  .usp-block {
    padding: 60px 40px 50px;
  }
}
.usp-block__list {
  color: #000;
  font: 300 17px/1.2em "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
}
.usp-block__list ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.usp-block__list li {
  position: relative;
  padding: 16px 40px 14px 53px;
}
.usp-block__list li:nth-child(odd) {
  background: #eeebe3;
}
.usp-block__list li:before {
  position: absolute;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  font-family: 'Icons';
  line-height: 1;
  text-transform: none;
  speak: none;
  content: '\e91c';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: greyscale;
  font-size: 13px;
  top: 20px;
  left: 20px;
}
.usp-block__logo {
  position: relative;
  width: 100%;
  height: 204px;
  margin: 0 0 40px;
  background: url(../images/logo-holland-heater-full-white.png) no-repeat center top;
  background-image: url(../images/logo-holland-heater-full-white.svg), none;
  background-size: 260px auto;
}
.usp-block__logo:before {
  position: absolute;
  content: '';
  width: 260px;
  height: 7px;
  margin: 0 0 0 -130px;
  left: 50%;
  bottom: 0;
  background: url(../images/worldwide-black.png) no-repeat;
  background: url(../images/worldwide-black.svg), none;
  background-size: cover;
}
@media only screen and (min-width: 425px) {
  .usp-block__logo:before {
    width: 300px;
    margin: 0 0 0 -150px;
  }
}
@media only screen and (min-width: 1024px) {
  .usp-block__logo:before {
    width: 364px;
    height: 9px;
    margin: 0 0 0 -182px;
  }
}
@media only screen and (min-width: 425px) {
  .usp-block__logo {
    height: 244px;
    background-size: 318px auto;
  }
}
@media only screen and (min-width: 1024px) {
  .usp-block__logo {
    margin: 0;
  }
}
.usp-block__link {
  position: absolute;
  padding: 0 30px 0 0;
  right: 0;
  bottom: -35px;
  font: 500 15px/1.2em "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  text-transform: uppercase;
  text-decoration: none;
}
.usp-block__link:after {
  position: absolute;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  font-family: 'Icons';
  line-height: 1;
  text-transform: none;
  speak: none;
  content: '\e90b';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: greyscale;
  font-size: 9px;
  top: 5px;
  right: 0;
  transition: right 0.2s ease-in-out;
}
.usp-block__link:hover:after {
  right: -2px;
}
