﻿/*------------------------------------*\
	#Toggler
\*------------------------------------*/

@import (reference) "../default.less";


.toggler {
	z-index: 11;
	position: fixed;
	width:  60px;
	height: 60px;
	top:   20px;
	right: 0;
	background: @color-primary;
	border: 1px solid @color-primary;
	text-indent: -9999px;
	color: @color-white;
	cursor: pointer;

	&:after {
		position: absolute;
		.icon(@icon-close);
		font-size: 35px;
		text-indent: 0;
		top:  11px;
		left: 11px;
	}

	&.toggler--closed:after {
		.icon(@icon-navicon);
	}

	@media @laptop {
		display: none;
	}
}