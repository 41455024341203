﻿/*------------------------------------*\
	#Footer menu
\*------------------------------------*/

@import (reference) "../default.less";

.footer-menu {
	margin: 0 0 30px;

	ul {
		padding: 0;
		margin:  0;
		list-style: none;
	}

	li:last-child a {
		border-bottom: 1px solid fade(@color-white, 20%);
	}

	a {
		display: block;
		padding: 16px 18px 15px 18px;
		border-top: 1px solid fade(@color-white, 20%);
		font: 500 15px/1.2em @font-text;
		color: @color-white;
		text-decoration: none;
		text-transform: uppercase;

		&:hover {
			color: @color-primary;
		}
	}
}