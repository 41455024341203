/*------------------------------------*\
	#Item navigation
\*------------------------------------*/
.item-navigation {
  /*
	 * For modern browsers
	 * 1. The space content is one way to avoid an Opera bug when the
	 *    contenteditable attribute is included anywhere else in the document.
	 *    Otherwise it causes space to appear at the top and bottom of elements
	 *    that are clearfixed.
	 * 2. The use of `table` rather than `block` is only necessary if using
	 *    `:before` to contain the top-margins of child elements.
	 */
  *zoom: 1;
  font: 500 15px/1.2em "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  text-transform: uppercase;
  padding: 0 20px;
}
.item-navigation:before,
.item-navigation:after {
  content: " ";
  display: table;
}
.item-navigation:after {
  clear: both;
}
@media only screen and (min-width: 1024px) {
  .item-navigation {
    padding: 0;
  }
}
.item-navigation--right-top {
  margin: 37px 0 0;
  text-align: right;
}
.item-navigation--right-top .item-navigation__container,
.item-navigation--right-top .item-navigation__back-to-overview {
  float: right;
  clear: both;
}
.item-navigation--right-top .item-navigation__back-to-overview {
  margin: 0 0 13px;
}
.item-navigation--aside {
  margin: 22px 0 3px;
}
.item-navigation--aside .item-navigation__container {
  display: inline-block;
  float: right;
  margin: 0 0 13px;
}
.item-navigation--aside .item-navigation__back-to-overview {
  float: left;
  margin: 0 0 13px;
}
@media only screen and (min-width: 1024px) {
  .item-navigation--aside {
    margin: -47px 0 20px;
    text-align: center;
  }
  .item-navigation--aside .item-navigation__container {
    float: none;
  }
  .item-navigation--aside .item-navigation__back-to-overview {
    float: none;
  }
}
.item-navigation--full {
  position: relative;
  display: block;
  padding: 0 20px 18px;
  border-bottom: 1px solid rgba(51, 51, 51, 0.12);
}
.item-navigation--full .item-navigation__container,
.item-navigation--full .item-navigation__back-to-overview {
  margin: 0 0 13px;
}
.item-navigation--full:before {
  position: absolute;
  content: '';
  width: 1280px;
  height: 1px;
  bottom: -1px;
  right: 100%;
  background: rgba(51, 51, 51, 0.12);
}
@media only screen and (min-width: 1024px) {
  .item-navigation--full {
    padding: 0 0 31px;
  }
}
.item-navigation--news {
  display: none;
  padding: 77px 20px 18px;
}
.item-navigation--news:last-child {
  border-bottom: none;
}
.item-navigation--news:last-child:before {
  display: none;
}
@media only screen and (min-width: 1024px) {
  .item-navigation--news {
    display: block;
    padding: 37px 0 23px;
  }
}
.item-navigation__container {
  /*
	 * For modern browsers
	 * 1. The space content is one way to avoid an Opera bug when the
	 *    contenteditable attribute is included anywhere else in the document.
	 *    Otherwise it causes space to appear at the top and bottom of elements
	 *    that are clearfixed.
	 * 2. The use of `table` rather than `block` is only necessary if using
	 *    `:before` to contain the top-margins of child elements.
	 */
  *zoom: 1;
  float: right;
}
.item-navigation__container:before,
.item-navigation__container:after {
  content: " ";
  display: table;
}
.item-navigation__container:after {
  clear: both;
}
.item-navigation__back-to-overview {
  display: inline-block;
  position: relative;
  text-decoration: none;
  padding: 0 0 0 18px;
}
.item-navigation__back-to-overview:before {
  position: absolute;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  font-family: 'Icons';
  line-height: 1;
  text-transform: none;
  speak: none;
  content: '\e90d';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: greyscale;
  font-size: 9px;
  top: 4px;
  left: -2px;
}
.item-navigation__text {
  float: left;
  display: block;
}
.item-navigation__next,
.item-navigation__previous {
  position: relative;
  float: left;
  display: block;
  height: 17px;
  width: 20px;
  overflow: hidden;
  text-decoration: none;
  text-indent: 9999px;
}
.item-navigation__next:after,
.item-navigation__previous:after {
  position: absolute;
  top: 4px;
  font-size: 9px;
  text-indent: 0;
}
.item-navigation__next {
  margin: 0 -8px 0 0;
}
.item-navigation__next:after {
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  font-family: 'Icons';
  line-height: 1;
  text-transform: none;
  speak: none;
  content: '\e90b';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: greyscale;
  left: 4px;
}
.item-navigation__previous:after {
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  font-family: 'Icons';
  line-height: 1;
  text-transform: none;
  speak: none;
  content: '\e90d';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: greyscale;
  left: 7px;
}
