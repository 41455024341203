﻿/*------------------------------------*\
	#Form controls
\*------------------------------------*/

@import (reference) "../default.less";

input[type=text],
input[type=password],
input[type=email],
input[type=tel],
textarea,
select {
	font: 16px/36px @font-text;
	padding: 6px 16px 6px;
	-webkit-appearance: none;
	outline: none;
	color: @color-text;
	height: 50px;
	border: 1px solid fade(@color-text, 12%);
	background: @color-white;

	&.ivld {
		background: mix(red, #fff, 20%);
		border-color: mix(red, #fff, 40%);
	}

	&.required {
		//border-left: 2px solid mix(red, #fff, 40%);
	}
}

input[type=file] {
	
	&.required {
		border-left: 2px solid mix(red, #fff, 40%);
	}
}

textarea {
	resize: vertical;
	overflow: auto;
	height: auto;
	min-height: 150px;
}

label {
	font: 300 17px/30px @font-text;
	padding: 10px 10px 10px 0;
}

select {
	-moz-appearance: none;
	border-radius: 0;
	padding: 8px 16px 9px;
}

select::-ms-expand {
	display: none;
}

.form-check {
	display: none;
}

.select-wrapper {
	position: relative;
	display: block;

	select {
		width: 100%;
	}

	&:after {
		position: absolute;
		.icon(@icon-caret-thin-down);
		font-size: 17px; 
		width:  50px;
		height: 50px;
		padding: 16px 0 0;
		border-left: 1px solid fade(@color-text, 12%);
		top:  0;
		right: 0;
		text-align: center;
		pointer-events: none;
		color: fade(@color-text, 40%);
	}
}

// Placeholder
input,
textarea {
	.placeholder({
		color: fade(@color-text, 12%);
		font: 16px/36px @font-text;
	});
}

.empty {
	display: none;
	min-height: 50px;

	@media @laptop {
		display: block;
	}
}

.form__control {
	overflow: hidden;
	margin: 0 0 20px;
}