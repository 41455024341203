﻿/*------------------------------------*\
	#Call to action
\*------------------------------------*/

@import (reference) "../default.less";

.call-to-action {
	.clearfix;
	min-height: 302px;
	margin: 8px 0 22px;
	
	background: @color-primary url(../images/red-watermark.png) no-repeat center;
	background-size: cover;

	@media @tablet {
		background-size: auto;
	}

	@media @laptop {
		display: flex;
		align-items: center;
	}
}

.call-to-action__title {
	float: left;
	width: 100%;
	padding: 20px 20px;
	margin: 0;
	font: 100 24px/36px @font-text;
	letter-spacing: 0.02em;
	color: @color-white;

	strong {
		font-weight: 500;
	}

	p {
		margin: 0;
	}

	@media @mobile-large {
		font-size: 36px;
		line-height: 42px;
	}

	@media @tablet-small {
		font-size:   45px;
		line-height: 48px;
	}

	@media @tablet {
		font-size:   50px;
		line-height: 53px;
		padding: 20px 40px;
	}

	@media @laptop {
		width: 55%;
	}
}

.call-to-action__button-container {
	float: left;
	width: 100%;
	padding: 4px 20px 20px;

	@media @tablet-small {
		padding: 4px 40px 20px;
	}

	@media @laptop {
		padding: 4px 40px 0;
		width: 45%;
		text-align: center;
	}
}