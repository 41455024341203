﻿/*------------------------------------*\
	#Responsive nav
\*------------------------------------*/

@import (reference) "../default.less";

.responsive-nav {
	position: fixed;
	z-index: 10;
	width:  320px;
	height: 100%;
	padding: 40px 0;
	top:   0;
	right: 0;
	transition: right 0.3s ease-in-out;
	background: @color-primary;
	overflow-x: hidden;
	overflow-y: visible;
	box-shadow: -5px 0px 5px 0px rgba(0,0,0,0.25);

	&.collapsed {
		right: -325px;
	}

	@media @laptop {
		position: static;
		width: auto;
		padding: 0;
		opacity: 1;
		background: none;
		overflow: visible;
		transition: none;
		box-shadow: none;

		&.collapsed {
			left: 0;
			transition: none;
		}
	}
}