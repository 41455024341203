/*------------------------------------*\
	#Product overview
\*------------------------------------*/
.product-overview {
  /*
	 * For modern browsers
	 * 1. The space content is one way to avoid an Opera bug when the
	 *    contenteditable attribute is included anywhere else in the document.
	 *    Otherwise it causes space to appear at the top and bottom of elements
	 *    that are clearfixed.
	 * 2. The use of `table` rather than `block` is only necessary if using
	 *    `:before` to contain the top-margins of child elements.
	 */
  *zoom: 1;
  margin: 72px 0 -40px;
}
.product-overview:before,
.product-overview:after {
  content: " ";
  display: table;
}
.product-overview:after {
  clear: both;
}
.product-overview:first-child {
  margin-top: 0;
}
.product-overview:last-child {
  margin-bottom: -18px;
}
.product-overview--no-title:first-child {
  margin-top: 8px;
}
.product-overview--no-title:last-child {
  margin-bottom: -18px;
}
.product-overview__title {
  margin: 0 0 31px;
}
