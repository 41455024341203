﻿/*------------------------------------*\
	#Contact box.
\*------------------------------------*/

@import (reference) "../default.less";

.contact-box {
	position: fixed;
	right: -260px;
	z-index: 10;
	width: 320px;
	height: 130px;
	bottom: 0;
	transition: right 250ms ease;
	/*&.open {
		right: 0;
	}*/
	@media @tablet-small {
		margin-top: -65px;
		top: 50%;
		bottom: auto;
	}
}

.contact-box__telephone,
.contact-box__pointer {
	position: relative;
	box-sizing: border-box;
	font: 500 18px/30px @font-text;
	height: 61px;
	padding: 15px 20px 15px 30px;
	margin-left: 61px;
	transition: right 250ms ease;
	right: 0;

	&:before {
		font-size: 26px;
		position: absolute;
		left: -40px;
		z-index: 1;
		cursor: default;
	}

	&:after {
		position: absolute;
		top: 0;
		left: -61px;
		content: '';
		width:  61px;
		height: 61px;
		font-size: 36px;
	}

	a {
		.text-decoration-hover();

		&:hover {
			color: inherit;
		}
	}

	&:hover,
	&.open {
		right: 260px;
	}
}

.contact-box__telephone {
	background: @color-primary;
	color: @color-white;

	&:before {
		.icon(@icon-phone);
		background: @color-primary;
		margin-top: 2px;
		left: -44px;
	}

	&:after {
		background: @color-primary;
	}
}

.contact-box__pointer {
	background: @color-background;
	color: fade(@color-black, 40%);

	&:before {
		.icon(@icon-pointer);
		left: -44px;
		background: @color-background;
	}

	&:after {
		background: @color-background;
	}
}