﻿/*------------------------------------*\
	#Dealers
\*------------------------------------*/

@import (reference) "../default.less";

.dealers {
	display: none;
	padding: 19px 40px 44px;
	width: 100%;

	@media @laptop {
		float: left;
		width: 342px;
		height: 1008px;
		overflow-y: scroll;
	}
}

.dealers__wrapper {
	.clearfix;
}

.dealer-maps {
	width: 100%;
	height: 100px;

	@media @laptop {
		float: left;
		//width: calc(~'100% - 342px');
	}
}

.dealer {
	margin: 0 0 28px;
	cursor: pointer;
}

.dealer__name,
.dealer__city {
	font: 300 17px/28px @font-text;
	padding-left: 20px;
}

.dealer__name {
	position: relative;

	a {
		text-decoration: none;
	}

	&:before {
		position: absolute;
		content: "";
		border: 5px solid #333;
		border-radius: 50%;
		border-top-color: @color-agricultural;
		border-right-color: @color-agricultural;
		border-bottom-color: @color-horticulture;
		border-left-color: @color-horticulture;
		top:  10px;
		left: 0;
	}
}

.dealer__name--agrarisch {

	&:before {
		border-color: @color-agricultural;
	}
}

.dealer__name--tuinbouw {

	&:before {
		border-color: @color-horticulture;
	}
}