/*------------------------------------*\
	#Dealers
\*------------------------------------*/
.dealers {
  display: none;
  padding: 19px 40px 44px;
  width: 100%;
}
@media only screen and (min-width: 1024px) {
  .dealers {
    float: left;
    width: 342px;
    height: 1008px;
    overflow-y: scroll;
  }
}
.dealers__wrapper {
  /*
	 * For modern browsers
	 * 1. The space content is one way to avoid an Opera bug when the
	 *    contenteditable attribute is included anywhere else in the document.
	 *    Otherwise it causes space to appear at the top and bottom of elements
	 *    that are clearfixed.
	 * 2. The use of `table` rather than `block` is only necessary if using
	 *    `:before` to contain the top-margins of child elements.
	 */
  *zoom: 1;
}
.dealers__wrapper:before,
.dealers__wrapper:after {
  content: " ";
  display: table;
}
.dealers__wrapper:after {
  clear: both;
}
.dealer-maps {
  width: 100%;
  height: 100px;
}
@media only screen and (min-width: 1024px) {
  .dealer-maps {
    float: left;
  }
}
.dealer {
  margin: 0 0 28px;
  cursor: pointer;
}
.dealer__name,
.dealer__city {
  font: 300 17px/28px "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  padding-left: 20px;
}
.dealer__name {
  position: relative;
}
.dealer__name a {
  text-decoration: none;
}
.dealer__name:before {
  position: absolute;
  content: "";
  border: 5px solid #333;
  border-radius: 50%;
  border-top-color: #ffb400;
  border-right-color: #ffb400;
  border-bottom-color: #77b039;
  border-left-color: #77b039;
  top: 10px;
  left: 0;
}
.dealer__name--agrarisch:before {
  border-color: #ffb400;
}
.dealer__name--tuinbouw:before {
  border-color: #77b039;
}
