/*------------------------------------*\
	#Product
\*------------------------------------*/
.product {
  /*
	 * For modern browsers
	 * 1. The space content is one way to avoid an Opera bug when the
	 *    contenteditable attribute is included anywhere else in the document.
	 *    Otherwise it causes space to appear at the top and bottom of elements
	 *    that are clearfixed.
	 * 2. The use of `table` rather than `block` is only necessary if using
	 *    `:before` to contain the top-margins of child elements.
	 */
  *zoom: 1;
  margin: 0 0 40px;
}
.product:before,
.product:after {
  content: " ";
  display: table;
}
.product:after {
  clear: both;
}
.product__container {
  /*
	 * For modern browsers
	 * 1. The space content is one way to avoid an Opera bug when the
	 *    contenteditable attribute is included anywhere else in the document.
	 *    Otherwise it causes space to appear at the top and bottom of elements
	 *    that are clearfixed.
	 * 2. The use of `table` rather than `block` is only necessary if using
	 *    `:before` to contain the top-margins of child elements.
	 */
  *zoom: 1;
  display: flex;
  flex-wrap: wrap;
  background: #faf7f2;
  transition: background 0.2s ease-in-out;
}
.product__container:before,
.product__container:after {
  content: " ";
  display: table;
}
.product__container:after {
  clear: both;
}
section:first-child .product__container {
  background: #f3ece0;
}
section:first-child .product__container:hover {
  background: #eeebe3;
}
.product__container:hover {
  background: #eeebe3;
}
.product__container:hover a {
  color: inherit;
}
.product__container a {
  display: block;
  width: 100%;
  height: 100%;
}
.product__image-container {
  float: left;
  width: 100%;
  border: 1px solid rgba(51, 51, 51, 0.12);
  background: #fff;
}
@media only screen and (min-width: 1280px) {
  .product__image-container {
    width: 50%;
  }
}
.product__placeholder {
  position: relative;
  overflow: hidden;
  height: 0;
  padding: 0 0 100%;
  background: url(../images/placeholder.jpg) no-repeat center;
  background-image: url(../images/placeholder.svg), none;
  background-size: cover;
}
.product__text-container {
  float: left;
  width: 100%;
  padding: 36px 20px 112px;
}
.product__text-container p {
  letter-spacing: 0.02em;
  margin: 0;
}
@media only screen and (min-width: 425px) {
  .product__text-container {
    padding: 36px 40px 112px;
  }
}
@media only screen and (min-width: 1280px) {
  .product__text-container {
    width: 50%;
  }
}
.product__text-content {
  position: relative;
  height: 100%;
}
.product__title {
  font: 300 27px/28px "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  letter-spacing: 0.02em;
  margin: 0 0 32px;
}
.product__button {
  position: absolute;
  bottom: -22px;
  transform: translateY(100%);
}
