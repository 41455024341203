﻿/*------------------------------------*\
	#Happening
\*------------------------------------*/

@import (reference) "../default.less";

.happenings {
	margin: 0 0 -18px 0px;
}

.happening  {
	display: block;
	text-decoration: none;
	border-bottom: 10px solid @color-background-dark;
	margin: 9px 0 40px;
	background: @color-background url(../images/grey-watermark.png) no-repeat center right -140px;
	background-size: 566px 566px;
	color: @color-text;
	transition: background 0.2s ease-in-out;

	&.agrarisch {
		border-bottom-color: @color-agricultural;
	}

	&.tuinbouw {
		border-bottom-color: @color-horticulture;
	}

	&:hover {
		background-color: darken(@color-background, 10%);

		.happening__title:after {
			left: 13px;
		}

		.happening__date {
			color: @color-text;
		}
	}
}

.happening--frontpage {
	margin: 8px 0 0;

	&:hover {
		background-color: @color-background;

		.happening__title:after {
			left: 10px;
		}
	}

	@media @tablet-small {
	
		.columns  {
			display: flex;
			align-items: center;
		}
	}
}

.happening__container {
	padding: 40px 39px 25px;

	.frontpage & {
		padding: 140px 20px 25px;

		@media @tablet-small {
			padding: 32px 40px 32px 140px;
		}
	}
}

.happening__container--frontpage {
	position: relative;
	display: block;
	text-decoration: none;
	padding-left: 140px;

	&:before {
		position: absolute;
		content: '';
		width:  60px;
		height: 63px;
		margin: 0 0 0 -20px;
		top:  44px;
		left: 50%;
		background: url(../images/calander.png) center no-repeat;
		background-image: url(../images/calander.svg), none;

		@media @tablet-small {
			left: 40px;
			margin: 0 0 0 0;
		}
	}

	.happening--frontpage & {

		&:hover {
			
			.happening__title:after {
				left: 13px;
			}
		}
	}
}

.happening__date {
	display: block;
	font: 100 28px/44px @font-text;
	letter-spacing: 0.02em; 
	margin: 0 0 -3px;
	color: @color-text;
}

.happening-date {
	display: block;
	font: 100 27px/39px @font-text;
	letter-spacing: 0.02em; 
	margin: -26px 0 26px;
}

.happening__title {
	font: 100 28px/38px @font-text;
	letter-spacing: 0; 
	text-transform: uppercase;
	margin: 0;

	&:after {
		position: relative;
		.icon(@icon-caret-thin-right);
		font-size: 17px;
		top:  -6px;
		left: 10px;
		transition: left 0.2s ease-in-out;
	}

	@media @mobile-large {
		font-size: 38px;
		line-height: 44px;
	}
}

.happening__button-container {
	padding-top:    20px;
	padding-bottom: 20px;
	text-align: center;
}