/*------------------------------------*\
	#HollandHeater
\*------------------------------------*/
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  padding: 0;
  border: none;
  outline: none;
  margin: 0;
}
img {
  vertical-align: bottom;
}
html {
  overflow-y: scroll;
}
body {
  line-height: 1;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
nav ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
a {
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  padding: 0;
  margin: 0;
}
ins {
  color: #000;
  background-color: #ff9;
  text-decoration: none;
}
mark {
  color: #000;
  background-color: #ff9;
  font-style: italic;
  font-weight: bold;
}
del {
  text-decoration: line-through;
}
abbr[title],
acronym[title],
dfn[title] {
  cursor: help;
  border-bottom: 1px dotted;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
hr {
  display: block;
  height: 0;
  padding: 0;
  margin: 1em 0;
  border: none;
  border-top: 1px solid #ccc;
}
input,
select {
  vertical-align: middle;
}
*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
/*------------------------------------*\
	#Fonts
\*------------------------------------*/
@font-face {
  font-family: 'Helvetica Neue';
  src: url('webfonts/m Th.eot');
  src: url('webfonts/m Th.eot?#iefix') format('embedded-opentype'), url('webfonts/m Th.woff2') format('woff2'), url('webfonts/m Th.woff') format('woff'), url('webfonts/m Th.svg') format('svg');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: 'Helvetica Neue';
  src: url('webfonts/m Lt.eot');
  src: url('webfonts/m Lt.eot?#iefix') format('embedded-opentype'), url('webfonts/m Lt.woff2') format('woff2'), url('webfonts/m Lt.woff') format('woff'), url('webfonts/m Lt.svg') format('svg');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Helvetica Neue';
  src: url('webfonts/m Roman.eot');
  src: url('webfonts/m Roman.eot?#iefix') format('embedded-opentype'), url('webfonts/m Roman.woff2') format('woff2'), url('webfonts/m Roman.woff') format('woff'), url('webfonts/m Roman.svg') format('svg');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Helvetica Neue';
  src: url('webfonts/m Md.eot');
  src: url('webfonts/m Md.eot?#iefix') format('embedded-opentype'), url('webfonts/m Md.woff2') format('woff2'), url('webfonts/m Md.woff') format('woff'), url('webfonts/m Md.svg') format('svg');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Helvetica Neue';
  src: url('webfonts/m Bd.eot');
  src: url('webfonts/m Bd.eot?#iefix') format('embedded-opentype'), url('webfonts/m Bd.woff2') format('woff2'), url('webfonts/m Bd.woff') format('woff'), url('webfonts/m Bd.svg') format('svg');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Helvetica Neue Cyr';
  src: url('webfonts/HelveticaNeueCyr-Thin.eot');
  src: url('webfonts/HelveticaNeueCyr-Thin.eot?#iefix') format('embedded-opentype'), url('webfonts/HelveticaNeueCyr-Thin.woff2') format('woff2'), url('webfonts/HelveticaNeueCyr-Thin.woff') format('woff'), url('webfonts/HelveticaNeueCyr-Thin.svg') format('svg');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: 'Helvetica Neue Cyr';
  src: url('webfonts/HelveticaNeueCyr-Light.eot');
  src: url('webfonts/HelveticaNeueCyr-Light.eot?#iefix') format('embedded-opentype'), url('webfonts/HelveticaNeueCyr-Light.woff2') format('woff2'), url('webfonts/HelveticaNeueCyr-Light.woff') format('woff'), url('webfonts/HelveticaNeueCyr-Light.svg') format('svg');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Helvetica Neue Cyr';
  src: url('webfonts/HelveticaNeueCyr-Roman.eot');
  src: url('webfonts/HelveticaNeueCyr-Roman.eot?#iefix') format('embedded-opentype'), url('webfonts/HelveticaNeueCyr-Roman.woff2') format('woff2'), url('webfonts/HelveticaNeueCyr-Roman.woff') format('woff'), url('webfonts/HelveticaNeueCyr-Roman.svg') format('svg');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Helvetica Neue Cyr';
  src: url('webfonts/HelveticaNeueCyr-Medium.eot');
  src: url('webfonts/HelveticaNeueCyr-Medium.eot?#iefix') format('embedded-opentype'), url('webfonts/HelveticaNeueCyr-Medium.woff2') format('woff2'), url('webfonts/HelveticaNeueCyr-Medium.woff') format('woff'), url('webfonts/HelveticaNeueCyr-Medium.svg') format('svg');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Helvetica Neue Cyr';
  src: url('webfonts/HelveticaNeueCyr-Bold.eot');
  src: url('webfonts/HelveticaNeueCyr-Bold.eot?#iefix') format('embedded-opentype'), url('webfonts/HelveticaNeueCyr-Bold.woff2') format('woff2'), url('webfonts/HelveticaNeueCyr-Bold.woff') format('woff'), url('webfonts/HelveticaNeueCyr-Bold.svg') format('svg');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Icons';
  src: url('webfonts/icons.eot?-a707vd');
  src: url('webfonts/icons.eot?#iefix-a707vd') format('embedded-opentype'), url('webfonts/icons.woff?-a707vd') format('woff'), url('webfonts/icons.ttf?-a707vd') format('truetype'), url('webfonts/icons.svg?-a707vd#icons') format('svg');
  font-weight: normal;
  font-style: normal;
}
/*
 * Fonts
 */
/*
 * Colors
 */
html[lang|=ru-RU]  * {
  font-family: 'Helvetica Neue Cyr', "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif !important;
}
/*
 * Default styles
 */
body {
  font: 300 17px/28px "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  color: #333333;
  min-width: 320px;
  background: #fff;
}
@media only screen and (min-width: 1024px) {
  body {
    padding: 120px 0 0;
  }
}
#wrapper {
  overflow: hidden;
}
#content {
  position: relative;
  z-index: 1;
}
h1 {
  font: 100 36px/1.2em "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  color: #fff;
  letter-spacing: 0.02em;
  padding: 0 20px;
}
@media only screen and (min-width: 640px) {
  h1 {
    font-size: 45px;
    line-height: 1.2em;
  }
}
@media only screen and (min-width: 768px) {
  h1 {
    font-size: 50px;
    line-height: 1.2em;
  }
}
h2 {
  font: 100 32px/1.2em "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  color: #333333;
  letter-spacing: 0.02em;
  margin: 0 0 27px;
}
h2.center {
  text-align: center;
}
@media only screen and (min-width: 425px) {
  h2 {
    font-size: 38px;
  }
}
.sub-title {
  font: 100 28px/28px "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  letter-spacing: 0.02em;
}
p,
ul,
ol {
  margin: 0 0 24px;
}
ul,
ol {
  padding: 0 0 0 18px;
}
a {
  color: inherit;
  transition: color 0.3s ease-in-out;
}
a:hover {
  color: #ec1d25;
}
a[href^=tel],
a.telephone {
  text-decoration: none;
}
img {
  width: 100%;
  height: auto;
}
sup {
  font-size: 9px;
  position: relative;
  top: -5px;
  left: -3px;
  line-height: 10px;
}
#content {
  position: relative;
  z-index: 1;
}
.jscroll-added,
.jscroll-inner {
  width: 100%;
  float: left;
}
.hidden {
  display: none;
}
.table-wrapper {
  overflow-x: auto;
}
.table-wrapper--scroll-more {
  position: relative;
  padding: 40px 0 0 0;
}
.table-wrapper--scroll-more:before {
  position: absolute;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  font-family: 'Icons';
  line-height: 1;
  text-transform: none;
  speak: none;
  content: '\e924';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: greyscale;
  font-size: 28px;
  top: 0;
  left: 20px;
  animation: slide 2.8s linear infinite;
}
@-webkit-keyframes slide {
  0% {
    left: 20px;
  }
  50% {
    left: 40px;
  }
  100% {
    left: 20px;
  }
}
@keyframes slide {
  0% {
    left: 20px;
  }
  50% {
    left: 40px;
  }
  100% {
    left: 20px;
  }
}
