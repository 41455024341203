﻿/*------------------------------------*\
	#Toastr
\*------------------------------------*/

@import (reference) "../default.less";

.toast {
	border-bottom: 5px solid #766a62;
	background: url(../images/grey-watermark.png)  @color-background no-repeat right -147px center;
	background-size: 300px auto;

	.date {
		color: @color-black;
		font: bold 16px/26px @font-text;
	}

	.title {
		position: relative;
		color: @color-black;
		font: 300 16px/26px @font-text;
		width: auto;

		&:after {
			position: relative;
			.icon(@icon-caret-right);
			top:   -2px;
			right:  -4px;
			font-size: 9px;
			line-height: 1em;
			margin-top: -6px;
			transition: right 0.3s ease-in-out; 
		}
	}

	.toast-progress {
		background-color: #000;
		opacity: 0.2;
	}

	.toast-close-button {
		color: @color-black;
		opacity: 1;
		top: -10px;

		&:after {
			display: none;
		}

		&:hover {
			background: none;
			color: @color-primary;
		}
	}

	&:hover {
		background-color: @color-background-dark;

		.title:after {
			right:  -6px;
		}
	}
}

#toast-container  {

	> div {
		border-bottom: 5px solid @color-background-dark;
		padding: 21px 15px 22px 15px;
		opacity: 1;
		box-shadow: none;
		background-position: right -147px center;
	}
}