﻿/*------------------------------------*\
	#Sitemap
\*------------------------------------*/

@import (reference) "../default.less";

.sitemap {
	
	> li {
		//margin: 0 0 24px;
		float: none;
		padding: 0;

		&.agrarisch,
		&.tuinbouw {
			display: none;
		}

		&.dealer {
			height: auto;
			overflow-y: auto;
		}
	}

	ul {
		margin: 0;
	}

	.vacancies-count {
		display: none;
	}
}