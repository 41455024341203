﻿/*------------------------------------*\
	#Hero
\*------------------------------------*/

@import (reference) "../default.less";

.hero {
	min-height: 253px;
	padding: 70px 20px 0;
	margin: 100px 0 0;
	text-align: center;

	background-color: @color-primary;
	background-position: center bottom;
	background-size: cover; 

	@media @laptop {
		min-height: unset;
		height: 444px;
		display: flex;
		padding: 61px 20px 0;
		margin: 0;
		justify-content: center;
	}
}

.hero--small {
	min-height: 183px;
	padding: 70px 0 0;
	align-items: center;

	@media @laptop {
		min-height: unset;
		padding: 0 0 0;
		height: 183px;
	}
}