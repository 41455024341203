/*------------------------------------*\
	#Sitemap
\*------------------------------------*/
.sitemap > li {
  float: none;
  padding: 0;
}
.sitemap > li.agrarisch,
.sitemap > li.tuinbouw {
  display: none;
}
.sitemap > li.dealer {
  height: auto;
  overflow-y: auto;
}
.sitemap ul {
  margin: 0;
}
.sitemap .vacancies-count {
  display: none;
}
