/*------------------------------------*\
	#Navigation
\*------------------------------------*/
.navigation {
  padding: 0;
  overflow-x: hidden;
  overflow-y: auto;
}
.navigation ul {
  display: inline-block;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
}
.navigation ul li a {
  position: relative;
  display: block;
  font: 500 15px/1.2em "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  color: #fff;
  text-decoration: none;
  padding: 13px 23px 12px;
  text-transform: uppercase;
}
.navigation ul li a span {
  display: inline-block;
  position: relative;
}
.navigation ul li a span:after {
  position: absolute;
  content: '';
  width: 100%;
  height: 2px;
  bottom: -2px;
  left: 0;
  background: #fff;
  transform: scaleX(0);
  transition: transform 0.2s ease-in-out;
}
.navigation ul li:hover > a span:after,
.navigation ul li.active > a span:after {
  transform: scaleX(1);
}
.navigation ul li.home ul {
  display: none;
}
.navigation ul ul li a {
  padding: 13px 23px 12px 43px;
}
@media only screen and (min-width: 1024px) {
  .navigation {
    /*
	 * For modern browsers
	 * 1. The space content is one way to avoid an Opera bug when the
	 *    contenteditable attribute is included anywhere else in the document.
	 *    Otherwise it causes space to appear at the top and bottom of elements
	 *    that are clearfixed.
	 * 2. The use of `table` rather than `block` is only necessary if using
	 *    `:before` to contain the top-margins of child elements.
	 */
    *zoom: 1;
    position: relative;
    display: flex;
    height: 70px;
    padding: 0  0 0 285px;
    margin: 0;
    overflow: visible;
    align-items: center;
    justify-content: flex-end;
  }
  .navigation:before,
  .navigation:after {
    content: " ";
    display: table;
  }
  .navigation:after {
    clear: both;
  }
  .navigation > ul {
    background: none;
    width: auto;
    height: auto;
    padding: 0 42px 0 0;
    margin: 0;
  }
  .navigation > ul > li {
    float: left;
    position: relative;
  }
  .navigation > ul > li a {
    white-space: nowrap;
    transition: none;
    color: #fff;
  }
  .navigation > ul > li.home {
    width: 62px;
    height: 20px;
  }
  .navigation > ul > li.home a {
    position: relative;
    text-indent: 9999px;
    overflow: hidden;
    transition: color 0.2s ease-in-out;
  }
  .navigation > ul > li.home a:after {
    position: absolute;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    font-family: 'Icons';
    line-height: 1;
    text-transform: none;
    speak: none;
    content: '\e91a';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: greyscale;
    font-size: 20px;
    margin: -10px 0 0 -10px;
    top: 50%;
    left: 50%;
    text-indent: 0;
  }
  .navigation > ul > li.home:hover a {
    color: #eeebe3 !important;
  }
  .navigation > ul > li.active:hover > a {
    color: #fff;
  }
  .navigation > ul > li ul {
    display: none;
  }
  .headroom--not-top .navigation {
    height: 77px;
  }
}
