/*------------------------------------*\
	#Vacancies count
\*------------------------------------*/
.vacancies-count__wrapper {
  position: relative;
}
.vacancies-count {
  position: absolute;
  padding: 2px 5px;
  border-radius: 50%;
  top: -8px;
  right: 2px;
  font: 700 11px/12px "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  background: #db191f;
  color: #fff;
}
@media only screen and (min-width: 1024px) {
  .vacancies-count {
    background: #ec1d25;
  }
}
.side-nav .vacancies-count {
  position: relative;
  right: auto;
  left: 2px;
}
