/*------------------------------------*\
	#project-intro 
\*------------------------------------*/
.project-intro {
  /*
	 * For modern browsers
	 * 1. The space content is one way to avoid an Opera bug when the
	 *    contenteditable attribute is included anywhere else in the document.
	 *    Otherwise it causes space to appear at the top and bottom of elements
	 *    that are clearfixed.
	 * 2. The use of `table` rather than `block` is only necessary if using
	 *    `:before` to contain the top-margins of child elements.
	 */
  *zoom: 1;
  display: flex;
  flex-direction: column;
}
.project-intro:before,
.project-intro:after {
  content: " ";
  display: table;
}
.project-intro:after {
  clear: both;
}
@media only screen and (min-width: 1024px) {
  .project-intro {
    display: block;
    flex-direction: row;
  }
}
