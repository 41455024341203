﻿/*------------------------------------*\
	#Product details
\*------------------------------------*/

@import (reference) "../default.less";

.product-details {
	position: relative;
	display: inline-block;
	padding: 15px 30px 18px 0;
	font: 500 15px/1.2em @font-text;
	text-transform: uppercase;
	text-decoration: none;

	&:after {
		position: absolute;
		.icon(@icon-caret-down);
		font-size: 9px;
		top:   20px;
		right: 8px;
	}
}