﻿/*------------------------------------*\
	#Aside
\*------------------------------------*/

@import (reference) "../default.less";

aside {
	order: 1;
	width: 100%;
	margin: -9px 0 0;
	padding: 0 20px 0;

	@media @laptop {
		position: relative;
		float: right;
		width: 342px;
		padding: 84px 0 0 40px;
		margin: 0;

		+ .content {
			float: left;
			width: calc(~"100% - 342px");
			.clearfix;

			+ * {
				clear: both;
			}
		}

		&:before {
			position: absolute;
			content: '';
			width:  100%;
			height: 40px;
			top: -40px;
			left: 0;
			background: @color-white;
		}
	}
}