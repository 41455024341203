/*------------------------------------*\
	#Tabs
\*------------------------------------*/
.tabs {
  margin: 0 0 31px;
}
.tabs__control {
  /*
	 * For modern browsers
	 * 1. The space content is one way to avoid an Opera bug when the
	 *    contenteditable attribute is included anywhere else in the document.
	 *    Otherwise it causes space to appear at the top and bottom of elements
	 *    that are clearfixed.
	 * 2. The use of `table` rather than `block` is only necessary if using
	 *    `:before` to contain the top-margins of child elements.
	 */
  *zoom: 1;
  padding: 0;
  margin: 0;
  list-style: none;
}
.tabs__control:before,
.tabs__control:after {
  content: " ";
  display: table;
}
.tabs__control:after {
  clear: both;
}
.tabs__control-item {
  position: relative;
  float: left;
  padding: 17px 40px 16px 18px;
  font: 500 15px/1.2em "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.02em;
  cursor: pointer;
}
.tabs__control-item:after {
  position: absolute;
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 20px 51px 0;
  border-color: transparent #fff transparent transparent;
  top: 0;
  right: 0;
}
.tabs__control-item:hover {
  background: #fdfcf9;
}
.tabs__control-item--active {
  background: #faf7f2;
}
.tabs__control-item--active:hover {
  background: #faf7f2;
}
.tabs__content {
  display: none;
  padding: 34px 20px 8px;
  background: #faf7f2;
}
.tabs__content ul {
  /*
	 * For modern browsers
	 * 1. The space content is one way to avoid an Opera bug when the
	 *    contenteditable attribute is included anywhere else in the document.
	 *    Otherwise it causes space to appear at the top and bottom of elements
	 *    that are clearfixed.
	 * 2. The use of `table` rather than `block` is only necessary if using
	 *    `:before` to contain the top-margins of child elements.
	 */
  *zoom: 1;
  padding: 8px 0 0;
  margin: 0 -20px 32px;
  list-style: none;
}
.tabs__content ul:before,
.tabs__content ul:after {
  content: " ";
  display: table;
}
.tabs__content ul:after {
  clear: both;
}
.tabs__content ul li {
  float: left;
  width: 100%;
  padding: 0 20px;
}
.tabs__content ul li span {
  position: relative;
  display: block;
  padding: 11px 20px 10px 44px;
  border-bottom: 1px solid rgba(51, 51, 51, 0.12);
}
.tabs__content ul li span a {
  display: block;
  padding: 11px 20px 10px 44px;
  margin: -12px -20px -9px -44px;
  text-decoration: none;
}
.tabs__content ul li span:before {
  position: absolute;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  font-family: 'Icons';
  line-height: 1;
  text-transform: none;
  speak: none;
  content: '\e90b';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: greyscale;
  font-size: 9px;
  top: 21px;
  left: 18px;
}
.tabs__content ul li:first-child span {
  border-top: 1px solid rgba(51, 51, 51, 0.12);
}
.tabs__content ul li ul {
  margin: 0 0 -11px;
}
.tabs__content ul li ul li {
  width: 100%;
}
.tabs__content ul li ul li:first-child + li span {
  border-top: 0;
}
.tabs__content ul li ul li:last-child span {
  border-bottom: 0;
}
@media only screen and (min-width: 768px) {
  .tabs__content ul li {
    width: 50%;
  }
  .tabs__content ul li:first-child + li span {
    border-top: 1px solid rgba(51, 51, 51, 0.12);
  }
}
.tabs__content table {
  margin: 0 0 32px;
}
@media only screen and (min-width: 425px) {
  .tabs__content {
    padding: 34px 40px 8px;
  }
}
.tabs__content--active {
  display: block;
}
.tab__images {
  margin-bottom: -8px;
}
.tab__image {
  margin-bottom: 40px;
}
.tab__video {
  position: relative;
  display: block;
}
.tab__video:before {
  position: absolute;
  content: '';
  width: 70px;
  height: 49px;
  margin: -25px 0 0 -35px;
  top: 50%;
  left: 50%;
  background: url(../images/footer-video-play.png);
  background-image: url(../images/footer-video-play.svg), none;
}
