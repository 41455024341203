﻿/*------------------------------------*\
	#HollandHeater
\*------------------------------------*/

@import "reset.less";
@import "fonts.less";
@import "mixins.less";
@import "variables.less";

@mobile-large: ~"only screen and (min-width: 425px)";
@tablet-small: ~"only screen and (min-width: 640px)";
@tablet: ~"only screen and (min-width: 768px)";
@laptop: ~"only screen and (min-width: 1024px)";
@laptop-med: ~"only screen and (min-width: 1280px)";
@laptop-large: ~"only screen and (min-width: 1440px)";
@desktop-small: ~"only screen and (min-width: 1680px)";
@desktop: ~"only screen and (min-width: 1920px)";


html[lang|=ru-RU] & * {
	font-family: @font-text-ru !important;
}

/*
 * Default styles
 */

body {
	font: 300 17px/28px @font-text;
	color: @color-text;
	min-width: 320px;
	background: @color-white;
	//background: url(../css/template/contact-personen.jpg) no-repeat top center;

	@media @laptop {
		padding: 120px 0 0;
	}
}

#wrapper {
	overflow: hidden;
	//opacity: 0.8;
}

#content {
	position: relative;
	z-index: 1;
}

h1 {
	font: 100 36px/1.2em @font-text;
	color: @color-white;
	letter-spacing: 0.02em;
	padding: 0 20px;
	
	@media @tablet-small  {
		font-size:   45px;
		line-height: 1.2em;
	}

	@media @tablet {
		font-size:   50px;
		line-height: 1.2em;
	}
}

h2 {
	font: 100 32px/1.2em @font-text;
	color: @color-text;
	letter-spacing: 0.02em;
	margin: 0 0 27px; 

	&.center {
		text-align: center;
	}

	@media @mobile-large {
		font-size: 38px;
	}
}

.sub-title {
	font: 100 28px/28px @font-text;
	letter-spacing: 0.02em;

}

p,
ul,
ol {
	margin: 0 0 24px;
}

ul,
ol {
	padding: 0 0 0 18px;
}

a {
	color: inherit;
	transition: color 0.3s ease-in-out;

	&:hover {
		color: @color-primary;
	}
}

a[href^=tel],
a.telephone {
	text-decoration: none;
}

img {
	width: 100%;
	height: auto;
}

sup {
	font-size: 9px;
	position: relative;
	top: -5px;
	left: -3px;
	line-height: 10px;
}

#content {
	position: relative;
	z-index: 1;
}

.jscroll-added,
.jscroll-inner {
	width: 100%;
	float: left;
}

&.hidden {
	display: none;
}

.table-wrapper {
	overflow-x: auto;
}

.table-wrapper--scroll-more {
	position: relative;
	padding: 40px 0 0 0;

	&:before {
		position: absolute;
		.icon(@icon-swipe);
		font-size: 28px;
		top: 0;
		left: 20px;
		animation: slide 2.8s linear infinite;
	}
}

@-webkit-keyframes slide {
	0%{ left:20px; } 
	50%{ left:40px; }
	100%{ left:20px; }
}

@keyframes slide {
	0%{ left:20px; } 
	50%{ left:40px; }
	100%{ left:20px; }
}