﻿/*------------------------------------*\
	#More news
\*------------------------------------*/

@import (reference) "../default.less";

.more-news {
	position: absolute;
	padding: 0 30px 0 0;
	right:  0;
	bottom: -35px;
	font: 500 15px/1.2em @font-text;
	text-transform: uppercase;
	text-decoration: none;

	&:after {
		position: absolute;
		.icon(@icon-caret-right);
		font-size: 9px;
		top:   5px;
		right: 0;
		transition: right 0.2s ease-in-out;
	}

	&:hover:after {
		right: -2px;
	}
}