/*------------------------------------*\
	#Category filter
\*------------------------------------*/
.category-filter__input {
  width: 100%;
  padding: 14px 10px 12px 16px;
  border: 1px solid rgba(51, 51, 51, 0.12);
  margin: 8px 0 22px;
  background: #fff;
  font: 300 17px/1.2em "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  color: #333333;
}
.category-filter__input-container {
  position: relative;
}
.category-filter__input-container:after {
  position: absolute;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  font-family: 'Icons';
  line-height: 1;
  text-transform: none;
  speak: none;
  content: '\e915';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: greyscale;
  width: 51px;
  height: 48px;
  padding: 16px 0 0;
  top: 9px;
  right: 1px;
  background: #fff;
  font-size: 17px;
  text-align: center;
  color: #adadad;
  border-left: 1px solid rgba(51, 51, 51, 0.12);
  pointer-events: none;
}
