﻿/*------------------------------------*\
	#Dealers
\*------------------------------------*/

@import (reference) "../default.less";
@import (reference) "../components/button.less";

.filter {
	position: relative;
	.clearfix;
	background: @color-background-dark;
}

.filter__container {
	
	&:after {
		position: absolute;
		content: '';
		width: 1280px;
		height: 100%;
		right:  100%;
		bottom: 0;
		background: @color-background-dark;
	}
}

.filter__title {
	position: relative;
	float: left;
	font: 500 15px/1.2em @font-text;
	text-transform: uppercase;
	padding: 32px 15px 0 74px;

	&:before {
		position: absolute;
		.icon(@icon-filter);
		font-size: 33px;
		top:  24px;
		left: 20px;

		@media @laptop {
			left: 0;
		}
	}

	@media @laptop {
		padding: 32px 15px 0 54px;
	}
}

.filter__buttons {
	float: left;
	padding: 5px 10px;
}

.filter__button {
	margin: 10px;

	&.button--agricultural-borderd.filter__button--active {
		.button--hover-agricultural-borderd-full;
	}

	&.button--horticulture-borderd.filter__button--active {
		.button--hover-horticulture-borderd-full;
	}
}

.filter__select-container {
	position: relative;
	float: left;
	width: 100%;
	max-width: 278px;
	margin: 15px 20px;

	&:after {
		position: absolute;
		.icon(@icon-caret-thin-down);
		font-size: 17px; 
		width:  50px;
		height: 50px;
		padding: 16px 0 0;
		border-left: 1px solid fade(@color-text, 12%);
		top:  0;
		right: 0;
		text-align: center;
		pointer-events: none;
		color: fade(@color-text, 40%);
	}

	@media @mobile-large {
		max-width: 324px;
	}
}

.filter__select {
	width: 100%;
	border: none;
}

.filter__checkbox {
	display: none;
}

.filter__search {
	margin: 15px;
}