﻿/*------------------------------------*\
	#Side nav
\*------------------------------------*/

@import (reference) "../default.less";

.side-nav {
	margin: 20px 0 40px;

	dt {
		margin: 0 0 36px;
	}

	dt a {
		font: 100 28px/28px @font-text;
		letter-spacing: 0,02px;
	}

	ul {
		list-style: none;
		padding: 0;
		margin: 0;

		a {
			position: relative;
			padding: 17px 20px 14px 38px;
			border-top: 1px solid fade(@color-black, 12%);
			text-transform: uppercase;

			&:before {
				position: absolute;
				.icon(@icon-caret-right);
				font-size: 9px;
				top:  22px;
				left: 18px;
			}
		}
	}

	li.active a {
		background: @color-background;
	}

	li:last-child a {
		border-bottom: 1px solid fade(@color-black, 12%);
	}

	a {
		display: block;
		text-decoration: none;
		font: 500 15px/1.2em @font-text;
	}

	@media @tablet {
		margin: 0 0 40px;
	}
}