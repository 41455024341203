﻿/*------------------------------------*\
	#Product overview
\*------------------------------------*/

@import (reference) "../default.less";

.product-overview {
	.clearfix;
	margin: 72px 0 -40px;

	&:first-child {
		margin-top: 0;
	}

	&:last-child {
		margin-bottom: -18px;
	}
}

.product-overview--no-title {
	
	&:first-child {
		margin-top: 8px;
	}

	&:last-child {
		margin-bottom: -18px;
	}
}

.product-overview__title {
	margin: 0 0 31px;
}