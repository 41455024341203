/*------------------------------------*\
	#Route form
\*------------------------------------*/
.form .route-form-container {
  z-index: 1;
  width: 100%;
  padding: 30px 20px;
  top: 32px;
  left: 0;
}
@media only screen and (min-width: 768px) {
  .form .route-form-container {
    position: absolute;
    width: 768px;
    margin: 0 0 0 -384px;
    left: 50%;
  }
}
.form.form--route {
  z-index: 1;
  padding: 37px 14px 27px 8px;
  top: 0;
}
.form.form--route input[type=text] {
  margin: 5px 10px;
  width: 100%;
}
.form.form--route input[type=text]::-webkit-input-placeholder {
  color: #333333;
  font: 16px/36px "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
}
.form.form--route input[type=text]:-moz-placeholder {
  opacity: 1;
  color: #333333;
  font: 16px/36px "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
}
.form.form--route input[type=text]::-moz-placeholder {
  opacity: 1;
  color: #333333;
  font: 16px/36px "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
}
.form.form--route input[type=text]:-ms-input-placeholder {
  color: #333333;
  font: 16px/36px "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
}
.form.form--route input[type=text]::input-placeholder {
  color: #333333;
  font: 16px/36px "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
}
@media only screen and (min-width: 768px) {
  .form.form--route input[type=text] {
    flex-shrink: 1;
  }
}
.form.form--route button {
  padding: 16px 44px 14px 20px;
  height: 50px;
  margin: 5px 10px;
  flex-shrink: 0;
}
.form .form--route__container {
  display: flex;
  flex-wrap: wrap;
}
@media only screen and (min-width: 1024px) {
  .form .form--route__container {
    flex-wrap: nowrap;
  }
}
