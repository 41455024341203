/*------------------------------------*\
	#Columns 
\*------------------------------------*/
.columns {
  /*
	 * For modern browsers
	 * 1. The space content is one way to avoid an Opera bug when the
	 *    contenteditable attribute is included anywhere else in the document.
	 *    Otherwise it causes space to appear at the top and bottom of elements
	 *    that are clearfixed.
	 * 2. The use of `table` rather than `block` is only necessary if using
	 *    `:before` to contain the top-margins of child elements.
	 */
  *zoom: 1;
}
.columns:before,
.columns:after {
  content: " ";
  display: table;
}
.columns:after {
  clear: both;
}
.columns--gutter-20 {
  margin-left: -10px;
  margin-right: -10px;
}
.columns--gutter-20 .column {
  padding-left: 10px;
  padding-right: 10px;
}
.columns--gutter-30 {
  margin-left: -15px;
  margin-right: -15px;
}
.columns--gutter-30 .column {
  padding-left: 15px;
  padding-right: 15px;
}
.columns--gutter-32 {
  margin-left: -16px;
  margin-right: -16px;
}
.columns--gutter-32 .column {
  padding-left: 16px;
  padding-right: 16px;
}
.columns--gutter-40 {
  margin-left: -20px;
  margin-right: -20px;
}
.columns--gutter-40 .column {
  padding-left: 20px;
  padding-right: 20px;
}
.column {
  float: left;
  clear: left;
  width: 100%;
}
.column--dual {
  width: 50%;
}
.column--dual:nth-child(n + 1) {
  clear: none;
}
.column--dual:nth-child(2n + 1) {
  clear: left;
}
@media only screen and (min-width: 425px) {
  .column--dual-mobile-large {
    width: 50%;
  }
  .column--dual-mobile-large:nth-child(n + 1) {
    clear: none;
  }
  .column--dual-mobile-large:nth-child(2n + 1) {
    clear: left;
  }
}
@media only screen and (min-width: 640px) {
  .column--dual-tablet-small {
    width: 50%;
  }
  .column--dual-tablet-small:nth-child(n + 1) {
    clear: none;
  }
  .column--dual-tablet-small:nth-child(2n + 1) {
    clear: left;
  }
}
@media only screen and (min-width: 768px) {
  .column--dual-tablet {
    width: 50%;
  }
  .column--dual-tablet:nth-child(n + 1) {
    clear: none;
  }
  .column--dual-tablet:nth-child(2n + 1) {
    clear: left;
  }
}
@media only screen and (min-width: 1024px) {
  .column--dual-laptop {
    width: 50%;
  }
  .column--dual-laptop:nth-child(n + 1) {
    clear: none;
  }
  .column--dual-laptop:nth-child(2n + 1) {
    clear: left;
  }
}
@media only screen and (min-width: 1280px) {
  .column--dual-laptop-med {
    width: 50%;
  }
  .column--dual-laptop-med:nth-child(n + 1) {
    clear: none;
  }
  .column--dual-laptop-med:nth-child(2n + 1) {
    clear: left;
  }
}
@media only screen and (min-width: 1440px) {
  .column--dual-laptop-large {
    width: 50%;
  }
  .column--dual-laptop-large:nth-child(n + 1) {
    clear: none;
  }
  .column--dual-laptop-large:nth-child(2n + 1) {
    clear: left;
  }
}
@media only screen and (min-width: 1680px) {
  .column--dual-desktop-small {
    width: 50%;
  }
  .column--dual-desktop-small:nth-child(n + 1) {
    clear: none;
  }
  .column--dual-desktop-small:nth-child(2n + 1) {
    clear: left;
  }
}
.column--triple {
  width: 33.33333333%;
}
.column--triple:nth-child(n + 1) {
  clear: none;
}
.column--triple:nth-child(3n + 1) {
  clear: left;
}
@media only screen and (min-width: 425px) {
  .column--triple-mobile-large {
    width: 33.33333333%;
  }
  .column--triple-mobile-large:nth-child(n + 1) {
    clear: none;
  }
  .column--triple-mobile-large:nth-child(3n + 1) {
    clear: left;
  }
}
@media only screen and (min-width: 640px) {
  .column--triple-tablet-small {
    width: 33.33333333%;
  }
  .column--triple-tablet-small:nth-child(n + 1) {
    clear: none;
  }
  .column--triple-tablet-small:nth-child(3n + 1) {
    clear: left;
  }
}
@media only screen and (min-width: 768px) {
  .column--triple-tablet {
    width: 33.33333333%;
  }
  .column--triple-tablet:nth-child(n + 1) {
    clear: none;
  }
  .column--triple-tablet:nth-child(3n + 1) {
    clear: left;
  }
}
@media only screen and (min-width: 1024px) {
  .column--triple-laptop {
    width: 33.33333333%;
  }
  .column--triple-laptop:nth-child(n + 1) {
    clear: none;
  }
  .column--triple-laptop:nth-child(3n + 1) {
    clear: left;
  }
}
@media only screen and (min-width: 1280px) {
  .column--triple-laptop-med {
    width: 33.33333333%;
  }
  .column--triple-laptop-med:nth-child(n + 1) {
    clear: none;
  }
  .column--triple-laptop-med:nth-child(3n + 1) {
    clear: left;
  }
}
@media only screen and (min-width: 1440px) {
  .column--triple-laptop-large {
    width: 33.33333333%;
  }
  .column--triple-laptop-large:nth-child(n + 1) {
    clear: none;
  }
  .column--triple-laptop-large:nth-child(3n + 1) {
    clear: left;
  }
}
@media only screen and (min-width: 1680px) {
  .column--triple-desktop-small {
    width: 33.33333333%;
  }
  .column--triple-desktop-small:nth-child(n + 1) {
    clear: none;
  }
  .column--triple-desktop-small:nth-child(3n + 1) {
    clear: left;
  }
}
.column--triple-2 {
  width: 66.66666667%;
}
.column--triple-2:nth-child(n + 1) {
  clear: none;
}
.column--triple-2:nth-child(1.5n + 1) {
  clear: left;
}
@media only screen and (min-width: 425px) {
  .column--triple-2-mobile-large {
    width: 66.66666667%;
  }
  .column--triple-2-mobile-large:nth-child(n + 1) {
    clear: none;
  }
  .column--triple-2-mobile-large:nth-child(1.5n + 1) {
    clear: left;
  }
}
@media only screen and (min-width: 640px) {
  .column--triple-2-tablet-small {
    width: 66.66666667%;
  }
  .column--triple-2-tablet-small:nth-child(n + 1) {
    clear: none;
  }
  .column--triple-2-tablet-small:nth-child(1.5n + 1) {
    clear: left;
  }
}
@media only screen and (min-width: 768px) {
  .column--triple-2-tablet {
    width: 66.66666667%;
  }
  .column--triple-2-tablet:nth-child(n + 1) {
    clear: none;
  }
  .column--triple-2-tablet:nth-child(1.5n + 1) {
    clear: left;
  }
}
@media only screen and (min-width: 1024px) {
  .column--triple-2-laptop {
    width: 66.66666667%;
  }
  .column--triple-2-laptop:nth-child(n + 1) {
    clear: none;
  }
  .column--triple-2-laptop:nth-child(1.5n + 1) {
    clear: left;
  }
}
@media only screen and (min-width: 1280px) {
  .column--triple-2-laptop-med {
    width: 66.66666667%;
  }
  .column--triple-2-laptop-med:nth-child(n + 1) {
    clear: none;
  }
  .column--triple-2-laptop-med:nth-child(1.5n + 1) {
    clear: left;
  }
}
@media only screen and (min-width: 1440px) {
  .column--triple-2-laptop-large {
    width: 66.66666667%;
  }
  .column--triple-2-laptop-large:nth-child(n + 1) {
    clear: none;
  }
  .column--triple-2-laptop-large:nth-child(1.5n + 1) {
    clear: left;
  }
}
@media only screen and (min-width: 1680px) {
  .column--triple-2-desktop-small {
    width: 66.66666667%;
  }
  .column--triple-2-desktop-small:nth-child(n + 1) {
    clear: none;
  }
  .column--triple-2-desktop-small:nth-child(1.5n + 1) {
    clear: left;
  }
}
.column--quad {
  width: 25%;
}
.column--quad:nth-child(n + 1) {
  clear: none;
}
.column--quad:nth-child(4n + 1) {
  clear: left;
}
@media only screen and (min-width: 425px) {
  .column--quad-mobile-large {
    width: 25%;
  }
  .column--quad-mobile-large:nth-child(n + 1) {
    clear: none;
  }
  .column--quad-mobile-large:nth-child(4n + 1) {
    clear: left;
  }
}
@media only screen and (min-width: 640px) {
  .column--quad-tablet-small {
    width: 25%;
  }
  .column--quad-tablet-small:nth-child(n + 1) {
    clear: none;
  }
  .column--quad-tablet-small:nth-child(4n + 1) {
    clear: left;
  }
}
@media only screen and (min-width: 768px) {
  .column--quad-tablet {
    width: 25%;
  }
  .column--quad-tablet:nth-child(n + 1) {
    clear: none;
  }
  .column--quad-tablet:nth-child(4n + 1) {
    clear: left;
  }
}
@media only screen and (min-width: 1024px) {
  .column--quad-laptop {
    width: 25%;
  }
  .column--quad-laptop:nth-child(n + 1) {
    clear: none;
  }
  .column--quad-laptop:nth-child(4n + 1) {
    clear: left;
  }
}
@media only screen and (min-width: 1280px) {
  .column--quad-laptop-med {
    width: 25%;
  }
  .column--quad-laptop-med:nth-child(n + 1) {
    clear: none;
  }
  .column--quad-laptop-med:nth-child(4n + 1) {
    clear: left;
  }
}
@media only screen and (min-width: 1440px) {
  .column--quad-laptop-large {
    width: 25%;
  }
  .column--quad-laptop-large:nth-child(n + 1) {
    clear: none;
  }
  .column--quad-laptop-large:nth-child(4n + 1) {
    clear: left;
  }
}
@media only screen and (min-width: 1680px) {
  .column--quad-desktop-small {
    width: 25%;
  }
  .column--quad-desktop-small:nth-child(n + 1) {
    clear: none;
  }
  .column--quad-desktop-small:nth-child(4n + 1) {
    clear: left;
  }
}
.column--quin {
  width: 20%;
}
.column--quin:nth-child(n + 1) {
  clear: none;
}
.column--quin:nth-child(5n + 1) {
  clear: left;
}
@media only screen and (min-width: 425px) {
  .column--quin-mobile-large {
    width: 20%;
  }
  .column--quin-mobile-large:nth-child(n + 1) {
    clear: none;
  }
  .column--quin-mobile-large:nth-child(5n + 1) {
    clear: left;
  }
}
@media only screen and (min-width: 640px) {
  .column--quin-tablet-small {
    width: 20%;
  }
  .column--quin-tablet-small:nth-child(n + 1) {
    clear: none;
  }
  .column--quin-tablet-small:nth-child(5n + 1) {
    clear: left;
  }
}
@media only screen and (min-width: 768px) {
  .column--quin-tablet {
    width: 20%;
  }
  .column--quin-tablet:nth-child(n + 1) {
    clear: none;
  }
  .column--quin-tablet:nth-child(5n + 1) {
    clear: left;
  }
}
@media only screen and (min-width: 1024px) {
  .column--quin-laptop {
    width: 20%;
  }
  .column--quin-laptop:nth-child(n + 1) {
    clear: none;
  }
  .column--quin-laptop:nth-child(5n + 1) {
    clear: left;
  }
}
@media only screen and (min-width: 1280px) {
  .column--quin-laptop-med {
    width: 20%;
  }
  .column--quin-laptop-med:nth-child(n + 1) {
    clear: none;
  }
  .column--quin-laptop-med:nth-child(5n + 1) {
    clear: left;
  }
}
@media only screen and (min-width: 1440px) {
  .column--quin-laptop-large {
    width: 20%;
  }
  .column--quin-laptop-large:nth-child(n + 1) {
    clear: none;
  }
  .column--quin-laptop-large:nth-child(5n + 1) {
    clear: left;
  }
}
@media only screen and (min-width: 1680px) {
  .column--quin-desktop-small {
    width: 20%;
  }
  .column--quin-desktop-small:nth-child(n + 1) {
    clear: none;
  }
  .column--quin-desktop-small:nth-child(5n + 1) {
    clear: left;
  }
}
