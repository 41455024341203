﻿/*------------------------------------*\
	#Search results
\*------------------------------------*/

@import (reference) "../default.less";

 .search-results {
	list-style: none;
	padding: 0;
	margin: -40px 0 70px;

	> li {
		margin-top: 40px;

		h3 {
			margin-bottom: 14px;

			+ .breadcrumbs {
				margin-top: 8px;
			}
		}

		.breadcrumbs {
			background: none;
			border-bottom: 1px solid @color-background;
			padding: 0 0 32px;
			margin: 0;
		}

		&:first-of-type {
			border-top: 1px solid @color-background;
			padding-top: 40px;
		}

		&:before {
			display: none;
		}
	}
}