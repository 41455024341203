﻿/*------------------------------------*\
	#360 view
\*------------------------------------*/

@import (reference) "../default.less";

.three-sixty__wrapper {
	
	@media @laptop {
		margin: 0 0 0 40px;
	}
}

.three-sixty__link {
	position: relative; 
	display: block;
	text-decoration: none;

	&:before {
		position: absolute;
		.icon(@icon-three-sixyt);
		font-size: 70px;
		margin: 0 0 0 -35px;
		top:  15px;
		left: 50%;
		color: fade(@color-black, 35%);
	}

	&:hover:before  {
		color: fade(@color-black, 60%);
	}
}