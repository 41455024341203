/*------------------------------------*\
	#Video
\*------------------------------------*/
.video__wrapper {
  padding: 4px 0 0;
  margin: 0 0 22px;
}
.video {
  position: relative;
  overflow: hidden;
  height: 0;
  padding: 0 0 56.1746988%;
  margin: 0;
}
.video iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
