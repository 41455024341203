/*------------------------------------*\
	#Happening
\*------------------------------------*/
.happenings {
  margin: 0 0 -18px 0px;
}
.happening {
  display: block;
  text-decoration: none;
  border-bottom: 10px solid #eeebe3;
  margin: 9px 0 40px;
  background: #faf7f2 url(../images/grey-watermark.png) no-repeat center right -140px;
  background-size: 566px 566px;
  color: #333333;
  transition: background 0.2s ease-in-out;
}
.happening.agrarisch {
  border-bottom-color: #ffb400;
}
.happening.tuinbouw {
  border-bottom-color: #77b039;
}
.happening:hover {
  background-color: #ece0cd;
}
.happening:hover .happening__title:after {
  left: 13px;
}
.happening:hover .happening__date {
  color: #333333;
}
.happening--frontpage {
  margin: 8px 0 0;
}
.happening--frontpage:hover {
  background-color: #faf7f2;
}
.happening--frontpage:hover .happening__title:after {
  left: 10px;
}
@media only screen and (min-width: 640px) {
  .happening--frontpage .columns {
    display: flex;
    align-items: center;
  }
}
.happening__container {
  padding: 40px 39px 25px;
}
.frontpage .happening__container {
  padding: 140px 20px 25px;
}
@media only screen and (min-width: 640px) {
  .frontpage .happening__container {
    padding: 32px 40px 32px 140px;
  }
}
.happening__container--frontpage {
  position: relative;
  display: block;
  text-decoration: none;
  padding-left: 140px;
}
.happening__container--frontpage:before {
  position: absolute;
  content: '';
  width: 60px;
  height: 63px;
  margin: 0 0 0 -20px;
  top: 44px;
  left: 50%;
  background: url(../images/calander.png) center no-repeat;
  background-image: url(../images/calander.svg), none;
}
@media only screen and (min-width: 640px) {
  .happening__container--frontpage:before {
    left: 40px;
    margin: 0 0 0 0;
  }
}
.happening--frontpage .happening__container--frontpage:hover .happening__title:after {
  left: 13px;
}
.happening__date {
  display: block;
  font: 100 28px/44px "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  letter-spacing: 0.02em;
  margin: 0 0 -3px;
  color: #333333;
}
.happening-date {
  display: block;
  font: 100 27px/39px "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  letter-spacing: 0.02em;
  margin: -26px 0 26px;
}
.happening__title {
  font: 100 28px/38px "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  letter-spacing: 0;
  text-transform: uppercase;
  margin: 0;
}
.happening__title:after {
  position: relative;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  font-family: 'Icons';
  line-height: 1;
  text-transform: none;
  speak: none;
  content: '\e914';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: greyscale;
  font-size: 17px;
  top: -6px;
  left: 10px;
  transition: left 0.2s ease-in-out;
}
@media only screen and (min-width: 425px) {
  .happening__title {
    font-size: 38px;
    line-height: 44px;
  }
}
.happening__button-container {
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
}
