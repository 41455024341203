/*------------------------------------*\
	#Aside
\*------------------------------------*/
.contact-person {
  width: 100%;
  margin: 0 0 40px;
}
@media only screen and (min-width: 1024px) {
  .contact-person {
    float: right;
    width: 342px;
    padding: 0 0 0 40px;
    margin: 0 0 8px;
  }
  .contact-person + .content {
    float: left;
    width: calc(100% - 342px);
  }
}
.contact-person__content {
  padding: 33px 40px 33px;
  border-bottom: 10px solid #eeebe3;
  font: 300 20px/28px "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  text-align: center;
  background: #faf7f2;
}
.contact-person__content strong {
  font-weight: 500;
}
.contact-person__title {
  margin: 0 0 22px;
  letter-spacing: 0.02em;
  font-weight: 300;
}
.contact-person__image {
  max-width: 150px;
  border-radius: 50%;
  margin: 0 auto 24px;
}
.contact-person__name {
  display: block;
  font-weight: 500;
  margin: 0 0 13px;
}
.contact-person__button {
  margin: 0 0 14px;
}
.contact-person__call {
  display: block;
}
