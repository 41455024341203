﻿/*------------------------------------*\
	#Route form
\*------------------------------------*/

@import (reference) "../default.less";

.form {

	.route-form-container {
		z-index: 1;
		width: 100%;
		padding: 30px 20px; 
		top:  32px;
		left: 0;

		@media @tablet {
			position: absolute;
			width: 768px;
			margin: 0 0 0 -384px;
			left: 50%;
		}
	}

	&.form--route {
		z-index: 1;
		padding: 37px 14px 27px 8px;
		top: 0;

		input[type=text] {
			margin: 5px 10px;
			width: 100%;

			.placeholder({
				color: @color-text;
				font: 16px/36px @font-text;
			});

			@media @tablet {
				flex-shrink: 1;
			}
		}

		button {
			padding: 16px 44px 14px 20px;
			height: 50px;
			margin: 5px 10px;
			flex-shrink: 0;
		}
	}

	.form--route__container {
		display: flex;
		flex-wrap: wrap;
	
		@media @laptop {
			flex-wrap: nowrap;
		}
	}
}