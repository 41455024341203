/*------------------------------------*\
	#Vacancy
\*------------------------------------*/
.vacancies {
  margin: 0 0 -18px 0px;
}
.vacancy {
  display: block;
  text-decoration: none;
  border-bottom: 10px solid #eeebe3;
  margin: 9px 0 40px;
  background: #faf7f2 url(../images/grey-watermark.png) no-repeat center right -140px;
  background-size: 566px 566px;
  color: #333333;
  transition: background 0.2s ease-in-out;
}
.vacancy:hover {
  background-color: #ece0cd;
}
.vacancy:hover .vacancy__action-text:after {
  left: 13px;
}
.vacancy:hover .vacancy__action-text {
  color: #333333;
}
.vacancy__container {
  padding: 40px 20px 25px;
}
@media only screen and (min-width: 425px) {
  .vacancy__container {
    padding: 40px 39px 25px;
  }
}
.vacancy__title {
  font: 100 20px/38px "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  letter-spacing: 0;
  text-transform: uppercase;
  margin: 0;
}
@media only screen and (min-width: 425px) {
  .vacancy__title {
    font-size: 28px;
  }
}
@media only screen and (min-width: 640px) {
  .vacancy__title {
    font-size: 38px;
    line-height: 44px;
  }
}
.vacancy__action-text {
  font: 100 20px/37px "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  letter-spacing: 0.02em;
}
.vacancy__action-text:after {
  position: relative;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  font-family: 'Icons';
  line-height: 1;
  text-transform: none;
  speak: none;
  content: '\e914';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: greyscale;
  font-size: 8px;
  top: -2px;
  left: 10px;
  transition: left 0.2s ease-in-out;
}
@media only screen and (min-width: 425px) {
  .vacancy__action-text:after {
    font-size: 13px;
    left: 17px;
  }
}
@media only screen and (min-width: 425px) {
  .vacancy__action-text {
    font-size: 28px;
  }
}
