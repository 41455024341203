﻿/*------------------------------------*\
	#Category filter
\*------------------------------------*/

@import (reference) "../default.less";

.category-filter__input {
	width: 100%;
	padding: 14px 10px 12px 16px;
	border: 1px solid fade(@color-text, 12%);
	margin: 8px 0 22px;
	background: @color-white;
	font: 300 17px/1.2em @font-text;
	color: @color-text;
}

.category-filter__input-container {
	position: relative;

	&:after {
		position: absolute;
		.icon('\e915');
		width:  51px;
		height: 48px;
		padding: 16px 0 0; 
		top:   9px;
		right: 1px;
		background: @color-white;
		font-size: 17px;
		text-align: center;
		color: mix(@color-white, @color-text, 60%);
		border-left: 1px solid fade(@color-text, 12%);
		pointer-events: none;
	}
}