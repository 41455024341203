﻿/*------------------------------------*\
	#Languages
\*------------------------------------*/

@import (reference) "../default.less";

// Languages
.languages {
	white-space: nowrap;
	width: 100%;
	margin: 0;
	padding: 0 15px;
	top:   0;
	right: 0;
	z-index: 7;

	dt {
		position: relative;
		display: none;
		overflow: hidden;
		width:  43px;
		height: 41px;
		padding: 6px 0 8px 53px;
		cursor: default;
		text-indent: 9999px;

		&:before {
			content: '';
			position: absolute;
			width:  21px;
			height: 21px;
			margin: 0;
			top:  14px;
			left: 10px;
			background: url(../images/languages.png) no-repeat top center;
			background-size: cover;
		}

		&.nl:before { background-position: 0 0; }
		&.en:before { background-position: 0 -21px; }
		&.de:before { background-position: 0 -42px; }
		&.ru:before { background-position: 0 -63px; }
		&.es:before { background-position: 0 -84px; }
		&.pl:before { background-position: 0 -106px; }

		&:after {
			position: absolute;
			.icon(@icon-caret-down); // chevron-down
			top:   20px;
			right: 9px;
			font-size: 7px;
			text-indent: 0;
		}

		@media @laptop {
			display: block;
		}
	}

	dd {
		min-width: 100%;
		top:   40px;
		right: 0;
		z-index: 3;

		ul {
			list-style: none;
			padding: 0; 
			margin: 0;

			li {
				position: relative;

				&:first-of-type {
					a {
						padding-top: 5px;

						&:before {
							top: 15px;
						}
					}
				}

				&:last-of-type {

					a {
						padding-bottom: 7px;

						&:before {
							top: 11px;
						}
					}
				}

				a {
					font: 300 15px/30px @font-text;
					color: @color-white;
					text-decoration: none;
					display: block;
					padding: 0 15px 0 53px;
					opacity: 1;
					position: relative;

					&:before {
						content: '';
						position: absolute;
						width:  21px;
						height: 13px;
						margin: 0;
						top:  10px;
						left: 10px;
						background: url(../images/languages.png) no-repeat top center;
						background-size: cover;
						border: 1px solid fade(@color-white, 60%);
					}

					@media @laptop {
						color: @color-text;
					}
				}

				&:hover a {
					text-decoration: underline;
				}

				&.nl a:before { background-position: 0 -4px; }
				&.en a:before { background-position: 0 -23px; }
				&.de a:before { background-position: 0 -42px; }
				&.ru a:before { background-position: 0 -62px; }
				&.es a:before { background-position: 0 -80px; }
				&.pl a:before { background-position: 0 -100px; }
			}

			@media @laptop {
				background: @color-background;
			}
		}

		@media @laptop {
			position: absolute;
			visibility: hidden;
		}
	}

	&.open,
	&:hover {

		dt {

			&:after {
				z-index: 4;
			}
		}

		dd {
			visibility: visible;
		}

		@media @laptop {
			background: @color-background;
		}
	}

	@media @laptop {
		position: absolute;
		max-width: 50px;
		height: 53px;
		padding: 0;
		background: none;
	}
}