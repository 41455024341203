﻿/*------------------------------------*\
	#Dealers aside 
\*------------------------------------*/

@import (reference) "../default.less";

.aside-dealers {
	padding: 35px 20px 28px;
	margin: 0 0 40px;
	background: @color-background;
	border-bottom: 10px solid @color-background-dark;

	@media @mobile-large {
		padding: 35px 40px 28px;
	}
}

.aside-dealers__title {
	font: 300 20px/28px @font-text;
	text-align: center;
	margin: 0 0 17px;
}

.aside-dealers__image {
	width:  252px;
	height: 138px;
	margin: 0 auto 21px;
	background: url(../images/aside-dealers-image.png);

	@media @laptop {
		margin: 0 -15px 21px;
	}
}

.aside-dealers .aside-dealers__button {
	margin: 0 0 10px;
}