﻿/*------------------------------------*\
	#Employees
\*------------------------------------*/

@import (reference) "../default.less";

.employees {
	padding: 9px 0 0;
	margin-bottom: -18px;
}

.employee {
	margin: 0 0 40px;
}

.employee__image-placeholder {
	.responsive-container(600, 400);
	background: url(../images/placeholder.jpg) no-repeat center;
	background-image: url(../images/placeholder.svg), none;
	background-size: cover;
}

.employee__description {
	padding: 34px 0 0;
	border-bottom: 10px solid @color-background-dark;
	background: @color-background;
}

.employee__name {
	font: 300 20px/28px @font-text;
	letter-spacing: 0.02em;
	padding: 0 40px;
	margin: 0 0 32px;
}

.employee__job-title {
	font: 300 17px/28px @font-text;
	padding: 0 40px;
	margin: -31px 0 32px;
}

.employee__notes {
	padding: 10px 40px 12px;
	border-top: 1px solid fade(@color-text, 12%);
}

.employee__contact-detail {
	padding: 10px 40px 12px;
	border-top: 1px solid fade(@color-text, 12%);
}

.employee__email,
.employee__telephone {
	position: relative;
	padding: 0 0 0 44px;

	&:before {
		position: absolute;
		font-size: 20px;
		top:  0;
		left: 6px;
	}
}

.employee__telephone  {
	
	&:before {
		.icon(@icon-phone);
	}
}

.employee__email {
	
	&:before {
		.icon(@icon-mail);
	}
}