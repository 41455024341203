/*------------------------------------*\
	#Project
\*------------------------------------*/
.projects {
  margin-bottom: -8px;
}
.project {
  /*
	 * For modern browsers
	 * 1. The space content is one way to avoid an Opera bug when the
	 *    contenteditable attribute is included anywhere else in the document.
	 *    Otherwise it causes space to appear at the top and bottom of elements
	 *    that are clearfixed.
	 * 2. The use of `table` rather than `block` is only necessary if using
	 *    `:before` to contain the top-margins of child elements.
	 */
  *zoom: 1;
  position: relative;
  margin: 0 0 40px;
}
.project:before,
.project:after {
  content: " ";
  display: table;
}
.project:after {
  clear: both;
}
.project__overview-title {
  margin: 0 0 31px;
}
.project__container {
  overflow: hidden;
  display: block;
  border-bottom: 10px solid #eeebe3;
  text-decoration: none;
  background: #faf7f2;
  transition: background 0.2s ease-in-out;
}
.project__container:hover {
  color: #333333;
  background: #eeebe3;
}
.project__container--horticulture {
  border-bottom-color: #77b039;
}
.project__container--agricultural {
  border-bottom-color: #ffb400;
}
.project__image-container {
  width: 100%;
  background: #fff;
}
.project__placeholder {
  position: relative;
  overflow: hidden;
  height: 0;
  padding: 0 0 66.66666667%;
  background: url(../images/placeholder.jpg) no-repeat center;
  background-image: url(../images/placeholder.svg), none;
  background-size: cover;
}
.project__title-prefix {
  font: 300 20px/28px "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  letter-spacing: 0.02em;
}
.project__text-container {
  padding: 34px 40px 33px;
}
.project__text-container p {
  letter-spacing: 0.02em;
  margin: 0;
}
.product__text-content {
  position: relative;
  height: 100%;
}
.project__title {
  font: 300 20px/28px "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  letter-spacing: 0.02em;
  text-transform: uppercase;
}
.project__title:after {
  position: relative;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  font-family: 'Icons';
  line-height: 1;
  text-transform: none;
  speak: none;
  content: '\e900';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: greyscale;
  font-size: 9px;
  top: -3px;
  left: 7px;
  transition: left 0.2s ease-in-out;
}
.project__container:hover .project__title:after {
  left: 9px;
}
.project__to-overview {
  position: absolute;
  right: 21px;
  bottom: -45px;
  font: 500 15px/1.2em "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  padding: 0 24px 0 0;
  text-transform: uppercase;
  text-decoration: none;
}
.project__to-overview:after {
  position: absolute;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  font-family: 'Icons';
  line-height: 1;
  text-transform: none;
  speak: none;
  content: '\e90b';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: greyscale;
  font-size: 9px;
  margin: -4px 0 0;
  top: 50%;
  right: 0;
  transition: left 0.2s ease-in-out;
}
.project__to-overview:hover:after {
  right: -2px;
}
