﻿/*------------------------------------*\
	#Intro block frontpage
\*------------------------------------*/

@import (reference) "../default.less";

.intro-block-frontpage__block {
	position: relative;
	padding-top: 416px;
	background: @color-background no-repeat top center;
	border-bottom: 10px solid @color-background-dark;
	margin: 0 0 40px;

	p {
		position: relative;
		z-index: 1;
		font: 300 28px/36px @font-text;
		color: @color-text;
		letter-spacing: 0.02em;
		text-align: center;
		padding: 0 40px;
		margin: -70px 0 22px;
		background: @color-background;
	}
}

.intro-block-frontpage__block--triangle-bottom {
	padding-top: 0;
	display: flex;
	flex-direction: column;

	&:before {
		position: absolute;
		z-index: 1;
		content: '';
		width:  0;
		height: 0;
		margin: 0 0 0 -20.5px;
		border-style: solid;
		border-width: 21px 20.5px 0 20.5px;
		border-color: @color-background transparent transparent transparent;
		bottom: -21px;
		left:   50%;
	}

	&:after {
		position: absolute;
		content: '';
		width:  0;
		height: 0;
		margin: 0 0 0 -25.5px;
		border-style: solid;
		border-width: 25px 25.5px 0 25.5px;
		border-color: @color-background-dark transparent transparent transparent;
		bottom: -35px;
		left:   50%;
	}
}



.intro-block-frontpage__block--agriculture {
	border-bottom-color: @color-agricultural;

	.intro-block-frontpage__image {
		background-image: url(../images/agriculture-intro-block.png);
	}
}

.intro-block-frontpage__block--horticulture {
	border-bottom-color: @color-horticulture;

	.intro-block-frontpage__image {
		background-image: url(../images/horticulture-intro-block.png);
	}
}

.intro-block-frontpage__image {
	position: absolute;
	width: 100%;
	height: 416px;
	top:  0;
	left: 0;
	background-size: cover;
	background-position: center;
}

.intro-block-frontpage__image--fading:before {
	position: absolute;
	z-index: 1;
	content: '';
	width:  100%;
	height: 180px;
	bottom: 70px;
	left:   0;
	background: linear-gradient(to top, rgba(250,247,242,1) 0%, rgba(250,247,242,0) 100%);
}

.intro-block-frontpage__title {
	position: absolute;
	width: 100%;
	top:  12px;
	left: 0;
	text-align: center;
}

.intro-block-frontpage__list {

	ul {
		list-style: none;
		padding: 0;
		margin:  0;
	}

	> ul {
		border-bottom: 1px solid fade(@color-text, 12%);
	}

	a,
	strong {
		position: relative;
		display: block;
		text-decoration: none;
		padding: 17px 38px 15px;
		border-top: 1px solid fade(@color-text, 12%);
		font: 500 15px/1.2em @font-text;
		letter-spacing: 0.02em;
		text-transform: uppercase;

		strong {
			margin: -17px -38px -15px;
			border-top: 0;
		}

		+ ul.open {
			height: auto;
		}
	}

	strong {
		padding-right: 61px;

		&:before {
			position: absolute;
			.icon(@icon-caret-thin-down);
			width:  51px;
			height: 50px;
			padding-top: 16.5px;
			border-left: 1px solid fade(@color-text, 12%);
			top:   0;
			right: 0;
			font-size: 17px;
			text-align: center;

		}
	}


	li {

		ul {
			overflow: hidden;
			height: 0;
		}

		&:hover ul {
			height: auto;
		}

		li a {
			font-size: 13px;
			padding-left: 55px;
			background: @color-background-dark;

			&:before {
				position: absolute;
				.icon(@icon-caret-bold-right);
				font-size: 7px;
				top:  20.5px;
				left: 42px;
			}
		}
	}
}

.intro-block-frontpage__slideshow {
	position: relative;
	height: 100%;
}

.intro-block-frontpage__slideshow-container {
	flex: 1 1 auto;
}

.intro-block-frontpage__slideshow.lSSlideOuter .lSPager.lSpg  {
	width: 100%;

	> li a {
		width:  12px;
		height: 12px;
		background: @color-background-dark;
	}

	> li.active a {
		background: @color-primary;
	}
}

.intro-block-frontpage__outro {
	position: relative;
	z-index: 1;
	display: block;
	font: 500 15px/1.2em @font-text;
	text-transform: uppercase;
	letter-spacing: 0.02em;
	text-align: center;
	padding: 17px 20px 16px;
	border-top: 1px solid fade(@color-text, 12%);
	text-decoration: none;
	flex: 0 0 auto;

	&:after {
		position: absolute;
		.icon(@icon-caret-light-down);
		font-size: 17px;
		margin: 0 0 0 -8.5px;
		bottom: -8px;
		left:   50%;
	}
}