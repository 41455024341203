/*------------------------------------*\
	#Form controls
\*------------------------------------*/
input[type=text],
input[type=password],
input[type=email],
input[type=tel],
textarea,
select {
  font: 16px/36px "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  padding: 6px 16px 6px;
  -webkit-appearance: none;
  outline: none;
  color: #333333;
  height: 50px;
  border: 1px solid rgba(51, 51, 51, 0.12);
  background: #fff;
}
input[type=text].ivld,
input[type=password].ivld,
input[type=email].ivld,
input[type=tel].ivld,
textarea.ivld,
select.ivld {
  background: #ffcccc;
  border-color: #ff9999;
}
input[type=file].required {
  border-left: 2px solid #ff9999;
}
textarea {
  resize: vertical;
  overflow: auto;
  height: auto;
  min-height: 150px;
}
label {
  font: 300 17px/30px "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  padding: 10px 10px 10px 0;
}
select {
  -moz-appearance: none;
  border-radius: 0;
  padding: 8px 16px 9px;
}
select::-ms-expand {
  display: none;
}
.form-check {
  display: none;
}
.select-wrapper {
  position: relative;
  display: block;
}
.select-wrapper select {
  width: 100%;
}
.select-wrapper:after {
  position: absolute;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  font-family: 'Icons';
  line-height: 1;
  text-transform: none;
  speak: none;
  content: '\e915';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: greyscale;
  font-size: 17px;
  width: 50px;
  height: 50px;
  padding: 16px 0 0;
  border-left: 1px solid rgba(51, 51, 51, 0.12);
  top: 0;
  right: 0;
  text-align: center;
  pointer-events: none;
  color: rgba(51, 51, 51, 0.4);
}
input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: rgba(51, 51, 51, 0.12);
  font: 16px/36px "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
}
input:-moz-placeholder,
textarea:-moz-placeholder {
  opacity: 1;
  color: rgba(51, 51, 51, 0.12);
  font: 16px/36px "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
}
input::-moz-placeholder,
textarea::-moz-placeholder {
  opacity: 1;
  color: rgba(51, 51, 51, 0.12);
  font: 16px/36px "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
}
input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: rgba(51, 51, 51, 0.12);
  font: 16px/36px "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
}
input::input-placeholder,
textarea::input-placeholder {
  color: rgba(51, 51, 51, 0.12);
  font: 16px/36px "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
}
.empty {
  display: none;
  min-height: 50px;
}
@media only screen and (min-width: 1024px) {
  .empty {
    display: block;
  }
}
.form__control {
  overflow: hidden;
  margin: 0 0 20px;
}
