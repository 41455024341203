/*------------------------------------*\
	#More news
\*------------------------------------*/
.more-news {
  position: absolute;
  padding: 0 30px 0 0;
  right: 0;
  bottom: -35px;
  font: 500 15px/1.2em "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  text-transform: uppercase;
  text-decoration: none;
}
.more-news:after {
  position: absolute;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  font-family: 'Icons';
  line-height: 1;
  text-transform: none;
  speak: none;
  content: '\e90b';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: greyscale;
  font-size: 9px;
  top: 5px;
  right: 0;
  transition: right 0.2s ease-in-out;
}
.more-news:hover:after {
  right: -2px;
}
