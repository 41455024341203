﻿/*------------------------------------*\
	#Search
\*------------------------------------*/

@import (reference) "../default.less";

.search {
	position: absolute;
	z-index: 5;
	background: @color-primary;
	overflow: hidden;
	width: 100%;
	height: 60px;
	padding: 0 20px;
	right: 0;
	top: 100px;
	transition: width 0.2s ease-in-out;

	.search__button {
		padding-left: 20px;
		padding-right: 20px;
		flex: 0 0 auto;
	}

	.input {
		width: 100%;
	}

	@media @laptop {
		width: calc(~"100% - 285px");
		height: 70px;
		top: auto;
		bottom: 0;
	}
}

.search__button,
.search__toggler {
	position: absolute;
	z-index: 1;
	height: 60px;
	width: 60px;
	margin: 0;
	text-align: center;
	color: @color-white;
	cursor: pointer;
	text-indent: -9999px;

	@media @laptop {
		height: 70px;
		width: 70px;
	}
}

.search__toggler {
	top: 0;
	right: 0;
	transition: color 0.2s ease-in-out;

	&:after {
		position: absolute;
		.icon(@icon-close);
		font-size: 23px;
		top: 18px;
		left: 18px;
		text-indent: 0;

		@media @laptop {
			top: 23px;
			left: 23px;
		}
	}

	&:hover {
		color: @color-background-dark;
	}
}

.search__toggler--closed:after {
	.icon(@icon-search);
}

.search__button {
	top:  -10px;
	left: 0;
}

.search--closed {
	width:  60px;

	@media @laptop {
		width:  70px;
	}
}

.search__container {
	position: absolute;
	overflow: hidden;
	min-width: 280px;
	width: 100%;
	height: 60px;
	padding: 5px 0;
	right: 0;

	div {
		overflow: hidden;
		position: relative;
		width: 100%;
		padding: 0 70px;

		&:after {
			position: absolute;
			.icon(@icon-search);
			color: @color-white;
			font-size: 23px;
			top: 14px;
			left: 23px;
			text-indent: 0;
		}
	}

	@media @laptop {
		height: 70px;
		padding: 10px 0;
	}
}

.search__button {
	padding-left: 20px;
	padding-right: 20px;
}