/*------------------------------------*\
	#Search results
\*------------------------------------*/
.search-results {
  list-style: none;
  padding: 0;
  margin: -40px 0 70px;
}
.search-results > li {
  margin-top: 40px;
}
.search-results > li h3 {
  margin-bottom: 14px;
}
.search-results > li h3 + .breadcrumbs {
  margin-top: 8px;
}
.search-results > li .breadcrumbs {
  background: none;
  border-bottom: 1px solid #faf7f2;
  padding: 0 0 32px;
  margin: 0;
}
.search-results > li:first-of-type {
  border-top: 1px solid #faf7f2;
  padding-top: 40px;
}
.search-results > li:before {
  display: none;
}
