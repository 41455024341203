﻿/*------------------------------------*\
	#Tabs
\*------------------------------------*/

@import (reference) "../default.less";



.tabs {
	margin: 0 0 31px;
}

.tabs__control {
	.clearfix;
	padding: 0;
	margin:  0;
	list-style: none;
}

.tabs__control-item {
	position: relative;
	float: left;
	padding: 17px 40px 16px 18px;
	font: 500 15px/1.2em @font-text;
	text-transform: uppercase;
	letter-spacing: 0.02em;
	cursor: pointer;

	&:after {
		position: absolute;
		content: '';
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 0 20px 51px 0;
		border-color: transparent @color-white transparent transparent;
		top:   0;
		right: 0
	}

	&:hover {
		background: lighten(@color-background, 2%);
	}
}

.tabs__control-item--active {
	background: @color-background;

	&:hover {
		background: @color-background;
	}
}

.tabs__content {
	display: none;
	padding: 34px 20px 8px;
	background: @color-background;

	ul {
		.clearfix;
		padding: 8px 0 0;
		margin: 0 -20px 32px;
		list-style: none;
		 
		li {
			float: left;
			width: 100%;
			padding: 0 20px;

			span {
				position: relative;
				display: block;
				padding: 11px 20px 10px 44px;
				border-bottom: 1px solid fade(@color-text, 12%);

				a {
					display: block;
					padding: 11px 20px 10px 44px;
					margin: -12px -20px -9px -44px;
					text-decoration: none;
				}

				&:before {
					position: absolute;
					.icon(@icon-caret-right);
					font-size: 9px; 
					top:  21px;
					left: 18px;
				}
			}

			&:first-child span {
				border-top: 1px solid fade(@color-text, 12%);
			} 

			ul { 
				margin: 0 0 -11px;

				li {
					width: 100%;
					
					&:first-child + li span {
						border-top: 0;
					}

					&:last-child span {
						border-bottom: 0;
					}
				}
			}

			@media @tablet {
				width: 50%;

				&:first-child + li span {
					border-top: 1px solid fade(@color-text, 12%);
				}
			}
		}
	}

	table {
		margin: 0 0 32px;
	}

	@media @mobile-large {
		padding: 34px 40px 8px;
	}
}

.tabs__content--active {
	display: block;
}

.tab__images {
	margin-bottom: -8px;
}

.tab__image {
	margin-bottom: 40px
}

.tab__video {
	position: relative;
	display: block;
	
	&:before {
		position: absolute;
		content: '';
		width:  70px;
		height: 49px;
		margin: -25px 0 0 -35px;
		top:  50%;
		left: 50%;
		background: url(../images/footer-video-play.png);
		background-image: url(../images/footer-video-play.svg), none;
	}
}