﻿/*------------------------------------*\
	#Call to action aside
\*------------------------------------*/

@import (reference) "../default.less";

.aside-call-to-action {
	.clearfix;
	background: @color-primary url(../images/red-watermark.png) no-repeat center right -192px;
	background-size: 379px 379px;
	padding: 36px 20px 40px;
	margin: 0 0 40px;

	@media @mobile-large {
		padding: 36px 40px 40px;
	}
}

.aside-call-to-action__caption {
	text-align: center;
}

.aside-call-to-action__title {
	width: 100%;
	padding: 0 0 34px;
	margin: 0;
	font: 100 20px/28px @font-text;
	letter-spacing: 0.02em;
	color: @color-white;
	text-align: center;

	strong {
		font-weight: 500;
	}

	p {
		margin: 0;
	}
}

.aside-call-to-action .aside-call-to-action__button {
	width: 100%;

	&:after {
		right: 32px;
	}

	&:hover:after {
		right: 30px;
	}
}