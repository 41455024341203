/*------------------------------------*\
	#Product details
\*------------------------------------*/
.product-details {
  position: relative;
  display: inline-block;
  padding: 15px 30px 18px 0;
  font: 500 15px/1.2em "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  text-transform: uppercase;
  text-decoration: none;
}
.product-details:after {
  position: absolute;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  font-family: 'Icons';
  line-height: 1;
  text-transform: none;
  speak: none;
  content: '\e90c';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: greyscale;
  font-size: 9px;
  top: 20px;
  right: 8px;
}
