/*------------------------------------*\
	#Footer video
\*------------------------------------*/
.footer-video__link {
  position: relative;
  display: block;
  margin: 0 0 30px;
}
.footer-video__link:before {
  position: absolute;
  content: '';
  width: 70px;
  height: 49px;
  margin: -25px 0 0 -35px;
  top: 50%;
  left: 50%;
  background: url(../images/footer-video-play.png);
  background-image: url(../images/footer-video-play.svg), none;
}
