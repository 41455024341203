﻿/*------------------------------------*\
	#Slide
\*------------------------------------*/

@import (reference) "../default.less";

.slide {
	text-align: center;
	padding: 0 0 0;
	margin: 0 0 30px;
	overflow: hidden;

	p {
		font: 300 18px/27px @font-text;
		letter-spacing: 0.02em;
		padding: 0 20px;
		margin: 0 0 22px;
	}
}

.slide__title {
	font: 100 32px/36px @font-text;
	letter-spacing: 0.02em;
	padding: 0 20px;
	margin: 25px 0;
}

.slide__link {
	position: relative;
	display: block;
	color: @color-primary;
	font: 18px/36px @font-text;
	letter-spacing: 0.02em;
	text-decoration: none;

	&:after {
		position: relative;
		.icon(@icon-caret-right);
		font-size: 7px;
		top: -2px;
		left: 4px;
	}
}

.slide__image {
	position: relative;
	min-width: 416px;
	width: 100%;
	height: auto;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
}
