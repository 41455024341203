/*------------------------------------*\
	#Form
\*------------------------------------*/
.form {
  padding: 4px 0 14px;
}
.form label {
  float: left;
  display: block;
  width: 100%;
}
@media only screen and (min-width: 640px) {
  .form label {
    width: 227px;
  }
}
.form .radio,
.form input,
.form textarea,
.form .select-wrapper {
  float: left;
  width: 100%;
}
@media only screen and (min-width: 640px) {
  .form .radio,
  .form input,
  .form textarea,
  .form .select-wrapper {
    width: calc(100% - 227px);
  }
}
.form__control.is-checkbox {
  display: flex;
  align-items: center;
}
.form__control.is-checkbox input {
  width: auto;
  margin: 0 20px 0 0;
}
@media only screen and (min-width: 640px) {
  .form__control.is-checkbox input {
    width: auto;
  }
}
.form__control.is-checkbox label {
  width: auto;
}
.form__control {
  margin: 0 0 10px;
}
.form__control--button {
  text-align: right;
}
.form--product {
  /*
	 * For modern browsers
	 * 1. The space content is one way to avoid an Opera bug when the
	 *    contenteditable attribute is included anywhere else in the document.
	 *    Otherwise it causes space to appear at the top and bottom of elements
	 *    that are clearfixed.
	 * 2. The use of `table` rather than `block` is only necessary if using
	 *    `:before` to contain the top-margins of child elements.
	 */
  *zoom: 1;
}
.form--product:before,
.form--product:after {
  content: " ";
  display: table;
}
.form--product:after {
  clear: both;
}
.form__content {
  overflow: hidden;
}
