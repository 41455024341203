/*------------------------------------*\
	#Anchor
\*------------------------------------*/
.anchor {
  display: block;
  position: relative;
  visibility: hidden;
}
@media only screen and (min-width: 1024px) {
  .anchor {
    top: -140px;
  }
}
