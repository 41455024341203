/*------------------------------------*\
	#Image
\*------------------------------------*/
.image--big {
  margin: 7px 0 33px;
}
.image--right {
  margin: 7px 0 22px 0;
  /*@media @tablet {
		float: none;
		max-width: 100%;
		margin: 7px 0 22px 0;
	}*/
}
@media only screen and (min-width: 640px) {
  .image--right {
    float: right;
    max-width: 275px;
    margin: 7px 0 22px 30px;
  }
}
@media only screen and (min-width: 1024px) {
  .image--right {
    float: right;
    max-width: 275px;
    margin: 7px 0 22px 30px;
  }
}
@media only screen and (min-width: 1280px) {
  .image--right {
    max-width: 416px;
  }
}
