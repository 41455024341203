/*------------------------------------*\
	#News archive 
\*------------------------------------*/
.news-archive {
  padding: 0;
  margin: -3px 0 22px;
  list-style: none;
}
.news-archive__item {
  border-top: 1px solid rgba(51, 51, 51, 0.12);
}
.news-archive__item:last-child {
  border-bottom: 1px solid rgba(51, 51, 51, 0.12);
}
.news-archive__link {
  position: relative;
  display: block;
  padding: 10px 20px 11px 38px;
  text-decoration: none;
}
.news-archive__link:before {
  position: absolute;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  font-family: 'Icons';
  line-height: 1;
  text-transform: none;
  speak: none;
  content: '\e90b';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: greyscale;
  font-size: 9px;
  top: 20px;
  left: 18px;
}
