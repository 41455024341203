﻿/*------------------------------------*\
	#Vacancy
\*------------------------------------*/

@import (reference) "../default.less";

.vacancies {
	margin: 0 0 -18px 0px;
}

.vacancy  {
	display: block;
	text-decoration: none;
	border-bottom: 10px solid @color-background-dark;
	margin: 9px 0 40px;
	background: @color-background url(../images/grey-watermark.png) no-repeat center right -140px;
	background-size: 566px 566px;
	color: @color-text;
	transition: background 0.2s ease-in-out;

	&:hover {
		background-color: darken(@color-background, 10%);

		.vacancy__action-text:after {
			left: 13px;
		}

		.vacancy__action-text {
			color: @color-text;
		}
	}
}

.vacancy__container {
	padding: 40px 20px 25px;
	
	@media @mobile-large {
		padding: 40px 39px 25px;
	}
}

.vacancy__title {
	font: 100 20px/38px @font-text;
	letter-spacing: 0; 
	text-transform: uppercase;
	margin: 0;

	@media @mobile-large {
		font-size: 28px;
	}

	@media @tablet-small {
		font-size: 38px;
		line-height: 44px;
	}
}

.vacancy__action-text {
	font: 100 20px/37px @font-text;
	letter-spacing: 0.02em;

	&:after {
		position: relative;
		.icon(@icon-caret-thin-right);
		font-size: 8px;
		top:  -2px;
		left: 10px;
		transition: left 0.2s ease-in-out;
		
		@media @mobile-large {
			font-size: 13px;
			left: 17px;
		}

	}

	@media @mobile-large {
		font-size: 28px;
	}
}