﻿/*------------------------------------*\
	#Table
\*------------------------------------*/

@import (reference) "../default.less";

.text,
.tabs {

	table {
		width: 100%;

		th {
			font: 500 17px/1.2em @font-text;
			color: @color-white;
			background: @color-primary;
			text-align: left;
			padding: 16px 20px 14px;

			+ th {
				border-left: 1px solid @color-white;
			}
		}


		tr:nth-child(even) td {
			background: @color-background-dark;
		}

		td {
			font: 300 17px/1.2em @font-text;
			padding: 16px 20px 14px;

			+ td {
				border-left: 1px solid @color-white;
			}
		}
	}
}