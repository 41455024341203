﻿/*------------------------------------*\
	#Legal
\*------------------------------------*/

@import (reference) "../default.less";

.legal {
	position: relative;
	font: 12px/24px Arial, Helvetica Neue, Helvetica, sans-serif;
	overflow: hidden;
	padding: 13px 0 12px;
	color: fade(@color-white, 40%);

	ul {
		list-style: none;
		padding: 0;
		margin: 0;
		overflow: hidden;

		li {
			float: left;
			margin-right: 8px;
		}

		a {
			.text-decoration-hover;

			&:hover {
				color: fade(@color-white, 60%);
			}
		}
	}

	.panorama-studios{

		a {
			color: fade(@color-white, 50%);
			text-decoration: none;

			&:hover {
				text-decoration: underline;
			}
		}
	}

	@media @tablet-small {
		
		.panorama-studios {
			float: right;
			margin: 0;
		}
	}
}