﻿/*------------------------------------*\
	#Fonts
\*------------------------------------*/

@font-face {
	font-family: 'Helvetica Neue';
	src: url('webfonts/m Th.eot');
	src: url('webfonts/m Th.eot?#iefix') format('embedded-opentype'),
		 url('webfonts/m Th.woff2') format('woff2'),
		 url('webfonts/m Th.woff') format('woff'),
		 url('webfonts/m Th.svg') format('svg');
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: 'Helvetica Neue';
	src: url('webfonts/m Lt.eot');
	src: url('webfonts/m Lt.eot?#iefix') format('embedded-opentype'),
		 url('webfonts/m Lt.woff2') format('woff2'),
		 url('webfonts/m Lt.woff') format('woff'),
		 url('webfonts/m Lt.svg') format('svg');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Helvetica Neue';
	src: url('webfonts/m Roman.eot');
	src: url('webfonts/m Roman.eot?#iefix') format('embedded-opentype'),
		 url('webfonts/m Roman.woff2') format('woff2'),
		 url('webfonts/m Roman.woff') format('woff'),
		 url('webfonts/m Roman.svg') format('svg');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Helvetica Neue';
	src: url('webfonts/m Md.eot');
	src: url('webfonts/m Md.eot?#iefix') format('embedded-opentype'),
		 url('webfonts/m Md.woff2') format('woff2'),
		 url('webfonts/m Md.woff') format('woff'),
		 url('webfonts/m Md.svg') format('svg');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Helvetica Neue';
	src: url('webfonts/m Bd.eot');
	src: url('webfonts/m Bd.eot?#iefix') format('embedded-opentype'),
		 url('webfonts/m Bd.woff2') format('woff2'),
		 url('webfonts/m Bd.woff') format('woff'),
		 url('webfonts/m Bd.svg') format('svg');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Helvetica Neue Cyr';
	src: url('webfonts/HelveticaNeueCyr-Thin.eot');
	src: url('webfonts/HelveticaNeueCyr-Thin.eot?#iefix') format('embedded-opentype'), url('webfonts/HelveticaNeueCyr-Thin.woff2') format('woff2'), url('webfonts/HelveticaNeueCyr-Thin.woff') format('woff'), url('webfonts/HelveticaNeueCyr-Thin.svg') format('svg');
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: 'Helvetica Neue Cyr';
	src: url('webfonts/HelveticaNeueCyr-Light.eot');
	src: url('webfonts/HelveticaNeueCyr-Light.eot?#iefix') format('embedded-opentype'), url('webfonts/HelveticaNeueCyr-Light.woff2') format('woff2'), url('webfonts/HelveticaNeueCyr-Light.woff') format('woff'), url('webfonts/HelveticaNeueCyr-Light.svg') format('svg');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Helvetica Neue Cyr';
	src: url('webfonts/HelveticaNeueCyr-Roman.eot');
	src: url('webfonts/HelveticaNeueCyr-Roman.eot?#iefix') format('embedded-opentype'), url('webfonts/HelveticaNeueCyr-Roman.woff2') format('woff2'), url('webfonts/HelveticaNeueCyr-Roman.woff') format('woff'), url('webfonts/HelveticaNeueCyr-Roman.svg') format('svg');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Helvetica Neue Cyr';
	src: url('webfonts/HelveticaNeueCyr-Medium.eot');
	src: url('webfonts/HelveticaNeueCyr-Medium.eot?#iefix') format('embedded-opentype'), url('webfonts/HelveticaNeueCyr-Medium.woff2') format('woff2'), url('webfonts/HelveticaNeueCyr-Medium.woff') format('woff'), url('webfonts/HelveticaNeueCyr-Medium.svg') format('svg');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Helvetica Neue Cyr';
	src: url('webfonts/HelveticaNeueCyr-Bold.eot');
	src: url('webfonts/HelveticaNeueCyr-Bold.eot?#iefix') format('embedded-opentype'), url('webfonts/HelveticaNeueCyr-Bold.woff2') format('woff2'), url('webfonts/HelveticaNeueCyr-Bold.woff') format('woff'), url('webfonts/HelveticaNeueCyr-Bold.svg') format('svg');
	font-weight: 700;
	font-style: normal;
}

// Icons
@font-face {
	font-family: 'Icons';	
	src: url('webfonts/icons.eot?-a707vd');
	src: url('webfonts/icons.eot?#iefix-a707vd') format('embedded-opentype'),
		 url('webfonts/icons.woff?-a707vd') format('woff'),
		 url('webfonts/icons.ttf?-a707vd') format('truetype'),
		 url('webfonts/icons.svg?-a707vd#icons') format('svg');
	font-weight: normal;
	font-style: normal;
}

@icon-navicon: '\e910';
@icon-close: '\e90f';
@icon-caret-down: '\e90c';
@icon-caret-up: '\e90e';
@icon-caret-left: '\e90d';
@icon-caret-right: '\e90b';
@icon-caret-light-down: '\e911';
@icon-caret-light-up: '\e913';
@icon-caret-light-left: '\e912';
@icon-caret-light-right: '\e900';
@icon-caret-thin-right: '\e914';
@icon-caret-thin-down: '\e915';
@icon-caret-thin-up: '\e916';
@icon-caret-thin-left: '\e918';
@icon-caret-bold-right: '\e91e';
@icon-caret-bold-down: '\e91f';
@icon-caret-bold-up: '\e920';
@icon-caret-bold-left: '\e921';
@icon-twitter: '\e907';
@icon-facebook: '\e909';
@icon-google-plus: '\e90a';
@icon-linkedin: '\e902';
@icon-youtube: '\e906';
@icon-instagram: '\e908';
@icon-flickr: '\e904';
@icon-soundcloud: '\e903';
@icon-pinterest: '\e901';
@icon-vimeo: '\e905';
@icon-external-link: '\e900';
@icon-phone: '\e917';
@icon-mail: '\e919';
@icon-house: '\e91a';
@icon-search: '\e91b';
@icon-plus: '\e91c';
@icon-pointer: '\e91d';
@icon-three-sixyt: '\e922';
@icon-filter: '\e923';
@icon-swipe: '\e924';