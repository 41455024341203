/*------------------------------------*\
	#Project frontpage
\*------------------------------------*/
.project-frontpage {
  position: relative;
  background: #faf7f2 url(../images/grey-watermark.png) no-repeat center left -520px;
  border-bottom: 10px solid #eeebe3;
  margin-bottom: 40px;
}
@media only screen and (min-width: 1024px) {
  .project-frontpage {
    background-position: center left -420px;
  }
}
.project-frontpage--horticulture {
  border-bottom-color: #77b039;
}
.project-frontpage--agricultural {
  border-bottom-color: #ffb400;
}
.project-frontpage__text-container {
  position: relative;
  width: 100%;
  padding: 33px 20px 46px;
}
.project-frontpage__text-container:after {
  position: absolute;
  z-index: 1;
  content: '';
  width: 100%;
  height: 180px;
  bottom: -180px;
  left: 0;
  background: linear-gradient(to bottom, #faf7f2 0%, #faf7f2 18%, rgba(250, 247, 242, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#faf7f2', endColorstr='#00faf7f2', GradientType=1);
}
@media only screen and (min-width: 640px) {
  .project-frontpage__text-container:after {
    width: 373px;
    height: 100%;
    top: 0;
    right: -373px;
    bottom: auto;
    left: auto;
    background: linear-gradient(to right, #faf7f2 0%, #faf7f2 18%, rgba(250, 247, 242, 0) 100%);
  }
}
@media only screen and (min-width: 640px) {
  .project-frontpage__text-container {
    width: 65%;
    padding: 133px 40px 146px;
  }
}
@media only screen and (min-width: 768px) {
  .project-frontpage__text-container {
    width: 50%;
    padding: 133px 40px 146px;
  }
}
.project-frontpage__title-prefix {
  font: 300 28px/44px "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  letter-spacing: 0.02em;
}
.project-frontpage__title {
  font: 300 38px/44px "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  margin: 0 0 34px;
}
.project-frontpage__image {
  width: 100%;
  height: 250px;
  background-size: cover;
  background-position: center;
}
@media only screen and (min-width: 640px) {
  .project-frontpage__image {
    position: absolute;
    width: 35%;
    height: 100%;
    top: 0;
    right: 0;
  }
}
@media only screen and (min-width: 768px) {
  .project-frontpage__image {
    width: 50%;
  }
}
.project-frontpage__more-projects {
  position: absolute;
  padding: 0 30px 0 0;
  right: 0;
  bottom: -45px;
  font: 500 15px/1.2em "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  text-transform: uppercase;
  text-decoration: none;
}
.project-frontpage__more-projects:after {
  position: absolute;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  font-family: 'Icons';
  line-height: 1;
  text-transform: none;
  speak: none;
  content: '\e90b';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: greyscale;
  font-size: 9px;
  top: 5px;
  right: 0;
  transition: right 0.2s ease-in-out;
}
.project-frontpage__more-projects:hover:after {
  right: -2px;
}
