﻿/*------------------------------------*\
	#Container
\*------------------------------------*/

@import (reference) "../default.less";

.container {
	.clearfix;
	width: 100%;
	max-width: 1368px;
	padding: 0 20px;
	margin: 0 auto;
 }

 .container--relative {
	position: relative;
	height: 100%;
 }

 .container--content-wrapper {
	display: flex;
	flex-direction: column;
	padding: 0;

	@media @laptop {
		display: block;
		padding: 0 20px;
	}
 }

 .container--intro-block-frontpage {
	position: relative;
	z-index: 1; 
	max-width: 1408px;
	padding: 40px 20px 0;
	background: @color-white;

	@media @laptop { 
		padding: 40px 40px 0;
		margin-top: -261px;
	}
 }