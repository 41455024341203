/*------------------------------------*\
	#Languages
\*------------------------------------*/
.languages {
  white-space: nowrap;
  width: 100%;
  margin: 0;
  padding: 0 15px;
  top: 0;
  right: 0;
  z-index: 7;
}
.languages dt {
  position: relative;
  display: none;
  overflow: hidden;
  width: 43px;
  height: 41px;
  padding: 6px 0 8px 53px;
  cursor: default;
  text-indent: 9999px;
}
.languages dt:before {
  content: '';
  position: absolute;
  width: 21px;
  height: 21px;
  margin: 0;
  top: 14px;
  left: 10px;
  background: url(../images/languages.png) no-repeat top center;
  background-size: cover;
}
.languages dt.nl:before {
  background-position: 0 0;
}
.languages dt.en:before {
  background-position: 0 -21px;
}
.languages dt.de:before {
  background-position: 0 -42px;
}
.languages dt.ru:before {
  background-position: 0 -63px;
}
.languages dt.es:before {
  background-position: 0 -84px;
}
.languages dt.pl:before {
  background-position: 0 -106px;
}
.languages dt:after {
  position: absolute;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  font-family: 'Icons';
  line-height: 1;
  text-transform: none;
  speak: none;
  content: '\e90c';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: greyscale;
  top: 20px;
  right: 9px;
  font-size: 7px;
  text-indent: 0;
}
@media only screen and (min-width: 1024px) {
  .languages dt {
    display: block;
  }
}
.languages dd {
  min-width: 100%;
  top: 40px;
  right: 0;
  z-index: 3;
}
.languages dd ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.languages dd ul li {
  position: relative;
}
.languages dd ul li:first-of-type a {
  padding-top: 5px;
}
.languages dd ul li:first-of-type a:before {
  top: 15px;
}
.languages dd ul li:last-of-type a {
  padding-bottom: 7px;
}
.languages dd ul li:last-of-type a:before {
  top: 11px;
}
.languages dd ul li a {
  font: 300 15px/30px "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  color: #fff;
  text-decoration: none;
  display: block;
  padding: 0 15px 0 53px;
  opacity: 1;
  position: relative;
}
.languages dd ul li a:before {
  content: '';
  position: absolute;
  width: 21px;
  height: 13px;
  margin: 0;
  top: 10px;
  left: 10px;
  background: url(../images/languages.png) no-repeat top center;
  background-size: cover;
  border: 1px solid rgba(255, 255, 255, 0.6);
}
@media only screen and (min-width: 1024px) {
  .languages dd ul li a {
    color: #333333;
  }
}
.languages dd ul li:hover a {
  text-decoration: underline;
}
.languages dd ul li.nl a:before {
  background-position: 0 -4px;
}
.languages dd ul li.en a:before {
  background-position: 0 -23px;
}
.languages dd ul li.de a:before {
  background-position: 0 -42px;
}
.languages dd ul li.ru a:before {
  background-position: 0 -62px;
}
.languages dd ul li.es a:before {
  background-position: 0 -80px;
}
.languages dd ul li.pl a:before {
  background-position: 0 -100px;
}
@media only screen and (min-width: 1024px) {
  .languages dd ul {
    background: #faf7f2;
  }
}
@media only screen and (min-width: 1024px) {
  .languages dd {
    position: absolute;
    visibility: hidden;
  }
}
.languages.open dt:after,
.languages:hover dt:after {
  z-index: 4;
}
.languages.open dd,
.languages:hover dd {
  visibility: visible;
}
@media only screen and (min-width: 1024px) {
  .languages.open,
  .languages:hover {
    background: #faf7f2;
  }
}
@media only screen and (min-width: 1024px) {
  .languages {
    position: absolute;
    max-width: 50px;
    height: 53px;
    padding: 0;
    background: none;
  }
}
