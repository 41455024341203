﻿/*------------------------------------*\
	#Aside
\*------------------------------------*/

@import (reference) "../default.less";

.contact-person {
	width: 100%;
	margin: 0 0 40px;

	@media @mobile-large {
	}

	@media @laptop {
		float: right;
		width: 342px;
		padding: 0 0 0 40px;
		margin: 0 0 8px;

		+ .content {
			float: left;
			width: calc(~"100% - 342px");
		}
	}
}

.contact-person__content {
	padding: 33px 40px 33px;
	border-bottom: 10px solid @color-background-dark;
	font: 300 20px/28px @font-text;
	text-align: center;
	background: @color-background;

	strong {
		font-weight: 500;
	}
}

.contact-person__title {
	margin: 0 0 22px;
	letter-spacing: 0.02em;
	font-weight: 300;
}

.contact-person__image {
	max-width: 150px;
	border-radius: 50%;
	margin: 0 auto 24px;
}

.contact-person__name {
	display: block;
	font-weight: 500;
	margin: 0 0 13px;
}

.contact-person__button {
	margin: 0 0 14px;
}

.contact-person__call {
	display: block;
}