﻿/*------------------------------------*\
	#project-intro 
\*------------------------------------*/

@import (reference) "../default.less";

.project-intro {
	.clearfix;
	display: flex;
	flex-direction: column;

	@media @laptop {
		display: block;
		flex-direction: row;
	}
}