/*------------------------------------*\
	#Slide
\*------------------------------------*/
.slide {
  text-align: center;
  padding: 0 0 0;
  margin: 0 0 30px;
  overflow: hidden;
}
.slide p {
  font: 300 18px/27px "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  letter-spacing: 0.02em;
  padding: 0 20px;
  margin: 0 0 22px;
}
.slide__title {
  font: 100 32px/36px "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  letter-spacing: 0.02em;
  padding: 0 20px;
  margin: 25px 0;
}
.slide__link {
  position: relative;
  display: block;
  color: #ec1d25;
  font: 18px/36px "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  letter-spacing: 0.02em;
  text-decoration: none;
}
.slide__link:after {
  position: relative;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  font-family: 'Icons';
  line-height: 1;
  text-transform: none;
  speak: none;
  content: '\e90b';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: greyscale;
  font-size: 7px;
  top: -2px;
  left: 4px;
}
.slide__image {
  position: relative;
  min-width: 416px;
  width: 100%;
  height: auto;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
