﻿/*------------------------------------*\
	#Text
\*------------------------------------*/

@import (reference) "../default.less";

.text {
	overflow: hidden;

	p {
		&:last-child {
			margin: 0 0 13px
		}
	}

	ul,
	ol {
		list-style: none;
		overflow: hidden;
		padding: 0;
		margin: -8px 0 33px;

		li {
			position: relative;
			border-top: 1px solid fade(@color-text, 12%);
			padding: 12px 20px 9px 45px;

			&:before {
				position: absolute;
				.icon(@icon-caret-right);
				font-size: 9px;
				top:  22px;
				left: 18px;
			}

			&:last-child {
				border-bottom: 1px solid fade(@color-text, 12%);
			}

			ul { 
				margin: 9px 0 -11px !important;

				li {
					width: 100%;
					
					&:last-child {
						border-bottom: 0;
					}
				}
			}
		}

		&:last-child {
			margin: -8px 0 22px
		}
	}

	table {
		width: 100%;

		th {
			font: 500 17px/1.2em @font-text;
			color: @color-white;
			background: @color-primary;
			text-align: left;
			padding: 16px 20px 14px;

			+ th {
				border-left: 1px solid @color-white;
			}
		}


		tr:nth-child(even) td {
			background: @color-background-dark;
		}

		td {
			font: 300 17px/1.2em @font-text;
			padding: 16px 20px 14px;

			+ td {
				border-left: 1px solid @color-white;
			}
		}
	}
}