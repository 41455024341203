/*------------------------------------*\
	#Employees
\*------------------------------------*/
.employees {
  padding: 9px 0 0;
  margin-bottom: -18px;
}
.employee {
  margin: 0 0 40px;
}
.employee__image-placeholder {
  position: relative;
  overflow: hidden;
  height: 0;
  padding: 0 0 66.66666667%;
  background: url(../images/placeholder.jpg) no-repeat center;
  background-image: url(../images/placeholder.svg), none;
  background-size: cover;
}
.employee__description {
  padding: 34px 0 0;
  border-bottom: 10px solid #eeebe3;
  background: #faf7f2;
}
.employee__name {
  font: 300 20px/28px "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  letter-spacing: 0.02em;
  padding: 0 40px;
  margin: 0 0 32px;
}
.employee__job-title {
  font: 300 17px/28px "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  padding: 0 40px;
  margin: -31px 0 32px;
}
.employee__notes {
  padding: 10px 40px 12px;
  border-top: 1px solid rgba(51, 51, 51, 0.12);
}
.employee__contact-detail {
  padding: 10px 40px 12px;
  border-top: 1px solid rgba(51, 51, 51, 0.12);
}
.employee__email,
.employee__telephone {
  position: relative;
  padding: 0 0 0 44px;
}
.employee__email:before,
.employee__telephone:before {
  position: absolute;
  font-size: 20px;
  top: 0;
  left: 6px;
}
.employee__telephone:before {
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  font-family: 'Icons';
  line-height: 1;
  text-transform: none;
  speak: none;
  content: '\e917';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: greyscale;
}
.employee__email:before {
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  font-family: 'Icons';
  line-height: 1;
  text-transform: none;
  speak: none;
  content: '\e919';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: greyscale;
}
