﻿/*------------------------------------*\
	#Footer
\*------------------------------------*/

@import (reference) "../default.less";

footer {
	padding: 80px 0 0;
	background: @color-black url(../images/footer-background.jpg) no-repeat center top;
	background-size: cover;
}