﻿/*------------------------------------*\
	#Navigation top
\*------------------------------------*/

@import (reference) "../default.less";

.navigation-top {

	ul {
		padding: 10px 3px 12px;
		margin:  0;
		text-align: left;

		ul {
			padding: 0 0 0 20px;
		}

		@media @laptop {
			text-align: right;
			padding: 10px 40px 12px 246px;

			ul {
				display: none;
			}
		}
	}

	li {
		display: block;

		&.active a {
			color: @color-background-dark;
		}

		@media @laptop {
			display: inline-block;

			&.active a {
				color: @color-primary;
			}
		}
	}

	a {
		text-decoration: none;
		font: 400 14px/1.2em @font-text;
		padding: 0 17px;
		color: @color-white;
		
		&:hover {
			color: @color-background-dark;
		}

		@media @laptop {
			color: mix(@color-white, @color-text, 50%);

			&:hover {
				color: @color-primary;
			}
		}
	}

	@media @laptop {
		height: 50px;
	}
}