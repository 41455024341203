/*------------------------------------*\
	#Section
\*------------------------------------*/
section {
  position: relative;
  padding: 72px 20px 58px;
  border-bottom: 1px solid rgba(51, 51, 51, 0.12);
}
section:after {
  position: absolute;
  content: '';
  width: 1280px;
  height: 1px;
  right: 100%;
  bottom: -1px;
  background: rgba(51, 51, 51, 0.12);
}
section:first-child,
.product-intro section {
  background: #faf7f2;
  border-bottom: none;
  padding-right: 40px;
}
section:first-child:before,
.product-intro section:before {
  position: absolute;
  content: '';
  width: 1280px;
  height: 100%;
  top: 0;
  right: 100%;
  background: #faf7f2;
}
section:first-child:after,
.product-intro section:after {
  display: none;
}
section:last-child {
  border-bottom: none;
}
section:last-child:after {
  display: none;
}
.frontpage section {
  padding: 32px 0 40px;
  border: none;
}
.frontpage section:after {
  display: none;
}
section.small {
  padding: 72px 20px 28px;
  border-bottom: none;
}
section.small:after {
  display: none;
}
@media only screen and (min-width: 1024px) {
  section.small {
    padding: 72px 0 28px;
  }
}
@media only screen and (min-width: 1024px) {
  section {
    padding: 72px 0 58px;
  }
}
.section--contact:first-child {
  padding: 0;
  border-bottom: 10px solid #eeebe3;
}
.section--contact:first-child:after {
  position: absolute;
  display: block;
  content: '';
  width: 1280px;
  height: 10px;
  bottom: -10px;
  right: 100%;
  background: #eeebe3;
}
.frontpage  .section--news {
  padding-bottom: 0;
}
