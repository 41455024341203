/*------------------------------------*\
	#Container
\*------------------------------------*/
.container {
  /*
	 * For modern browsers
	 * 1. The space content is one way to avoid an Opera bug when the
	 *    contenteditable attribute is included anywhere else in the document.
	 *    Otherwise it causes space to appear at the top and bottom of elements
	 *    that are clearfixed.
	 * 2. The use of `table` rather than `block` is only necessary if using
	 *    `:before` to contain the top-margins of child elements.
	 */
  *zoom: 1;
  width: 100%;
  max-width: 1368px;
  padding: 0 20px;
  margin: 0 auto;
}
.container:before,
.container:after {
  content: " ";
  display: table;
}
.container:after {
  clear: both;
}
.container--relative {
  position: relative;
  height: 100%;
}
.container--content-wrapper {
  display: flex;
  flex-direction: column;
  padding: 0;
}
@media only screen and (min-width: 1024px) {
  .container--content-wrapper {
    display: block;
    padding: 0 20px;
  }
}
.container--intro-block-frontpage {
  position: relative;
  z-index: 1;
  max-width: 1408px;
  padding: 40px 20px 0;
  background: #fff;
}
@media only screen and (min-width: 1024px) {
  .container--intro-block-frontpage {
    padding: 40px 40px 0;
    margin-top: -261px;
  }
}
