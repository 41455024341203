﻿/*------------------------------------*\
	#Contact intro
\*------------------------------------*/

@import (reference) "../default.less";

.contact-intro {
	padding: 72px 20px 45px;

	table {
		margin: 0 0 27px;
		 
		td:first-child {
			width: 108px;
		}
	}
}