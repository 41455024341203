﻿/*------------------------------------*\
	#static call to action
\*------------------------------------*/

@import (reference) "../default.less";

.static-call-to-action {
	.clearfix;
	background: @color-primary url(../images/red-watermark.png) no-repeat center;
	background-size: 678px 678px;
	padding: 36px 20px 33px;
	margin: 40px 0 40px;
	text-align: center;

	@media @mobile-large {
		padding: 36px 40px 33px;
	}
}

.static-call-to-action__title {
	width: 100%;
	padding: 0;
	margin: 0 0 17px;
	font: 100 27px/28px @font-text;
	letter-spacing: 0.02em;
	color: @color-white;
	text-align: center;

	strong {
		font-weight: 500;
	}

	p {
		margin: 0;
	}
}

.static-call-to-action__text {
	display: block;
	font: 300 20px/28px @font-text;
	letter-spacing: 0.02em;
	color: @color-white;

	a {
		font-weight: 500;

		&:hover {
			color: @color-background-dark;
		}
	}
}

.static-call-to-action__button {
	margin: 0 0 13px;

	&:after {
		.icon(@icon-caret-down);
		margin: -4px 0 0;
		right: 20px;
	}

	&:hover:after {
		margin: -2px 0 0;
		right: 20px;
	}
}