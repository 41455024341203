/*------------------------------------*\
	#Contact intro
\*------------------------------------*/
.contact-intro {
  padding: 72px 20px 45px;
}
.contact-intro table {
  margin: 0 0 27px;
}
.contact-intro table td:first-child {
  width: 108px;
}
