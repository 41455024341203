/*------------------------------------*\
	#Message
\*------------------------------------*/
.messages {
  padding: 9px 0 0;
  margin-bottom: -19px;
}
.frontpage .messages {
  margin-bottom: -40px;
}
.message {
  margin-bottom: 40px;
}
.frontpage .message:nth-child(n + 3) {
  display: none;
}
@media only screen and (min-width: 1024px) {
  .frontpage .message:nth-child(n + 3) {
    display: block;
  }
}
.message__link {
  text-decoration: none;
}
.message__link:hover {
  color: #000;
}
.message__link:hover .message__title {
  background: #eeebe3;
}
.message__link:hover .message__title:after {
  left: 8px;
}
.message__title {
  font: 300 20px/28px "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  padding: 34px 40px 33px;
  letter-spacing: 0.02em;
  border-bottom: 10px solid #eeebe3;
  background: #faf7f2;
  transition: background 0.2s ease-in-out;
}
.message__title:after {
  position: relative;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  font-family: 'Icons';
  line-height: 1;
  text-transform: none;
  speak: none;
  content: '\e900';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: greyscale;
  font-size: 9px;
  top: -1px;
  left: 6px;
  transition: left 0.2s ease-in-out;
}
.message__title--horticulture {
  border-bottom-color: #77b039;
}
.message__title--agricultural {
  border-bottom-color: #ffb400;
}
.message__placeholder {
  position: relative;
  overflow: hidden;
  height: 0;
  padding: 0 0 66.66666667%;
  background: url(../images/placeholder.jpg) no-repeat center;
  background-image: url(../images/placeholder.svg), none;
  background-size: cover;
}
