/*------------------------------------*\
	#Social media
\*------------------------------------*/
.social-media,
.social-media ul {
  position: relative;
  z-index: 1;
  overflow: hidden;
  list-style: none;
  width: auto;
  padding: 0;
  margin: 42px auto 8px;
  text-align: center;
}
.social-media li,
.social-media ul li {
  position: relative;
  display: inline-block;
  margin: 0 5px;
  color: #fff;
}
.social-media li a,
.social-media ul li a {
  box-sizing: border-box;
  display: block;
  overflow: hidden;
  text-decoration: none;
  padding: 0;
  width: 50px;
  height: 50px;
  text-indent: -9999px;
}
.social-media li a:after,
.social-media ul li a:after {
  box-sizing: border-box;
  font-size: 26px;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  transform: translate(-50%, -50%);
  text-indent: 0;
}
.social-media li:hover a,
.social-media ul li:hover a {
  color: #ec1d25;
}
.social-media li.facebook a:after,
.social-media ul li.facebook a:after {
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  font-family: 'Icons';
  line-height: 1;
  text-transform: none;
  speak: none;
  content: '\e909';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: greyscale;
}
.social-media li.linkedin a:after,
.social-media ul li.linkedin a:after {
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  font-family: 'Icons';
  line-height: 1;
  text-transform: none;
  speak: none;
  content: '\e902';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: greyscale;
}
.social-media li.twitter a:after,
.social-media ul li.twitter a:after {
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  font-family: 'Icons';
  line-height: 1;
  text-transform: none;
  speak: none;
  content: '\e907';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: greyscale;
}
.social-media li.google-plus a:after,
.social-media ul li.google-plus a:after {
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  font-family: 'Icons';
  line-height: 1;
  text-transform: none;
  speak: none;
  content: '\e90a';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: greyscale;
}
.social-media li.youtube a:after,
.social-media ul li.youtube a:after {
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  font-family: 'Icons';
  line-height: 1;
  text-transform: none;
  speak: none;
  content: '\e906';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: greyscale;
}
.social-media li.soundcloud a:after,
.social-media ul li.soundcloud a:after {
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  font-family: 'Icons';
  line-height: 1;
  text-transform: none;
  speak: none;
  content: '\e903';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: greyscale;
}
.social-media li.vimeo a:after,
.social-media ul li.vimeo a:after {
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  font-family: 'Icons';
  line-height: 1;
  text-transform: none;
  speak: none;
  content: '\e905';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: greyscale;
}
.social-media li.instagram a:after,
.social-media ul li.instagram a:after {
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  font-family: 'Icons';
  line-height: 1;
  text-transform: none;
  speak: none;
  content: '\e908';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: greyscale;
}
.social-media li.flickr a:after,
.social-media ul li.flickr a:after {
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  font-family: 'Icons';
  line-height: 1;
  text-transform: none;
  speak: none;
  content: '\e904';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: greyscale;
}
.social-media li.pinterest a:after,
.social-media ul li.pinterest a:after {
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  font-family: 'Icons';
  line-height: 1;
  text-transform: none;
  speak: none;
  content: '\e901';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: greyscale;
}
