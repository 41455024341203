/*------------------------------------*\
	#Google maps
\*------------------------------------*/
.google-maps__wrapper {
  position: relative;
  height: 500px;
}
.google-maps__wrapper.dealer-maps {
  height: 500px;
}
@media only screen and (min-width: 1024px) {
  .google-maps__wrapper.dealer-maps {
    height: 1008px;
  }
}
.google-maps {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
}
.google-maps .canvas {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.google-maps .canvas .gmnoprint,
.google-maps .canvas .gm-style-cc {
  opacity: 0;
  transition: opacity 0.5s;
}
.google-maps .canvas:hover .gmnoprint,
.google-maps .canvas:hover .gm-style-cc {
  opacity: 1;
}
.google-maps img {
  visibility: visible;
  width: auto;
}
.maps-cluster {
  font-weight: 500;
  font-size: 15px;
  line-height: 40px;
  text-align: center;
  color: #333333;
  background: #fff;
  width: 48px;
  height: 48px;
  border: 5px solid #333333;
  border-radius: 100%;
}
.maps-cluster--agriculture {
  border-color: #ffb400;
}
.maps-cluster--horticulture {
  border-color: #77b039;
}
.maps-cluster--both {
  border-top-color: #77b039;
  border-right-color: #77b039;
  border-bottom-color: #ffb400;
  border-left-color: #ffb400;
}
.info-window__content {
  font: 300 15px/22px "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  padding: 7px 5px 4px;
}
.info-window__content h2 {
  font: 500 15px/22px "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  color: #ec1d25;
  margin: 0;
}
.info-window__content span {
  display: block;
}
.info-window__content a {
  display: block;
  color: #ec1d25;
  text-decoration: none;
}
.info-window__content a:hover {
  text-decoration: underline;
}
.info-window__content a[href^=tel] {
  display: inline;
  color: #333333;
}
