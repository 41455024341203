/*------------------------------------*\
	#Toggler
\*------------------------------------*/
.toggler {
  z-index: 11;
  position: fixed;
  width: 60px;
  height: 60px;
  top: 20px;
  right: 0;
  background: #ec1d25;
  border: 1px solid #ec1d25;
  text-indent: -9999px;
  color: #fff;
  cursor: pointer;
}
.toggler:after {
  position: absolute;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  font-family: 'Icons';
  line-height: 1;
  text-transform: none;
  speak: none;
  content: '\e90f';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: greyscale;
  font-size: 35px;
  text-indent: 0;
  top: 11px;
  left: 11px;
}
.toggler.toggler--closed:after {
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  font-family: 'Icons';
  line-height: 1;
  text-transform: none;
  speak: none;
  content: '\e910';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: greyscale;
}
@media only screen and (min-width: 1024px) {
  .toggler {
    display: none;
  }
}
