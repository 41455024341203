/*------------------------------------*\
	#Table
\*------------------------------------*/
.text table,
.tabs table {
  width: 100%;
}
.text table th,
.tabs table th {
  font: 500 17px/1.2em "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  color: #fff;
  background: #ec1d25;
  text-align: left;
  padding: 16px 20px 14px;
}
.text table th + th,
.tabs table th + th {
  border-left: 1px solid #fff;
}
.text table tr:nth-child(even) td,
.tabs table tr:nth-child(even) td {
  background: #eeebe3;
}
.text table td,
.tabs table td {
  font: 300 17px/1.2em "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  padding: 16px 20px 14px;
}
.text table td + td,
.tabs table td + td {
  border-left: 1px solid #fff;
}
