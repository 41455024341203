/*------------------------------------*\
	#specification
\*------------------------------------*/
.specification {
  /*
	 * For modern browsers
	 * 1. The space content is one way to avoid an Opera bug when the
	 *    contenteditable attribute is included anywhere else in the document.
	 *    Otherwise it causes space to appear at the top and bottom of elements
	 *    that are clearfixed.
	 * 2. The use of `table` rather than `block` is only necessary if using
	 *    `:before` to contain the top-margins of child elements.
	 */
  *zoom: 1;
  display: inline-block;
  padding: 7px 0 0;
  margin: 0 0 15px;
}
.specification:before,
.specification:after {
  content: " ";
  display: table;
}
.specification:after {
  clear: both;
}
.specification__row {
  border-top: 1px solid rgba(51, 51, 51, 0.12);
}
.specification__row:last-child {
  border-bottom: 1px solid rgba(51, 51, 51, 0.12);
}
.specification__name,
.specification__value {
  padding: 12px 20px 9px;
  display: block;
}
@media only screen and (min-width: 640px) {
  .specification__name,
  .specification__value {
    display: table-cell;
  }
}
.specification__name {
  max-width: 196px;
}
.specification__link {
  color: #ec1d25;
  text-decoration: none;
}
.specification__link:hover {
  color: #c51118;
}
