﻿/*------------------------------------*\
	#Assortment aside 
\*------------------------------------*/

@import (reference) "../default.less";

.aside-assortment {
	padding: 35px 20px 28px;
	margin: 0 0 40px;
	background: @color-background url(../images/grey-watermark.png) no-repeat center right -192px;
	background-size: 379px 379px;
	border-bottom: 10px solid @color-background-dark;

	@media @mobile-large {
		padding: 35px 40px 28px;
	}
}

.aside-assortment__title {
	font: 300 20px/28px @font-text;
	text-align: center;
	margin: 0 0 8px;
}

.aside-assortment__image {
	width:  222px;
	height: 149px;
	margin: 0 auto 21px;
	background: url(../images/aside-assortment-image.png);
}

.aside-assortment .aside-assortment__button {
	margin: 0 0 10px;
}