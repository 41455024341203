﻿/*------------------------------------*\
	#Band
\*------------------------------------*/

@import (reference) "../default.less";

.logo {
	.clearfix;
	position: absolute;
	z-index: 1;
	text-indent: -9999px;
	background: url(../images/logo-holland-heater-full-white.png) no-repeat;
	background-image: url(../images/logo-holland-heater-full-white.svg), none;
	background-size: cover;
	width:  200px;
	height: 122px;
	margin: 0;
	top:  8px;
	left: 20px;

	a {
		display: block;
		width: 100%;
		height: 100%;
	}

	img {
		display: none;
	}

	@media @laptop {
		width:  226px;
		height: 138px;
	}

	@media @laptop-large {
		left: -23px;
	}
}

.logo--footer {
	position: relative;
	width:  256px;
	height: 163px;
	margin: 0 auto 26px;
	top:  auto;
	left: auto;
	background: url(../images/logo-holland-heater-red.png) no-repeat top center;
	background-image: url(../images/logo-holland-heater-red.svg), none;

	a {
		position: relative;
		z-index: 1;

		&:before {
			position: absolute;
			content: '';
			width:  232px;
			height: 1px;
			margin: 0 0 0 -116px;
			bottom: -27px;
			left:   50%;
			background: fade(@color-white, 20%);
		}
	}

	&:before {
		position: absolute;
		content: '';
		width:  227px;
		height: 6px;
		margin: 0 0 0 -113.5px;
		bottom: 14px;
		left:   50%;
		background: url(../images/worldwide.png) no-repeat center;
		background-image: url(../images/worldwide.svg), none;
	}

	@media @laptop {
		width:  256px;
		height: 163px;
		margin: -34px auto 26px;
	}

	@media @laptop-large {
		left: auto;
	}
}