﻿/*------------------------------------*\
	#Section
\*------------------------------------*/

@import (reference) "../default.less";

section {
	position: relative;
	padding: 72px 20px 58px;
	border-bottom: 1px solid fade(@color-text, 12%);

	&:after {
		position: absolute;
		content: '';
		width: 1280px;
		height: 1px;
		right:  100%;
		bottom: -1px;
		background: fade(@color-text, 12%);
	}

	&:first-child,
	.product-intro & {
		background: @color-background;
		border-bottom: none;
		padding-right: 40px;

		&:before {
			position: absolute;
			content: '';
			width:  1280px;
			height: 100%;
			top:   0;
			right: 100%;
			background: @color-background;
		}

		&:after {
			display: none;
		}
	}

	&:last-child {
		border-bottom: none;

		&:after {
			display: none;
		}
	}

	.frontpage & {
		padding: 32px 0 40px;
		border: none;

		&:after {
			display: none;
		}
	}

	&.small {
		padding: 72px 20px 28px;
		border-bottom: none;

		&:after {
			display: none;
		}

		@media @laptop {
			padding: 72px 0 28px;
		}

	}

	@media @laptop {
		padding: 72px 0 58px;
	}
}

.section--contact:first-child  {
	padding: 0;
	border-bottom: 10px solid @color-background-dark;

	&:after {
		position: absolute;
		display: block;
		content: '';
		width:  1280px;
		height: 10px;
		bottom: -10px;
		right: 100%;
		background: @color-background-dark;
	}
}

.frontpage & {

	.section--news{
		padding-bottom: 0;
	}
} 