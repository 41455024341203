﻿/*------------------------------------*\
	#Message
\*------------------------------------*/

@import (reference) "../default.less";

.messages {
	padding: 9px 0 0;
	margin-bottom: -19px;

	.frontpage & {
		margin-bottom: -40px;
	}
}

.message {
	margin-bottom: 40px;

	.frontpage & {

		&:nth-child(n + 3) {
			display: none;

			@media @laptop {
				display: block;
			}
		}
	}
}

.message__link {
	text-decoration: none;

	&:hover {
		color: @color-black;

		.message__title {
			background: @color-background-dark;

			&:after {
				left: 8px;
			}
		}
	}
}

.message__title {
	font: 300 20px/28px @font-text;
	padding: 34px 40px 33px;
	letter-spacing: 0.02em;
	border-bottom: 10px solid @color-background-dark;
	background: @color-background;
	transition: background 0.2s ease-in-out;

	&:after {
		position: relative;
		.icon(@icon-caret-light-right);
		font-size: 9px;
		top:  -1px;
		left: 6px;
		transition: left 0.2s ease-in-out;
	}
}

.message__title--horticulture {
	border-bottom-color: @color-horticulture;
}

.message__title--agricultural {
	border-bottom-color: @color-agricultural;
}

.message__placeholder {
	.responsive-container(600, 400);
	background: url(../images/placeholder.jpg) no-repeat center;
	background-image: url(../images/placeholder.svg), none;
	background-size: cover;
}