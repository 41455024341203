﻿/*------------------------------------*\
	#Product intro
\*------------------------------------*/

@import (reference) "../default.less";

.product-intro {
	.clearfix;
}

.product-intro__right {
	position: relative;
	margin-bottom: 36px;

	@media @laptop {
		float: right;
		width: 570px;

		+ .product-intro__left {
			float: left;
			width: calc(~"100% - 570px");
		}

		&:before {
			position: absolute;
			content: '';
			width:  100%;
			height: 40px;
			top:  -40px;
			left: 0;
			background: @color-white;
		}
	}
}