/*------------------------------------*\
	#Legal
\*------------------------------------*/
.legal {
  position: relative;
  font: 12px/24px Arial, Helvetica Neue, Helvetica, sans-serif;
  overflow: hidden;
  padding: 13px 0 12px;
  color: rgba(255, 255, 255, 0.4);
}
.legal ul {
  list-style: none;
  padding: 0;
  margin: 0;
  overflow: hidden;
}
.legal ul li {
  float: left;
  margin-right: 8px;
}
.legal ul a {
  text-decoration: none;
}
.legal ul a:hover {
  text-decoration: underline;
}
.legal ul a:hover {
  color: rgba(255, 255, 255, 0.6);
}
.legal .panorama-studios a {
  color: rgba(255, 255, 255, 0.5);
  text-decoration: none;
}
.legal .panorama-studios a:hover {
  text-decoration: underline;
}
@media only screen and (min-width: 640px) {
  .legal .panorama-studios {
    float: right;
    margin: 0;
  }
}
