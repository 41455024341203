﻿.clearfix() {
	/*
	 * For modern browsers
	 * 1. The space content is one way to avoid an Opera bug when the
	 *    contenteditable attribute is included anywhere else in the document.
	 *    Otherwise it causes space to appear at the top and bottom of elements
	 *    that are clearfixed.
	 * 2. The use of `table` rather than `block` is only necessary if using
	 *    `:before` to contain the top-margins of child elements.
	 */
	&:before,
	&:after {
		content: " "; // 1
		display: table; // 2
	}

	&:after {
		clear: both;
	}

	// For IE 6/7 only: include this rule to trigger hasLayout and contain floats.
	*zoom: 1;
}

.placeholder(@styles) {
	&::-webkit-input-placeholder {
		// Webkit
		@styles();
	}
	&:-moz-placeholder {
		// FF 4-18
		opacity: 1;
		@styles();
	}
	&::-moz-placeholder {
		// FF 19+
		opacity: 1;
		@styles();
	}
	&:-ms-input-placeholder {
		// IE 10+
		@styles();
	}
	&::input-placeholder {
		@styles();
	}
}

.text-decoration-hover() {
	text-decoration: none;

	&:hover {
		text-decoration: underline;
	}
}

.responsive-container(@width, @height) {
	position: relative;
	overflow: hidden;
	height: 0;
	padding: 0 0 (100% * @height / @width);
}

// Icons
.icon(@content, @font-family: 'Icons') {
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	font-family: @font-family;
	line-height: 1;
	text-transform: none;
	speak: none;
	content: @content;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: greyscale; 
}