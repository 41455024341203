/*------------------------------------*\
	#Header
\*------------------------------------*/
header {
  position: relative;
  width: 100%;
  top: 0;
  z-index: 2;
}
@media only screen and (min-width: 1024px) {
  header {
    position: fixed;
    background: #ec1d25;
    height: auto;
  }
  header:before {
    position: absolute;
    content: '';
    width: 100%;
    height: 50px;
    background: #fff;
    top: 0;
    left: 0;
  }
}
