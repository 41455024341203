@font-face {
  font-family: 'lg';
  src: url('webfonts/lg.eot?n1z373');
  src: url('webfonts/lg.eot?#iefixn1z373') format('embedded-opentype'), url('webfonts/lg.woff?n1z373') format('woff'), url('webfonts/lg.ttf?n1z373') format('truetype'), url('webfonts/lg.svg?n1z373#lg') format('svg');
  font-weight: normal;
  font-style: normal;
}
.lg-outer .lg-item {
  background-image: url('../images/components/lightgallery/loading.gif');
}
.lg-outer .lg-image {
  background-color: #fff;
}
.lg-outer .lg-has-vimeo .lg-video-play,
.lg-outer .lg-has-vimeo:hover .lg-video-play {
  background-image: url('../images/components/lightgallery/vimeo-play.png');
}
.lg-outer .lg-has-html5 .lg-video-play {
  background-image: url('../images/components/lightgallery/video-play.png');
}
.lg-outer .lg-has-youtube .lg-video-play,
.lg-outer .lg-has-youtube:hover .lg-video-play {
  background-image: url('../images/components/lightgallery/youtube-play.png');
}
.lSAction > a {
  background-image: url('../images/components/lightslider/controls.png');
}
.lg-backdrop {
  background-color: rgba(0, 0, 0, 0.8);
}
.lg-toolbar {
  background: #000;
}
