/*------------------------------------*\
	#static call to action
\*------------------------------------*/
.static-call-to-action {
  /*
	 * For modern browsers
	 * 1. The space content is one way to avoid an Opera bug when the
	 *    contenteditable attribute is included anywhere else in the document.
	 *    Otherwise it causes space to appear at the top and bottom of elements
	 *    that are clearfixed.
	 * 2. The use of `table` rather than `block` is only necessary if using
	 *    `:before` to contain the top-margins of child elements.
	 */
  *zoom: 1;
  background: #ec1d25 url(../images/red-watermark.png) no-repeat center;
  background-size: 678px 678px;
  padding: 36px 20px 33px;
  margin: 40px 0 40px;
  text-align: center;
}
.static-call-to-action:before,
.static-call-to-action:after {
  content: " ";
  display: table;
}
.static-call-to-action:after {
  clear: both;
}
@media only screen and (min-width: 425px) {
  .static-call-to-action {
    padding: 36px 40px 33px;
  }
}
.static-call-to-action__title {
  width: 100%;
  padding: 0;
  margin: 0 0 17px;
  font: 100 27px/28px "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  letter-spacing: 0.02em;
  color: #fff;
  text-align: center;
}
.static-call-to-action__title strong {
  font-weight: 500;
}
.static-call-to-action__title p {
  margin: 0;
}
.static-call-to-action__text {
  display: block;
  font: 300 20px/28px "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  letter-spacing: 0.02em;
  color: #fff;
}
.static-call-to-action__text a {
  font-weight: 500;
}
.static-call-to-action__text a:hover {
  color: #eeebe3;
}
.static-call-to-action__button {
  margin: 0 0 13px;
}
.static-call-to-action__button:after {
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  font-family: 'Icons';
  line-height: 1;
  text-transform: none;
  speak: none;
  content: '\e90c';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: greyscale;
  margin: -4px 0 0;
  right: 20px;
}
.static-call-to-action__button:hover:after {
  margin: -2px 0 0;
  right: 20px;
}
