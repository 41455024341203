/*------------------------------------*\
	#Aside
\*------------------------------------*/
aside {
  order: 1;
  width: 100%;
  margin: -9px 0 0;
  padding: 0 20px 0;
}
@media only screen and (min-width: 1024px) {
  aside {
    position: relative;
    float: right;
    width: 342px;
    padding: 84px 0 0 40px;
    margin: 0;
  }
  aside + .content {
    float: left;
    width: calc(100% - 342px);
    /*
	 * For modern browsers
	 * 1. The space content is one way to avoid an Opera bug when the
	 *    contenteditable attribute is included anywhere else in the document.
	 *    Otherwise it causes space to appear at the top and bottom of elements
	 *    that are clearfixed.
	 * 2. The use of `table` rather than `block` is only necessary if using
	 *    `:before` to contain the top-margins of child elements.
	 */
    *zoom: 1;
  }
  aside + .content:before,
  aside + .content:after {
    content: " ";
    display: table;
  }
  aside + .content:after {
    clear: both;
  }
  aside + .content + * {
    clear: both;
  }
  aside:before {
    position: absolute;
    content: '';
    width: 100%;
    height: 40px;
    top: -40px;
    left: 0;
    background: #fff;
  }
}
