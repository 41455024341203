/*------------------------------------*\
	#360 view
\*------------------------------------*/
@media only screen and (min-width: 1024px) {
  .three-sixty__wrapper {
    margin: 0 0 0 40px;
  }
}
.three-sixty__link {
  position: relative;
  display: block;
  text-decoration: none;
}
.three-sixty__link:before {
  position: absolute;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  font-family: 'Icons';
  line-height: 1;
  text-transform: none;
  speak: none;
  content: '\e922';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: greyscale;
  font-size: 70px;
  margin: 0 0 0 -35px;
  top: 15px;
  left: 50%;
  color: rgba(0, 0, 0, 0.35);
}
.three-sixty__link:hover:before {
  color: rgba(0, 0, 0, 0.6);
}
