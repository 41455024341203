﻿/*------------------------------------*\
	#Google maps
\*------------------------------------*/

@import (reference) "../default.less";

.google-maps__wrapper {
	position: relative;
	height: 500px;

	&.dealer-maps {
		height: 500px;

		@media @laptop {
			height: 1008px;
		}
	}
}

.google-maps {
	position: absolute;
	width:  100%;
	height: 100%;
	top: 0;
	right: 0;
	
	.canvas {
		position: absolute;
		width:  100%;
		height: 100%;
		top:  0;
		left: 0;

		.gmnoprint,
		.gm-style-cc {
			opacity: 0;
			transition: opacity 0.5s;
		}

		&:hover .gmnoprint,
		&:hover .gm-style-cc {
			opacity: 1;
		}
	}

	img {
		visibility: visible;
		width:  auto;
	}
}

// Maps cluster
.maps-cluster {
	font-weight: 500;
	font-size: 15px;
	line-height: 40px;
	text-align: center;
	color: @color-text;
	background: @color-white;
	width:  48px;
	height: 48px;
	border: 5px solid @color-text;
	border-radius: 100%;
}

.maps-cluster--agriculture {
	border-color: @color-agricultural;
}

.maps-cluster--horticulture {
	border-color: @color-horticulture;
}

.maps-cluster--both {
	border-top-color: @color-horticulture;
	border-right-color: @color-horticulture;
	border-bottom-color: @color-agricultural;
	border-left-color: @color-agricultural;
}

// Maps infowindow
.info-window__content {
	font: 300 15px/22px @font-text;
	padding: 7px 5px 4px;

	h2 {
		font: 500 15px/22px @font-text;
		color: @color-primary;
		margin: 0;
	}

	span {
		display: block;
	}

	a {
		display: block;
		color: @color-primary;
		text-decoration: none;
		
		&:hover {
			text-decoration: underline;
		}
	}

	a[href^=tel]{
		display: inline;
		color: @color-text;
	}
}