﻿/*------------------------------------*\
	#Button
\*------------------------------------*/

@import (reference) "../default.less";

.button {
	position: relative;
	display: inline-block;
	font: 500 15px/1.2em @font-text;
	color: @color-black;
	padding: 17px 30px 15px 0;
	transition: background 0.2s ease-in-out, border-color 0.2s ease-in-out, color 0.2s ease-in-out;
	-webkit-appearance: none;
	cursor: pointer;
	text-transform: uppercase;
	text-decoration: none;
	background-color: transparent;
	border: none;
	margin: -2px 0 0;

	&:after {
		position: absolute;
		.icon(@icon-caret-right);
		font-size: 9px;
		margin: -3px 0 0;
		top:   50%;
		right: 6px;
		transition: right 0.2s ease-in-out, margin 0.2s ease-in-out;
	}

	.text & {
		margin-right: 18px;

		&:last-child {
			margin-bottom: 22px;
		}
	}

	&:hover {
		.button-hover;
	}
}

.button-hover {
	color: @color-primary;

	&:after {
		right: 4px;
	}
}

.button--primary,
.button--white,
.button--horticulture,
.button--agricultural  {
	padding: 17px 44px 15px 20px;
}

.button--no-arrow {
	padding: 17px 20px 15px 20px;
	
	&:after {
		display: none;
	}
}

.button--horticulture-borderd,
.button--agricultural-borderd {
	padding: 13px 16px 11px;
	background: @color-white;
}

.button--primary {
	background: @color-primary;
	color: @color-white;

	&:after {
		right: 18px;
	}

	&:hover {
		.button-hover--primary;
	}
}

.button-hover--primary {
	background: darken(@color-primary, 10%);
	color: @color-white;

	&:after {
		right: 16px;
	}
}

.button--white {
	background: @color-white;
	color: @color-primary;

	&:after {
		right: 18px;
	}

	&:hover {
		.button-hover--white;
	}
}

.button-hover--white {
	background: darken(@color-white, 10%);
	color: @color-primary;

	&:after {
		right: 16px;
	}
}

.button--horticulture {
	background: @color-horticulture;
	color: @color-white;

	&:after {
		right: 18px;
	}

	&:hover {
		.button-hover--horticulture;
	}
}

.button-hover--horticulture {
	background: darken(@color-horticulture, 10%);
	color: @color-white;

	&:after {
		right: 16px;
	}
}


.button--horticulture-borderd {
	border: 4px solid @color-horticulture;
	color: @color-horticulture;

	&:hover {
		.button--hover-horticulture-borderd;
	}
}

.button--hover-horticulture-borderd {
	color: @color-white;
	background-color: mix(@color-white, @color-horticulture, 40%);
}

.button--hover-horticulture-borderd-full {
	color: @color-white;
	background-color: @color-horticulture;
}

.button--agricultural {
	background: @color-agricultural ;
	color: @color-white;
	
	&:after {
		right: 18px;
	}

	&:hover {
		.button-hover--agricultural;
	}
}

.button-hover--agricultural  {
	background: darken(@color-agricultural , 10%);
	color: @color-white;

	&:after {
		right: 16px;
	}
}

.button--agricultural-borderd {
	border: 4px solid @color-agricultural;
	color: @color-agricultural;

	&:hover {
		.button--hover-agricultural-borderd;
	}
}

.button--hover-agricultural-borderd {
	color: @color-white;
	background-color: mix(@color-white, @color-agricultural, 40%);
}

.button--hover-agricultural-borderd-full {
	color: @color-white;
	background-color: @color-agricultural;
}

.button--center {
	width: 100%;
	padding: 17px 36px 15px 20px;
	text-align: center;

	&:after {
		position: relative;
		top:   -1px;
		right: -16px;
	}

	&:hover {
		.button-hover--center;
	}
}

.button-hover--center {
	
	&:after {
		right: -18px;
	}
}