﻿/*------------------------------------*\
	#Search navigation
\*------------------------------------*/

@import (reference) "../default.less";

.search-navigation {
	font: 16px/22px @font-text;
	text-align: center;
	list-style: none;
	overflow: hidden;
	width: 100%;
	padding: 10px 0 0;
	margin: -18px 0 70px;

	dt {
		margin: 0 0 12px;
	}

	dd {
		ul {
			margin: 0;
			padding: 0;

			li {
				display: inline-block;
				margin: 10px 10px 0;

				&.active {
					color: #fff;
					text-transform: uppercase;
					background: @color-primary;
					padding: 10px 20px;
					border-radius: 3px;
				}

				&.previous,
				&.next {
					a {
						background: @color-primary;
						color: #fff;
						padding: 10px 20px;
						border-radius: 3px;
						line-height: 22px;
						text-decoration: none;
						display: block;

						&:hover {
							background-color: mix(#000, @color-text, 20%);
						}
					}
				}

				.button {
					display: block;
				}

				&:before {
					display: none;
				}
			}
		}
	}
}