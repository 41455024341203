/*------------------------------------*\
	#Footer menu
\*------------------------------------*/
.footer-menu {
  margin: 0 0 30px;
}
.footer-menu ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.footer-menu li:last-child a {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.footer-menu a {
  display: block;
  padding: 16px 18px 15px 18px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  font: 500 15px/1.2em "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
}
.footer-menu a:hover {
  color: #ec1d25;
}
