﻿/*------------------------------------*\
	#Item navigation
\*------------------------------------*/

@import (reference) "../default.less";

.item-navigation {
	.clearfix;
	font: 500 15px/1.2em @font-text;
	text-transform: uppercase;
	padding: 0 20px;

	@media @laptop {
		padding: 0;
	}
}

.item-navigation--right-top {
	margin: 37px 0 0;
	text-align: right;

	.item-navigation__container,
	.item-navigation__back-to-overview {
		float: right;
		clear: both;
	}

	.item-navigation__back-to-overview {
		margin: 0 0 13px;
	}
}

.item-navigation--aside {
	margin: 22px 0 3px;

	.item-navigation__container {
		display: inline-block;
		float: right;
		margin: 0 0 13px;
	}

	.item-navigation__back-to-overview {
		float: left;
		margin: 0 0 13px;
	}

	@media @laptop {
		margin: -47px 0 20px;
		text-align: center;

		.item-navigation__container {
			float: none;
		}

		.item-navigation__back-to-overview {
			float: none;
		}
	}
}

.item-navigation--full {
	position: relative;
	display: block;
	padding: 0 20px 18px;
	border-bottom: 1px solid fade(@color-text, 12%);

	.item-navigation__container,
	.item-navigation__back-to-overview {
		margin: 0 0 13px;
	}

	&:before {
		position: absolute;
		content: '';
		width:  1280px;
		height: 1px;
		bottom: -1px;
		right:  100%;
		background: fade(@color-text, 12%);
	}

	@media @laptop {
		padding: 0 0 31px;
	}
}

.item-navigation--news {
	display: none;
	padding: 77px 20px 18px;

	&:last-child {
		border-bottom: none;

		&:before {
			display: none;
		}
	}

	@media @laptop {
		display: block;
		padding: 37px 0 23px;
	}
}

.item-navigation__container  {
	.clearfix;
	float: right;
}

.item-navigation__back-to-overview {
	display: inline-block;
	position: relative;
	text-decoration: none;
	padding: 0 0 0 18px;

	&:before {
		position: absolute;
		.icon(@icon-caret-left);
		font-size: 9px; 
		top:  4px;
		left: -2px;
	}
}

.item-navigation__text {
	float: left;
	display: block;
}

.item-navigation__next,
.item-navigation__previous {
	position: relative;
	float: left;
	display: block;
	height: 17px;
	width:  20px;
	overflow: hidden;
	text-decoration: none;
	text-indent: 9999px;

	&:after {
		position: absolute;
		top:  4px;
		font-size: 9px;
		text-indent: 0;
	}
}

.item-navigation__next {
	margin: 0 -8px 0 0;

	&:after {
		.icon(@icon-caret-right);
		left: 4px;
	}
}

.item-navigation__previous {

	&:after {
		.icon(@icon-caret-left);
		left: 7px;
	}
}