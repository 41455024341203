/*------------------------------------*\
	#Text
\*------------------------------------*/
.text {
  overflow: hidden;
}
.text p:last-child {
  margin: 0 0 13px;
}
.text ul,
.text ol {
  list-style: none;
  overflow: hidden;
  padding: 0;
  margin: -8px 0 33px;
}
.text ul li,
.text ol li {
  position: relative;
  border-top: 1px solid rgba(51, 51, 51, 0.12);
  padding: 12px 20px 9px 45px;
}
.text ul li:before,
.text ol li:before {
  position: absolute;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  font-family: 'Icons';
  line-height: 1;
  text-transform: none;
  speak: none;
  content: '\e90b';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: greyscale;
  font-size: 9px;
  top: 22px;
  left: 18px;
}
.text ul li:last-child,
.text ol li:last-child {
  border-bottom: 1px solid rgba(51, 51, 51, 0.12);
}
.text ul li ul,
.text ol li ul {
  margin: 9px 0 -11px !important;
}
.text ul li ul li,
.text ol li ul li {
  width: 100%;
}
.text ul li ul li:last-child,
.text ol li ul li:last-child {
  border-bottom: 0;
}
.text ul:last-child,
.text ol:last-child {
  margin: -8px 0 22px;
}
.text table {
  width: 100%;
}
.text table th {
  font: 500 17px/1.2em "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  color: #fff;
  background: #ec1d25;
  text-align: left;
  padding: 16px 20px 14px;
}
.text table th + th {
  border-left: 1px solid #fff;
}
.text table tr:nth-child(even) td {
  background: #eeebe3;
}
.text table td {
  font: 300 17px/1.2em "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  padding: 16px 20px 14px;
}
.text table td + td {
  border-left: 1px solid #fff;
}
