﻿/*------------------------------------*\
	#Video
\*------------------------------------*/

@import (reference) "../default.less";

.video__wrapper {
	padding: 4px 0 0;
	margin: 0 0 22px;
}

.video {
	.responsive-container(1328, 746);
	margin: 0;

	iframe {
		position: absolute;
		width:  100%;
		height: 100%;
		top:  0;
		left: 0;
	}
}