/*------------------------------------*\
	#Vsummary
\*------------------------------------*/
.vsummary {
  background: #ffcccc;
  padding: 9px 9px 3px;
  border: 1px solid #ff9999;
  border-radius: 3px;
  margin: 0 0 12px;
}
.vsummary p,
.vsummary ul {
  list-style: none;
  padding: 0;
  margin: 0 0 8px;
}
.vsummary p:last-child,
.vsummary ul:last-child {
  margin: 0 0 8px;
}
