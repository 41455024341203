/*------------------------------------*\
	#Call to action aside
\*------------------------------------*/
.aside-call-to-action {
  /*
	 * For modern browsers
	 * 1. The space content is one way to avoid an Opera bug when the
	 *    contenteditable attribute is included anywhere else in the document.
	 *    Otherwise it causes space to appear at the top and bottom of elements
	 *    that are clearfixed.
	 * 2. The use of `table` rather than `block` is only necessary if using
	 *    `:before` to contain the top-margins of child elements.
	 */
  *zoom: 1;
  background: #ec1d25 url(../images/red-watermark.png) no-repeat center right -192px;
  background-size: 379px 379px;
  padding: 36px 20px 40px;
  margin: 0 0 40px;
}
.aside-call-to-action:before,
.aside-call-to-action:after {
  content: " ";
  display: table;
}
.aside-call-to-action:after {
  clear: both;
}
@media only screen and (min-width: 425px) {
  .aside-call-to-action {
    padding: 36px 40px 40px;
  }
}
.aside-call-to-action__caption {
  text-align: center;
}
.aside-call-to-action__title {
  width: 100%;
  padding: 0 0 34px;
  margin: 0;
  font: 100 20px/28px "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  letter-spacing: 0.02em;
  color: #fff;
  text-align: center;
}
.aside-call-to-action__title strong {
  font-weight: 500;
}
.aside-call-to-action__title p {
  margin: 0;
}
.aside-call-to-action .aside-call-to-action__button {
  width: 100%;
}
.aside-call-to-action .aside-call-to-action__button:after {
  right: 32px;
}
.aside-call-to-action .aside-call-to-action__button:hover:after {
  right: 30px;
}
