﻿/*------------------------------------*\
	#Project
\*------------------------------------*/

@import (reference) "../default.less";

.projects {
	margin-bottom: -8px;
}

.project {
	.clearfix;
	position: relative;
	margin: 0 0 40px;
}

.project__overview-title {
	margin: 0 0 31px;
}

.project__container {
	overflow: hidden;
	display: block;
	border-bottom: 10px solid @color-background-dark;
	text-decoration: none;
	background: @color-background;
	transition: background 0.2s ease-in-out;

	&:hover {
		color: @color-text;
		background: @color-background-dark;
	}
}

.project__container--horticulture {
	border-bottom-color: @color-horticulture;
}

.project__container--agricultural {
	border-bottom-color: @color-agricultural;
}

.project__image-container {
	width: 100%;
	background: @color-white;
}

.project__placeholder {
	.responsive-container(600, 400);
	background: url(../images/placeholder.jpg) no-repeat center;
	background-image: url(../images/placeholder.svg), none;
	background-size: cover;
 }

.project__title-prefix {
	font: 300 20px/28px @font-text;
	letter-spacing: 0.02em;
}

.project__text-container {
	padding: 34px 40px 33px;

	p {
		letter-spacing: 0.02em;
		margin: 0;
	}
}

.product__text-content {
	position: relative;
	height: 100%;
}

.project__title {
	font: 300 20px/28px @font-text;
	letter-spacing: 0.02em;
	text-transform: uppercase;

	&:after {
		position: relative;
		.icon(@icon-caret-light-right);
		font-size: 9px;
		top: -3px;
		left: 7px;
		transition: left 0.2s ease-in-out;
	}

	.project__container:hover & {

		&:after {
			left: 9px;
		}
	}
}

.project__to-overview {
	position: absolute;
	right:  21px;
	bottom: -45px;
	font: 500 15px/1.2em @font-text;
	padding: 0 24px 0 0;
	text-transform: uppercase;
	text-decoration: none;

	&:after {
		position: absolute;
		.icon(@icon-caret-right);
		font-size: 9px;
		font-size: 9px;
		margin: -4px 0 0;
		top:   50%;
		right: 0;
		transition: left 0.2s ease-in-out;
	}

	&:hover:after {
		right: -2px;
	}
}