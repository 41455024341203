/*------------------------------------*\
	#Dealers
\*------------------------------------*/
.filter {
  position: relative;
  /*
	 * For modern browsers
	 * 1. The space content is one way to avoid an Opera bug when the
	 *    contenteditable attribute is included anywhere else in the document.
	 *    Otherwise it causes space to appear at the top and bottom of elements
	 *    that are clearfixed.
	 * 2. The use of `table` rather than `block` is only necessary if using
	 *    `:before` to contain the top-margins of child elements.
	 */
  *zoom: 1;
  background: #eeebe3;
}
.filter:before,
.filter:after {
  content: " ";
  display: table;
}
.filter:after {
  clear: both;
}
.filter__container:after {
  position: absolute;
  content: '';
  width: 1280px;
  height: 100%;
  right: 100%;
  bottom: 0;
  background: #eeebe3;
}
.filter__title {
  position: relative;
  float: left;
  font: 500 15px/1.2em "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  text-transform: uppercase;
  padding: 32px 15px 0 74px;
}
.filter__title:before {
  position: absolute;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  font-family: 'Icons';
  line-height: 1;
  text-transform: none;
  speak: none;
  content: '\e923';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: greyscale;
  font-size: 33px;
  top: 24px;
  left: 20px;
}
@media only screen and (min-width: 1024px) {
  .filter__title:before {
    left: 0;
  }
}
@media only screen and (min-width: 1024px) {
  .filter__title {
    padding: 32px 15px 0 54px;
  }
}
.filter__buttons {
  float: left;
  padding: 5px 10px;
}
.filter__button {
  margin: 10px;
}
.filter__button.button--agricultural-borderd.filter__button--active {
  color: #fff;
  background-color: #ffb400;
}
.filter__button.button--horticulture-borderd.filter__button--active {
  color: #fff;
  background-color: #77b039;
}
.filter__select-container {
  position: relative;
  float: left;
  width: 100%;
  max-width: 278px;
  margin: 15px 20px;
}
.filter__select-container:after {
  position: absolute;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  font-family: 'Icons';
  line-height: 1;
  text-transform: none;
  speak: none;
  content: '\e915';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: greyscale;
  font-size: 17px;
  width: 50px;
  height: 50px;
  padding: 16px 0 0;
  border-left: 1px solid rgba(51, 51, 51, 0.12);
  top: 0;
  right: 0;
  text-align: center;
  pointer-events: none;
  color: rgba(51, 51, 51, 0.4);
}
@media only screen and (min-width: 425px) {
  .filter__select-container {
    max-width: 324px;
  }
}
.filter__select {
  width: 100%;
  border: none;
}
.filter__checkbox {
  display: none;
}
.filter__search {
  margin: 15px;
}
