/*------------------------------------*\
	#Responsive nav
\*------------------------------------*/
.responsive-nav {
  position: fixed;
  z-index: 10;
  width: 320px;
  height: 100%;
  padding: 40px 0;
  top: 0;
  right: 0;
  transition: right 0.3s ease-in-out;
  background: #ec1d25;
  overflow-x: hidden;
  overflow-y: visible;
  box-shadow: -5px 0px 5px 0px rgba(0, 0, 0, 0.25);
}
.responsive-nav.collapsed {
  right: -325px;
}
@media only screen and (min-width: 1024px) {
  .responsive-nav {
    position: static;
    width: auto;
    padding: 0;
    opacity: 1;
    background: none;
    overflow: visible;
    transition: none;
    box-shadow: none;
  }
  .responsive-nav.collapsed {
    left: 0;
    transition: none;
  }
}
