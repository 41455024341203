﻿/*------------------------------------*\
	#News archive 
\*------------------------------------*/

@import (reference) "../default.less";

.news-archive {
	padding: 0;
	margin: -3px 0 22px;
	list-style: none;
}

.news-archive__item{
	border-top: 1px solid fade(@color-text, 12%);
	
	&:last-child {
		border-bottom: 1px solid fade(@color-text, 12%);
	}
}

.news-archive__link {
	position: relative; 
	display: block;
	padding: 10px 20px 11px 38px;
	text-decoration: none;

	&:before {
		position: absolute;
		.icon(@icon-caret-right);
		font-size: 9px;
		top:  20px;
		left: 18px;
	}
}