/*------------------------------------*\
	#Side nav
\*------------------------------------*/
.side-nav {
  margin: 20px 0 40px;
}
.side-nav dt {
  margin: 0 0 36px;
}
.side-nav dt a {
  font: 100 28px/28px "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  letter-spacing: 0,02px;
}
.side-nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.side-nav ul a {
  position: relative;
  padding: 17px 20px 14px 38px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  text-transform: uppercase;
}
.side-nav ul a:before {
  position: absolute;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  font-family: 'Icons';
  line-height: 1;
  text-transform: none;
  speak: none;
  content: '\e90b';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: greyscale;
  font-size: 9px;
  top: 22px;
  left: 18px;
}
.side-nav li.active a {
  background: #faf7f2;
}
.side-nav li:last-child a {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.side-nav a {
  display: block;
  text-decoration: none;
  font: 500 15px/1.2em "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
}
@media only screen and (min-width: 768px) {
  .side-nav {
    margin: 0 0 40px;
  }
}
