/*------------------------------------*\
	#Search
\*------------------------------------*/
.search {
  position: absolute;
  z-index: 5;
  background: #ec1d25;
  overflow: hidden;
  width: 100%;
  height: 60px;
  padding: 0 20px;
  right: 0;
  top: 100px;
  transition: width 0.2s ease-in-out;
}
.search .search__button {
  padding-left: 20px;
  padding-right: 20px;
  flex: 0 0 auto;
}
.search .input {
  width: 100%;
}
@media only screen and (min-width: 1024px) {
  .search {
    width: calc(100% - 285px);
    height: 70px;
    top: auto;
    bottom: 0;
  }
}
.search__button,
.search__toggler {
  position: absolute;
  z-index: 1;
  height: 60px;
  width: 60px;
  margin: 0;
  text-align: center;
  color: #fff;
  cursor: pointer;
  text-indent: -9999px;
}
@media only screen and (min-width: 1024px) {
  .search__button,
  .search__toggler {
    height: 70px;
    width: 70px;
  }
}
.search__toggler {
  top: 0;
  right: 0;
  transition: color 0.2s ease-in-out;
}
.search__toggler:after {
  position: absolute;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  font-family: 'Icons';
  line-height: 1;
  text-transform: none;
  speak: none;
  content: '\e90f';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: greyscale;
  font-size: 23px;
  top: 18px;
  left: 18px;
  text-indent: 0;
}
@media only screen and (min-width: 1024px) {
  .search__toggler:after {
    top: 23px;
    left: 23px;
  }
}
.search__toggler:hover {
  color: #eeebe3;
}
.search__toggler--closed:after {
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  font-family: 'Icons';
  line-height: 1;
  text-transform: none;
  speak: none;
  content: '\e91b';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: greyscale;
}
.search__button {
  top: -10px;
  left: 0;
}
.search--closed {
  width: 60px;
}
@media only screen and (min-width: 1024px) {
  .search--closed {
    width: 70px;
  }
}
.search__container {
  position: absolute;
  overflow: hidden;
  min-width: 280px;
  width: 100%;
  height: 60px;
  padding: 5px 0;
  right: 0;
}
.search__container div {
  overflow: hidden;
  position: relative;
  width: 100%;
  padding: 0 70px;
}
.search__container div:after {
  position: absolute;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  font-family: 'Icons';
  line-height: 1;
  text-transform: none;
  speak: none;
  content: '\e91b';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: greyscale;
  color: #fff;
  font-size: 23px;
  top: 14px;
  left: 23px;
  text-indent: 0;
}
@media only screen and (min-width: 1024px) {
  .search__container {
    height: 70px;
    padding: 10px 0;
  }
}
.search__button {
  padding-left: 20px;
  padding-right: 20px;
}
